import * as types from './types'

const initialState: types.UserInviteState = {
  invites: [],
  status: {
    loading: false,
    error: '',
  },
}

const UserInviteReducer = (
  state: types.UserInviteState = initialState,
  action: any
): types.UserInviteState => {
  switch (action.type) {
    case types.RESEND_INVITE_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
        },
      }

    case types.RESEND_INVITE_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: '',
        },
      }

    case types.RESEND_INVITE_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
        },
      }

    case types.GET_INVITATIONS_BY_USER_ID_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
        },
      }

    case types.GET_INVITATIONS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        invites: action.payload,
        status: {
          ...state.status,
          loading: false,
          error: '',
        },
      }

    case types.GET_INVITATIONS_BY_USER_ID_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
        },
      }

    case types.CREATE_INVITE_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
        },
      }

    case types.CREATE_INVITE_SUCCESS:
      return {
        ...state,
        invites: [...state.invites, action.payload],
        status: {
          ...state.status,
          loading: false,
          error: '',
        },
      }

    case types.CREATE_INVITE_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: 'This email is already linked to a user',
        },
      }

    case types.REQUEST_INVITE_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
        },
      }

    case types.REQUEST_INVITE_SUCCESS:
      return {
        ...state,
        invites: [...state.invites, action.payload],
        status: {
          ...state.status,
          loading: false,
          error: '',
        },
      }

    case types.REQUEST_INVITE_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: 'This email is already linked to a user',
        },
      }

    case types.RESET_ERRORS:
      return {
        ...state,
        status: {
          ...state.status,
          error: '',
        },
      }

    default:
      return state
  }
}

export default UserInviteReducer
