import { legacy_createStore as createStore, applyMiddleware, Store } from 'redux';
import { thunk } from 'redux-thunk';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import RootReducer from '../reducers';

export const store: Store = createStore(
    RootReducer, 
    composeWithDevTools(
        applyMiddleware(thunk)
    )
);

export const persistor = persistStore(store);

export type RootStore = ReturnType<typeof RootReducer>;

const root = { store, persistor }

export default root;
