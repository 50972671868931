import { Route } from "react-router-dom";
import { RouteModel } from "./route";

export const RouteWithSubRoutes = (route: RouteModel): JSX.Element => {
  return (
    <Route
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
};
