
export function booksFormater(array: any[], coverSize: number = 100) {
  const newArr: any[] = [];
    
    array.forEach(item => {
      const pubDate = getDateString(new Date(item.publishedDate));
      //const format = getType(item.type);
      const clasification = getGenre(item.genre);
      const bookCoverURL = item.bookCoverURL ? item.bookCoverURL.split("/x/").join(`/${coverSize}x/`) : "";
  
      newArr.push({
        id: item.id,
        isbn: item.isbn,
        title: item.title,
        author: item.author,
        type: item.type,
        genre: clasification,
        bookCoverURL,
        asin: item.asin,
        sku: item.sku,
        pubDate,
        publishedDate: item.publishedDate,
        primaryNarrative : item.primaryNarrative,
        ...item
      })
    });
    

  
  return newArr;
}
export function getDateString(date: Date) {
  return date.toLocaleString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
  });  
}

function getGenre(genre: number) {
  switch(genre) {
    case 1:
    return 'Fiction';
    case 2:
    return 'Non-fiction';
    case 3:
    return 'Academic';
    case 4:
    return 'Fantasy';
    case 5:
    return 'Adventure';
    case 6: 
    return 'Romance';
    case 7:
    return 'Contemporary';
    case 8:
    return 'Dystopian';
    case 9:
    return 'Mistery';
    case 10:
    return 'Horror';
    case 11:
    return 'Thriller';
    case 12:
    return 'Suspense';
    default:
    return 'Other';
  }
}

