export const GOOGLE_URL_REGEX = /(https?|http):\/\/[a-zA-Z0-9-]+\.[^\s]{2,}/;
export const GOOGLE_REGEX_LIST = [
  /([?!$#@]+)/,                                   //special characters
  /[A-Z][a-z][A-Z]/,   
  /[A-Z][A-Z][A-Z][A-Z]/,                           //AaA
  /[A-Z][.*?!$#@][a-z][.*?!$#@][A-Z]/,            //A*a*A
  /[A-Z][.*?!$#@][A-Z][.*?!$#@][A-Z]/,            //A*A*A
  /[.*?!$#@][0-9]/,                               //$1
];

export const GOOGLE_URL_SHORTENERS_LIST = [/bit.ly/i, /a.co/i, /shortio.li/i, /t.ly/i, /tinyurl.com/i, /amz.run/i ];

export const ISBN_REGEX = /^(?:ISBN(?:-1[03])?:? )?(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)(?:97[89][- ]?)?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$/
export const HTML_TAGS_REGEX = /(<script(\s|\S)*?<\/script>)|(<style(\s|\S)*?<\/style>)|(<!--(\s|\S)*?-->)|(<\/?(\s|\S)*?>)/g;
export const SCRIPT_TAGS_REGEX = /(<script(\s|\S)*?<\/script>)/g;

export function getHTMLContentLength(html: string): number {
  if (!html || html === null) return 0;

  const sanitized = html.replace(HTML_TAGS_REGEX, '');
  return sanitized.length;
}

export function getHTMLContentWithoutElements(html: string): string {
  if (!html) return "";

  return html.replace(HTML_TAGS_REGEX, "");
}

export function getHTMLWithoutScriptElements(html: string): string {
  if (!html) return "";

  return html.replace(SCRIPT_TAGS_REGEX, "");
}
