export type FeedbackForm = {
    questionType: number,
    message: string,
}

export type FeedbackState = {
    form: FeedbackForm,
    loading: boolean,
    sent: boolean,
    error: string,
} 

export const SET_CONTACT_FORM = 'SET_CONTACT_FORM';

type setContactForm = {
    type: typeof SET_CONTACT_FORM,
    payload: FeedbackForm,
}

export const POST_FEEDBACK_REQUEST = 'POST_FEEDBACK_REQUEST';
export const POST_FEEDBACK_SUCCESS = 'POST_FEEDBACK_SUCCESS';
export const POST_FEEDBACK_FAILED = 'POST_FEEDBACK_FAILED';

type postFeedBackRequest = {
    type: typeof POST_FEEDBACK_REQUEST,
}

type postFeedbackSuccess = {
    type: typeof POST_FEEDBACK_SUCCESS,
}

type postFeedBackFailed = {
    type: typeof POST_FEEDBACK_FAILED,
    payload: string
}

export type DispatchTypes = setContactForm | postFeedBackRequest | postFeedbackSuccess | postFeedBackFailed;