import React, { FC } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      background: "rgba(255, 255, 255, .5)",
      zIndex: theme.zIndex.drawer + 1,
    },
  })
);

const Loader: FC = () => {
  const { backdrop } = useStyles();
  return (
    <Backdrop open={true} className={backdrop}>
      <CircularProgress />
    </Backdrop>
  );
};

export default Loader;
