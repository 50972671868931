import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// reducers
import { FacebookTouchpoint, GoogleTouchpoint, AmazonTouchpoint, EmailBlastTouchpoint } from './Touchpoints';
import { NotificationsReducer } from './Notifications';
import { StatementsReducer } from './Statements';
import { CampaignsReducer, FilterReducer } from './Campaigns';
import { FeedbackReducer } from './Feedback';
import { ReportReducer } from './Reports';
import { AccountReducer } from './Account';
import FaqReducer from './Faq/reducer';
import { CartReducer } from './Cart';
import { UserInviteReducer } from './UserInvite';
import { BookmarkReducer } from './Bookmark';
import { SettingsReducer } from './Settings';

const blacklist = [
  'faqs',
  'feedback',
  'cart',
  'report',
  'campaigns',
  'filter',
  'fbTouchpoints',
  'googleTouchpoints',
  'amazonTouchpoints',
  'emailBlastTouchpoints',
  'notifications',
  'statements',
  'bookmarks',
  'settings',
];

const persistConfig = {
  key: 'root',
  storage,
  blacklist,
  migrate: (state: any) => {
    console.log('Reducers migration running...')
    return Promise.resolve(state)
  }
};

const RootReducer = combineReducers({
  account: AccountReducer,
  faqs: FaqReducer,
  feedback: FeedbackReducer,
  cart: CartReducer,
  campaigns: CampaignsReducer,
  filter: FilterReducer,
  report: ReportReducer,
  notifications: NotificationsReducer,
  fbTouchpoints: FacebookTouchpoint.reducer,
  googleTouchpoints: GoogleTouchpoint.reducer,
  amazonTouchpoints: AmazonTouchpoint.reducer,
  emailBlastTouchpoints: EmailBlastTouchpoint.reducer,
  statements: StatementsReducer,
  userInvites: UserInviteReducer,
  bookmarks: BookmarkReducer,
  settings: SettingsReducer
});

export default persistReducer(persistConfig, RootReducer);
