import * as types from './types';

const initialState: types.CartState = {
  paymentMethods: [],
  defaultMethod: '',
  promocode: '',
  promotion: {
    message: '',
    valid: false,
  },
  summary: {
    touchpoints: [],
    subTotal: 0,
    total: 0,
    taxAmount: 0, 
    discount: 0,
    taxServiceError: false,
    enablePayment: false,
    isFree: false,
  },
  taxes: {
    items: [],
    selected: undefined,
    loading: false,
    error: undefined
  },
  purchaseTerms: false,
  paymentIntent: '',
  status: {
    loading: false, // keep track of Api's response
    error: '', // notify if any
  }
} 

const CartReducer = (
  state: types.CartState = initialState,
  action: any
) : types.CartState => {

  switch (action.type) {
    default:
    return state;

    case 'RESET_CAMPAIGN_SUMMARY':
    return {
      ...state,
      taxes: {
        ...state.taxes,
        selected: undefined,
        error: undefined,
      },
      summary: initialState.summary,
      defaultMethod: initialState.defaultMethod
    };
    
    case "CLEAR_PROMOTION": {
      return {
        ...state,
        promocode: initialState.promocode,
        promotion: initialState.promotion,
      }
    }

    case "LOAD_MOCK_SUMMARY": {
      return {
        ...state,
        summary: {
          ...state.summary,
          touchpoints: [
            ...state.summary.touchpoints,
            action.payload
          ]
        }
      }
    }

    case types.GET_PAYMENT_METHODS_REQUEST: 
    return {
      ...state,
      status: {
        loading: true,
        error: initialState.status.error,
      }
    }

    case types.GET_PAYMENT_METHODS_SUCCESS:
    return {
      ...state,
      //do the changes...
      paymentMethods: action.payload,
      status: {
        ...state.status,
        loading: false, 
      }
    }

    case types.GET_PAYMENT_METHODS_FAILED:
    return {
      ...state,
      status: {
        loading: false,
        error: action.payload,
      }
    }

    case types.CREATE_PAYMENT_INTENT_REQUEST: 
    return {
      ...state,
      status: {
        loading: true,
        error: initialState.status.error,
      }
    }

    case types.CREATE_PAYMENT_INTENT_SUCCESS:
    return {
      ...state,
      //do the changes...
      paymentIntent: action.payload,
      status: {
        ...state.status,
        loading: false, 
      }
    }

    case types.CREATE_PAYMENT_INTENT_FAILED:
    return {
      ...state,
      status: {
        loading: false,
        error: action.payload,
      }
    }

    case types.SAVE_PAYMENT_METHOD:
    return {
      ...state,
      paymentMethods: [
        ...state.paymentMethods,
        action.payload,
      ]
    }

    case types.ATACH_PAYMENT_METHOD_REQUEST: 
    return {
      ...state,
      status: {
        loading: true,
        error: initialState.status.error,
      }
    }

    case types.ATACH_PAYMENT_METHOD_SUCCESS:
    return {
      ...state,
      //do the changes...
      status: {
        ...state.status,
        loading: false, 
      }
    }

    case types.ATACH_PAYMENT_METHOD_FAILED:
    return {
      ...state,
      status: {
        loading: false,
        error: action.payload,
      }
    }

    case types.UPDATE_PAYMENT_METHOD_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
        }
      }
    }

    case types.UPDATE_PAYMENT_METHOD_SUCCESS: {
      const paymentMethods = state.paymentMethods;

      const indexes = paymentMethods.map((p) => p.id);
      const target = indexes.indexOf(action.payload.id);

      paymentMethods[target] = action.payload;

      return {
        ...state,
        paymentMethods,
        status: {
          ...state.status,
          loading: false,
        }
      }
    }

    case types.UPDATE_PAYMENT_METHOD_FAILED: {
      return {
        ...state,
        status: {
          loading: false,
          error: action.payload
        }
      }
    }

    case types.DELETE_PAYMENT_METHOD_REQUEST: 
    return {
      ...state,
      status: {
        loading: true,
        error: initialState.status.error,
      }
    }

    case types.DELETE_PAYMENT_METHOD_SUCCESS: { 
      const filtered = state.paymentMethods.filter(
        method => method.id !== action.payload
      );
      return {
        ...state,
        paymentMethods: filtered,
        status: {
          ...state.status,
          loading: false, 
        }
      }
    }

    case types.DELETE_PAYMENT_METHOD_FAILED:
    return {
      ...state,
      status: {
        loading: false,
        error: action.payload,
      }
    }

    case types.SELECT_PAYMENT_METHOD: 
    return {
      ...state,
      defaultMethod: action.payload
    }
    
    case types.GET_CAMPAIGN_SUMMARY_REQUEST: {
      return {
        ...state,
        status: {
          error: initialState.status.error,
          loading: true,
        }
      }
    }

    case types.GET_CAMPAIGN_SUMMARY_SUCCESS: {
      return {
        ...state,
        summary: action.payload,
        status: {
          ...state.status,
          loading: false
        }
      }
    }

    case types.GET_CAMPAIGN_SUMMARY_FAILED: {
      return {
        ...state,
        status: {
          loading: false,
          error: action.payload
        }
      }
    }

    case 'REMOVE_SERVICE': {
      const touchpoints = state.summary.touchpoints.filter(
        (item) => item.touchpointId !== action.payload
      );

      return {
        ...state,
        summary: {
          ...state.summary,
          touchpoints
        }
      }
    }

    case types.SET_PROMOCODE: {
      return {
        ...state,
        promocode: action.payload,
      }
    }

    case types.SET_PROMOTION: {
      return {
        ...state,
        promotion: action.payload
      }
    }

    case types.RESET_PROMOTION: {
      return {
        ...state,
        promocode: initialState.promocode,
        promotion: initialState.promotion,
      }
    }

    case types.TOGGLE_PURCHASE_TERMS: {
      return {
        ...state,
        purchaseTerms: !state.purchaseTerms
      }
    }

    case types.GET_TAX_ITEMS_REQUEST: {
      return {
        ...state,
        taxes: {
          ...state.taxes,
          loading: true
        }
      }
    }

    case types.GET_TAX_ITEMS_SUCCESS: {
      return {
        ...state,
        taxes: {
          ...state.taxes,
          loading: false,
          items: action.payload
        }
      }
    }

    case types.GET_TAX_ITEMS_FAILED: {
      return {
        ...state,
        taxes: {
          ...state.taxes,
          loading: false,
          error :action.payload
        }
      }
    }

    case types.CREATE_TAX_ITEM_REQUEST: {
      return {
        ...state,
        taxes: {
          ...state.taxes,
          loading: true
        }
      }
    }

    case types.CREATE_TAX_ITEM_SUCCESS: {
      return {
        ...state,
        taxes: {
          ...state.taxes,
          loading: false,
          items: [ ...state.taxes.items, action.payload]
        }
      }
    }

    case types.CREATE_TAX_ITEM_FAILED: {
      return {
        ...state,
        taxes: {
          ...state.taxes,
          loading: false,
          error: action.payload
        }
      }
    }

    case types.DELETE_TAX_ITEM_REQUEST: {
      return {
        ...state,
        taxes: {
          ...state.taxes,
          loading: true,
        }
      }
    }

    case types.DELETE_TAX_ITEM_SUCCESS: {
      const items = state.taxes.items.filter(
        (item) => item.id !== action.payload.id
      )
      return {
        ...state,
        taxes: {
          ...state.taxes,
          items,
          loading: false
        }
      }
    }

    case types.DELETE_TAX_ITEM_FAILED: {
      return {
        ...state,
        taxes: {
          ...state.taxes,
          loading: false,
          error: action.payload,
        }
      }
    }

    case types.SET_SELECTED_TAX_ITEM: {
      return {
        ...state,
        taxes: {
          ...state.taxes,
          selected: action.payload
        }
      }
    }
  }
}

export default CartReducer;