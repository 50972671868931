export class AccountService {
	private readonly baseUrl: string
	private readonly apiUrl: string

	constructor() {
		this.baseUrl = `${process.env.REACT_APP_API_URL}/Accounts`
		this.apiUrl = `${process.env.REACT_APP_API_URL}`

		return this;
	}

	getAccountById(id: string): Promise<any> {
		return fetch(`${this.baseUrl}/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			credentials: 'include'
		})
			.then(res => res.json())
			.catch(error => Promise.reject(error))
	}

	updateAccount(form: any): Promise<any> {
		return fetch(`${this.baseUrl}/${form.id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json; ',
			},
			body: JSON.stringify(form),
			credentials: 'include'
		})
			.then(res => {
				if (res.status === 200)
					return res.status
				else
					return res.json()
			})
			.catch(error => Promise.reject(error))
	}

	changePassword(form: any, id: string): Promise<any> {
		return fetch(`${this.baseUrl}/ChangePassword/${id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			body: JSON.stringify(form),
			credentials: 'include'
		})
			.then(res => {
				if (res.status === 200) return res
				else return Promise.reject(res)
			})
			.catch(error => Promise.reject(error))
	}

	getRecommendedSteps(accountId: string): Promise<any> {
		return fetch(`${this.apiUrl}/RecommendedStep/GetByAccountId/${accountId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			credentials: 'include'
		})
			.then(res => {
				if (res.ok) return res.json()
			})
			.catch(error => Promise.reject(error))
	}

	skipRecommendedStep(accountId: string, stepId: string): Promise<any> {
		return fetch(
			`${this.apiUrl}/RecommendedStep/SkipAccountStep/${accountId}/${stepId}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json; ',
					'Access-Control-Allow-Credentials': 'true',
				},
				credentials: 'include'
			}
		)
			.then(res => {
				if (res.ok) return res.status
			})
			.catch(error => Promise.reject(error))
	}

	uploadAvatar(accountId: string, avatar: any): Promise<any> {
		const files = new FormData();
		files.append('files', avatar);
		return fetch(`${this.baseUrl}/UploadAvatar/${accountId}`, {
			method: 'POST',
			headers: {
				'Access-Control-Allow-Credentials': 'true',
			},
			body: files,
			credentials: 'include'
		})
			.then((res) => {
				if (res.ok) return res.text();
				else return res.json();
			})
			.then((json) => {
				if (json.message) return Promise.reject(json);
				else return json;
			})
			.catch(error => Promise.reject(error))
	}

	getLastPasswordChange(accountId: string): Promise<any> {
		return fetch(`${this.baseUrl}/GetLastPasswordChange/${accountId}`, {
			method: 'GET',
			headers: {
				'Access-Control-Allow-Credentials': 'true',
			},
			credentials: 'include'
		})
			.then((res) => {
				if (res.ok) return res.json();
				else return res.json()
			})
			.then((json) => {
				if (typeof json !== 'string') {
					return Promise.reject(json);
				} else return json;
			})
			.catch((error) => Promise.reject(error))
	}
}
