import { AccountModel, SmallAccountModel, StepModel } from '../../models';


export type AccountState = {
    avatar: string | undefined,
    details: SmallAccountModel,
    steps: StepModel[],
    status : {
        loading: boolean,
        error: {
            code: number,
            message: string,
        }|null,
        msg: string | null,
        verifyUrl: string | null,
        verifyInvite: boolean,
        created: boolean,
        verified: boolean,
        loggedIn: boolean,
        invalidCredentials: boolean,
        attempts: number,
        notVerifiedAttempt: boolean,
        blocked: Date | null,
        emailChange: boolean,
        impersonation: boolean,
    }
}

export const RESET_ACCOUNT_STATE = 'RESET_ACCOUNT_STATE';
export const RESET_LOADING_ACCOUNT = 'RESET_LOADING_ACCOUNT';

type resetLoadingAccount = {
    type: typeof RESET_LOADING_ACCOUNT,
}

export const VALIDATE_INVITE_REQUEST = 'VALIDATE_INVITE_REQUEST';
export const VALIDATE_INVITE_SUCCESS = 'VALIDATE_INVITE_SUCCESS';
export const VALIDATE_INVITE_FAILED = 'VALIDATE_INVITE_FAILED';

type ValidateInviteRequest = {
    type: typeof VALIDATE_INVITE_REQUEST,
}

type ValidateInviteSuccess = {
    type: typeof VALIDATE_INVITE_SUCCESS,
    payload: {
        firstName: string,
        lastName: string,
        email: string,
        id: string,
        password: null|string, 
        jobTitle: null|string,
        suscribe: boolean,
        salesforceAccountId: string,
        salesforceContactId: string, 
    }
}

type ValidateInviteFailed = {
    type: typeof VALIDATE_INVITE_FAILED,
    payload: {
        code: number,
        message: string,
    }
}

export const SIGN_UP_ACCOUNT_REQUEST = 'SIGN_UP_ACCOUNT_REQUEST';
export const SIGN_UP_ACCOUNT_SUCCESS = 'SIGN_UP_ACCOUNT_SUCCESS';
export const SIGN_UP_ACCOUNT_FAILED = 'SIGN_UP_ACCOUNT_FAILED';

type SignUpAccountRequest = {
    type: typeof SIGN_UP_ACCOUNT_REQUEST,
}

type SignUpAccountSuccess = {
    type: typeof SIGN_UP_ACCOUNT_SUCCESS,
    payload: string
}

type SignUpAccountFailed = {
    type: typeof SIGN_UP_ACCOUNT_FAILED,
    payload: string
}

export const CONFIRM_ACCOUNT_REQUEST = 'CONFIRM_ACCOUNT_REQUEST';
export const CONFIRM_ACCOUNT_SUCCESS = 'CONFIRM_ACCOUNT_SUCCESS';
export const CONFIRM_ACCOUNT_FAILED = 'CONFIRM_ACCOUNT_FAILED';

type confirmAccountRequest = {
    type: typeof CONFIRM_ACCOUNT_REQUEST,
}

type confirmAccountSuccess = {
    type: typeof CONFIRM_ACCOUNT_SUCCESS,
}

type confirmAccountFailed = {
    type: typeof CONFIRM_ACCOUNT_FAILED,
    payload: string
}

export const SIGN_IN_ACCOUNT_REQUEST = 'SIGN_IN_ACCOUNT_REQUEST';
export const SIGN_IN_ACCOUNT_SUCCESS = 'SIGN_IN_ACCOUNT_SUCCESS';
export const SIGN_IN_ACCOUNT_FAILED = 'SIGN_IN_ACCOUNT_FAILED';
export const SIGN_IN_ACCOUNT_INVALID = 'SIGN_IN_ACCOUNT_INVALID';
export const SIGN_IN_ACCOUNT_NOT_VERIFIED = 'SIGN_IN_ACCOUNT_NOT_VERIFIED';
export const SIGN_IN_RESET_INVALID_ATTEMPTS = 'SIGN_IN_RESET_INVALID_ATTEMPTS';
export const SIGN_IN_IMPERSONATED_ACCOUNT = "SIGN_IN_IMPERSONATED_ACCOUNT";

type signInAccountRequest = {
    type: typeof SIGN_IN_ACCOUNT_REQUEST,
}

type signInAccountSuccess = {
    type: typeof SIGN_IN_ACCOUNT_SUCCESS,
    payload: string
}

type signInAccountFailed = {
    type: typeof SIGN_IN_ACCOUNT_FAILED,
    payload: string
}

type signInAccountNotVerified = {
    type: typeof SIGN_IN_ACCOUNT_NOT_VERIFIED,
    payload: string,
}

type signInAccountInvalid = {
    type: typeof SIGN_IN_ACCOUNT_INVALID,
    payload: any
}

type signInResetInvalidAttempts = {
    type: typeof SIGN_IN_RESET_INVALID_ATTEMPTS,
}

type signInImpersonatedAccount = {
    type: typeof SIGN_IN_IMPERSONATED_ACCOUNT
}

export const RESEND_VERIFICATION_EMAIL_REQUEST = 'RESEND_VERIFICATION_EMAIL_REQUEST';
export const RESEND_VERIFICATION_EMAIL_SUCCESS = 'RESEND_VERIFICATION_EMAIL_SUCCESS';
export const RESEND_VERIFICATION_EMAIL_FAILED = 'RESEND_VERIFICATION_EMAIL_FAILED';

type resendVerificationEmailRequest = {
    type: typeof RESEND_VERIFICATION_EMAIL_REQUEST,
}

type resendVerificationEmailSuccess = {
    type: typeof RESEND_VERIFICATION_EMAIL_SUCCESS,
}

type resendVerificationEmailFailed = {
    type: typeof RESEND_VERIFICATION_EMAIL_FAILED,
    payload: string,
}

export const SIGN_OUT_ACCOUNT = 'SIGN_OUT_ACCOUNT';

type signOutAccount = {
    type: typeof SIGN_OUT_ACCOUNT,
}

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

type forgotPasswordRequest = {
    type: typeof FORGOT_PASSWORD_REQUEST,
}

type forgotPasswordSuccess = {
    type: typeof FORGOT_PASSWORD_SUCCESS,
}

type forgotPasswordFailed = {
    type: typeof FORGOT_PASSWORD_FAILED,
    payload: string,
}

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

type resetPasswordRequest = {
    type: typeof RESET_PASSWORD_REQUEST,
}

type resetPasswordSuccess = {
    type: typeof RESET_PASSWORD_SUCCESS,
    payload: any,
}

type resetPasswordFailed = {
    type: typeof RESET_PASSWORD_FAILED,
    payload: any
}

export  const GET_ACCOUNT_BY_ID_REQUEST = 'GET_ACCOUNT_BY_ID_REQUEST';
export  const GET_ACCOUNT_BY_ID_SUCCESS = 'GET_ACCOUNT_BY_ID_SUCCESS';
export  const GET_ACCOUNT_BY_ID_FAILED = 'GET_ACCOUNT_BY_ID_FAILED';

type getAccountByIdRequest = {
    type: typeof GET_ACCOUNT_BY_ID_REQUEST,
}

type getAccountByIdSuccess = {
    type: typeof GET_ACCOUNT_BY_ID_SUCCESS,
    payload: SmallAccountModel,
}

type getAccountByIdFailed = {
    type: typeof GET_ACCOUNT_BY_ID_FAILED,
    payload: string
}

export const ACCOUNT_RECOMMENDED_STEPS_REQUEST = 'ACCOUNT_RECOMMENDED_STEPS_REQUEST';
export const ACCOUNT_RECOMMENDED_STEPS_SUCCESS = 'ACCOUNT_RECOMMENDED_STEPS_SUCCESS';
export const ACCOUNT_RECOMMENDED_STEPS_FAILED = 'ACCOUNT_RECOMMENDED_STEPS_FAILED';

type accountRecommendedStepsRequest = {
    type: typeof ACCOUNT_RECOMMENDED_STEPS_REQUEST
}
type accountRecommendedStepsSuccess = {
    type: typeof ACCOUNT_RECOMMENDED_STEPS_SUCCESS,
    payload: any
}
type accountRecommendedStepsFailed = {
    type: typeof ACCOUNT_RECOMMENDED_STEPS_FAILED,
    payload: string,
}

export const SKIP_RECOMMENDED_STEP_REQUEST = 'SKIP_RECOMMENDED_STEP_REQUEST';
export const SKIP_RECOMMENDED_STEP_SUCCESS = 'SKIP_RECOMMENDED_STEP_SUCCESS';
export const SKIP_RECOMMENDED_STEP_FAILED = 'SKIP_RECOMMENDED_STEP_FAILED';

type skipRecommendedStepRequest = {
    type: typeof SKIP_RECOMMENDED_STEP_REQUEST,
}

type skipRecommendedStepSuccess = {
    type: typeof SKIP_RECOMMENDED_STEP_SUCCESS,
    payload: string,
}

type skipRecommendedStepFailed = {
    type:  typeof SKIP_RECOMMENDED_STEP_FAILED,
    payload: string,
}

export const UPDATE_ACCOUNT_PASSWORD_REQUEST = 'UPDATE_ACCOUNT_PASSWORD_REQUEST';
export const UPDATE_ACCOUNT_PASSWORD_SUCCESS = 'UPDATE_ACCOUNT_PASSWORD_SUCCESS';
export const UPDATE_ACCOUNT_PASSWORD_FAILED = 'UPDATE_ACCOUNT_PASSWORD_FAILED';

type updateAccountPasswordRequest = {
    type: typeof UPDATE_ACCOUNT_PASSWORD_REQUEST,
}

type updateAccountPasswordSuccess = {
    type: typeof UPDATE_ACCOUNT_PASSWORD_SUCCESS,
}

type updateAccountPasswordFailed = {
    type: typeof UPDATE_ACCOUNT_PASSWORD_FAILED,
    payload: string,
}

export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILED = 'UPDATE_ACCOUNT_FAILED';

type updateAccountRequest = {
    type: typeof UPDATE_ACCOUNT_REQUEST,
}

type updateAccountSuccess = {
    type: typeof UPDATE_ACCOUNT_SUCCESS,
    payload: any,
}

type updateAccountFailed = {
    type: typeof UPDATE_ACCOUNT_FAILED,
    payload: string,
}

export const EMAIL_CHANGE_TOGGLE = 'EMAIL_CHANGE_TOGGLE';

type emailChangeToggle = {
    type: typeof EMAIL_CHANGE_TOGGLE,
    payload: boolean,
}

export const POST_FB_ACCESS_TOKEN_REQUEST = 'POST_FB_ACCESS_TOKEN_REQUEST';
export const POST_FB_ACCESS_TOKEN_SUCCESS = 'POST_FB_ACCESS_TOKEN_SUCCESS';
export const POST_FB_ACCESS_TOKEN_FAILED = 'POST_FB_ACCESS_TOKEN_FAILED';

type postFbAccessTokenRequest = {
    type: typeof POST_FB_ACCESS_TOKEN_REQUEST
}

type postFbAccessTokenSuccess = {
    type: typeof POST_FB_ACCESS_TOKEN_SUCCESS,
    payload: AccountModel,
}

type postFbAccessTokenFailed = {
    type: typeof POST_FB_ACCESS_TOKEN_FAILED,
    payload: string,
}

export const VALIDATE_FB_INTEGRATION_REQUEST = 'VALIDATE_FB_INTEGRATION_REQUEST';
export const VALIDATE_FB_INTEGRATION_SUCCESS = 'VALIDATE_FB_INTEGRATION_SUCCESS';
export const VALIDATE_FB_INTEGRATION_FAILED = 'VALIDATE_FB_INTEGRATION_FAILED';

type validateFbIntegrationRequest = {
    type: typeof VALIDATE_FB_INTEGRATION_REQUEST,
}

type validateFbIntegrationSuccess = {
    type: typeof VALIDATE_FB_INTEGRATION_SUCCESS,
    payload: any
}

type validateFbIntegrationFailed = {
    type: typeof VALIDATE_FB_INTEGRATION_FAILED,
    payload: string
}

export const UPDATE_ACCOUNT_AVATAR_REQUEST = 'UPDATE_ACCOUNT_AVATAR_REQUEST';
export const UPDATE_ACCOUNT_AVATAR_SUCCESS = 'UPDATE_ACCOUNT_AVATAR_SUCCESS';
export const UPDATE_ACCOUNT_AVATAR_FAILED = 'UPDATE_ACCOUNT_AVATAR_FAILED';

type updateAccountAvatarRequest = { type: typeof UPDATE_ACCOUNT_AVATAR_REQUEST }
type updateAccountAvatarSuccess = { type: typeof UPDATE_ACCOUNT_AVATAR_SUCCESS, payload: string }
type updateAccountAvatarFailed = { type: typeof UPDATE_ACCOUNT_AVATAR_FAILED, payload: any }

export const RESET_ACCOUNT_STATUS = 'RESET_ACCOUNT_STATUS';

type resetAccountStatus = { type: typeof RESET_ACCOUNT_STATUS }

export type DispatchTypes = 
    ValidateInviteRequest | ValidateInviteSuccess | ValidateInviteFailed | resetLoadingAccount |
    SignUpAccountRequest | SignUpAccountSuccess | SignUpAccountFailed |
    confirmAccountRequest | confirmAccountSuccess | confirmAccountFailed |
    signInAccountRequest | signInAccountSuccess | signInAccountFailed | 
    signInAccountInvalid | signInResetInvalidAttempts | signOutAccount |
    forgotPasswordRequest | forgotPasswordSuccess | forgotPasswordFailed |
    resetPasswordRequest | resetPasswordSuccess | resetPasswordFailed |
    getAccountByIdRequest | getAccountByIdSuccess | getAccountByIdFailed |
    accountRecommendedStepsRequest | accountRecommendedStepsSuccess | accountRecommendedStepsFailed |
    skipRecommendedStepRequest | skipRecommendedStepSuccess | skipRecommendedStepFailed | 
    updateAccountRequest | updateAccountSuccess | updateAccountFailed |
    updateAccountPasswordRequest | updateAccountPasswordSuccess | updateAccountPasswordFailed |
    resendVerificationEmailRequest | resendVerificationEmailSuccess | 
    resendVerificationEmailFailed | signInAccountNotVerified |
    postFbAccessTokenRequest | postFbAccessTokenSuccess | postFbAccessTokenFailed |
    validateFbIntegrationRequest | validateFbIntegrationSuccess | validateFbIntegrationFailed |
    emailChangeToggle |
    updateAccountAvatarRequest | updateAccountAvatarSuccess | updateAccountAvatarFailed |
    resetAccountStatus | signInImpersonatedAccount
;