import { BookmarkModel } from '../../models';

export type BookmarkState = {
  current: BookmarkModel,
  items: BookmarkModel[],
  status: {
    loading: boolean,
    error: string,
  }
}

export const SET_BOOKMARK = 'SET_BOOKMARK';

type setBookmark = {
  type: typeof SET_BOOKMARK,
  payload: any,
}

export const GET_BOOKMARKS_BY_ACCOUNT_REQUEST = 'GET_BOOKMARKS_BY_ACCOUNT_REQUEST';
export const GET_BOOKMARKS_BY_ACCOUNT_SUCCESS = 'GET_BOOKMARKS_BY_ACCOUNT_SUCCESS';
export const GET_BOOKMARKS_BY_ACCOUNT_FAILED = 'GET_BOOKMARKS_BY_ACCOUNT_FAILED';

type getBookmarksByAccountRequest = {
  type: typeof GET_BOOKMARKS_BY_ACCOUNT_REQUEST,
}

type getBookmarksByAccountSuccess = {
  type: typeof GET_BOOKMARKS_BY_ACCOUNT_SUCCESS,
  payload: BookmarkModel[],
}

type getBookmarksByAccountFailed = {
  type: typeof GET_BOOKMARKS_BY_ACCOUNT_FAILED,
  payload: any
}

export const CREATE_BOOKMARK_REQUEST = 'CREATE_BOOKMARK_REQUEST';
export const CREATE_BOOKMARK_SUCCESS = 'CREATE_BOOKMARK_SUCCESS';
export const CREATE_BOOKMARK_FAILED = 'CREATE_BOOKMARK_FAILED';

type createBookmarkRequest = {
  type: typeof CREATE_BOOKMARK_REQUEST,
}

type createBookmarkSuccess = {
  type: typeof CREATE_BOOKMARK_SUCCESS,
  payload: BookmarkModel,
}

type createBookmarkFailed = {
  type: typeof CREATE_BOOKMARK_FAILED,
  payload: any
}

export const UPDATE_BOOKMARK_REQUEST = 'UPDATE_BOOKMARK_REQUEST';
export const UPDATE_BOOKMARK_SUCCESS = 'UPDATE_BOOKMARK_SUCCESS';
export const UPDATE_BOOKMARK_FAILED = 'UPDATE_BOOKMARK_FAILED';

type updateBookmarkRequest = {
  type: typeof UPDATE_BOOKMARK_REQUEST,
}

type updateBookmarkSuccess = {
  type: typeof UPDATE_BOOKMARK_SUCCESS,
  payload: BookmarkModel,
}

type updateBookmarkFailed = {
  type: typeof UPDATE_BOOKMARK_FAILED,
  payload: any
}

export const DELETE_BOOKMARK_REQUEST = 'DELETE_BOOKMARK_REQUEST';
export const DELETE_BOOKMARK_SUCCESS = 'DELETE_BOOKMARK_SUCCESS';
export const DELETE_BOOKMARK_FAILED = 'DELETE_BOOKMARK_FAILED';

type deleteBookmarkRequest = {
  type: typeof DELETE_BOOKMARK_REQUEST,
}

type deleteBookmarkSuccess = {
  type: typeof DELETE_BOOKMARK_SUCCESS,
  payload: string,
}

type deleteBookmarkFailed = {
  type: typeof DELETE_BOOKMARK_FAILED,
  payload: any
}

export type DispatchTypes = 
  setBookmark |
  getBookmarksByAccountRequest | getBookmarksByAccountSuccess | getBookmarksByAccountFailed |
  createBookmarkRequest | createBookmarkSuccess | createBookmarkFailed |
  updateBookmarkRequest | updateBookmarkSuccess | updateBookmarkFailed |
  deleteBookmarkRequest | deleteBookmarkSuccess | deleteBookmarkFailed
;
