import { encryptPayload } from './CipherHandler';
import { BookModel, IExtendedCampaignSummaryRequest, PaymentIntentModel } from '../models';
import { ISBN_REGEX } from '../utils/regexp';

export class CampaignService {
	private readonly baseUrl: string
	private readonly accountId: string

	constructor(id: string = "") {
		this.baseUrl = `${process.env.REACT_APP_API_URL}`
		this.accountId = id
	}

	getCampaignsByAccount(page: number, showing: string, folder?: string, pageSize?: number): Promise<any> {
		const url = `${this.baseUrl}/Campaign/GetCampaignsByAccountId/${this.accountId}`;
		let query = !folder ? `?page=${page}&showing=${showing}&pageSize=${pageSize ? pageSize : 20}` : `?folder=${folder}`;

		return fetch(
			url + query,
			{
				method: 'GET',
				headers: { 
					'Content-Type': 'application/json; ',
					'Access-Control-Allow-Credentials': 'true',
				},
				credentials: 'include'
			}
		)
			.then(res => {
				if (res.ok) return res.json()
				else return Promise.reject(res)
			})
			.catch(error => Promise.reject(error))
	}

	getCampaignFilterOptions(): Promise<any> {
		return fetch(
			`${this.baseUrl}/Campaign/GetCampaignFilterOptions/${this.accountId}`,
			{
				method: 'GET',
				headers: { 
					'Content-Type': 'application/json; ',
					'Access-Control-Allow-Credentials': 'true',
				  },
				credentials: 'include'
			}
		)
			.then(res => {
				if (res.ok) return res.json()
				else return Promise.reject(res)
			})
			.catch(error => Promise.reject(error))
	}

	getActiveCampaigns(): Promise<any> {
		return fetch(
			`${this.baseUrl}/Campaign/GetFavoriteCampaigns/${this.accountId}`,
			{
				method: 'GET',
				headers: { 
					'Content-Type': 'application/json; ',
					'Access-Control-Allow-Credentials': 'true',
				  },
				credentials: 'include'
			}
		)
			.then(res => {
				if (res.ok) return res.json()
				else return Promise.reject(res)
			})
			.catch(error => Promise.reject(error))
	}

	getFavoriteCampaigns(): Promise<any> {
		return fetch(
			`${this.baseUrl}/Campaign/GetFavoriteCampaigns/${this.accountId}`,
			{
				method: 'GET',
				headers: { 
					'Content-Type': 'application/json; ',
					'Access-Control-Allow-Credentials': 'true',
				  },
				credentials: 'include'
			}
		)
			.then(res => {
				if (res.ok) return res.json()
				else return Promise.reject(res)
			})
			.catch(error => Promise.reject(error))
	}

	getBooks(search: string): Promise<any> {
		let body: any = {accountId: this.accountId};

		if (ISBN_REGEX.test(search)) {
			body.isbn = search;
		} else {
			body.search = search;
		}

		return fetch(`${this.baseUrl}/Book/GetBooks`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			credentials: 'include',
			body: JSON.stringify(body),
		})
			.then(res => res.json())
			.then(json => {
				if (json.errors) return Promise.reject(json.errors)
				else return json
			})
			.catch(error => Promise.reject(error))
	}

	getBisacKeywords(campaignId: string): Promise<any> {
		return fetch(`${this.baseUrl}/Campaign/GetBisacKeywords/${campaignId}`, {
			method: 'GET',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include'
		})
			.then(res => {
				if (res.ok) return res.json()
				else return Promise.reject(res)
			})
			.catch(error => Promise.reject(error))
	}

	createCampaignBasic(name: string, books: Array<BookModel>): Promise<any> {
		return fetch(`${this.baseUrl}/campaign/CreateCampaignBasic`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include',
			body: JSON.stringify({ accountId: this.accountId, name, books }),
		})
			.then(res => {
				if (res.ok) {
					return res.json()
				}
			})
			.catch(error => Promise.reject(error))
	}

	updateCampaignBasic(
		id: string,
		name: string,
		books: Array<BookModel>
	): Promise<any> {
		return fetch(`${this.baseUrl}/campaign/UpdateCampaignBasic/${id}`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include',
			body: JSON.stringify({ accountId: this.accountId, name, books }),
		})
			.then(res => {
				if (res.ok) {
					return res.json()
				}
			})
			.catch(error => Promise.reject(error))
	}

	updateGoals(data: any): Promise<any> {
		const { id, marketingGoals, promoting, startDate, budget } = data
		return fetch(`${this.baseUrl}/campaign/updateGoals/${id}`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include',
			body: JSON.stringify({
				marketingGoals,
				promoting,
				startDate,
				budget,
			}),
		})
			.then(res => {
				if (res.status === 200) {
					return 'Success'
				} else {
					return res.status
				}
			})
			.catch(error => Promise.reject(error))
	}

	updateRecomendedAudiences(
		audiences: string[],
		campaignId: string
	): Promise<any> {
		return fetch(
			`${this.baseUrl}/campaign/UpdateRecommendedAudiences/${campaignId}`,
			{
				method: 'POST',
				headers: { 
					'Content-Type': 'application/json; ',
					'Access-Control-Allow-Credentials': 'true',
				  },
				credentials: 'include',
				body: JSON.stringify(audiences),
			}
		)
			.then(res => res.status)
			.catch(error => Promise.reject(error))
	}

	getMarketingServices(campaignId: string): Promise<any> {
		return fetch(
			`${this.baseUrl}/Campaign/GetMarketingServiceOptions/${campaignId}`,
			{
				method: 'GET',
				headers: { 
					'Content-Type': 'application/json; ',
					'Access-Control-Allow-Credentials': 'true',
				  },
				credentials: 'include',
			}
		)
			.then(res => {
				if (res.ok) return res.json()
				else return Promise.reject(res)
			})
			.catch(error => Promise.reject(error))
	}

	addMarketingServices(services: string[], campaignId: string): Promise<any> {
		return fetch(
			`${this.baseUrl}/campaign/AddMarketingServices/${campaignId}`,
			{
				method: 'POST',
				headers: { 
					'Content-Type': 'application/json; ',
					'Access-Control-Allow-Credentials': 'true',
				  },
				credentials: 'include',
				body: JSON.stringify(services),
			}
		)
			.then(res => {
				if (res.ok) return res.status
			})
			.catch(error => Promise.reject(error))
	}

	getCampaignById(campaignId: string): Promise<any> {
		return fetch(`${this.baseUrl}/campaign/${campaignId}`, {
			method: 'GET',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include'
		})
			.then(res => res.json())
			.then(json => {
				if (json.errors) return Promise.reject(json.errors)
				else return json
			})
			.catch(error => Promise.reject(error))
	}

	validateCampaign(campaignId: string): Promise<any> {
		return fetch(`${this.baseUrl}/campaign/ValidateCampaign/${campaignId}`, {
			method: 'GET',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include'
		})
			.then(res => {
				if (res.status === 200) return 'success';
				else return res.text();
			})
			.catch(error => Promise.reject(error))
	}

	updateCampaign(campaign: any): Promise<any> {
		const { id } = campaign
		return fetch(`${this.baseUrl}/campaign/${id}`, {
			method: 'PUT',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include',
			body: JSON.stringify(campaign),
		})
			.then(res => {
				if (res.ok) return res.status
				else return Promise.reject(res)
			})
			.catch(error => Promise.reject(error))
	}

	deleteCampaign(campaignId: string): Promise<any> {
		return fetch(`${this.baseUrl}/campaign/${campaignId}`, {
			method: 'DELETE',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include'
		})
			.then(res => {
				if (res.ok) return res.status
				else return Promise.reject(res.json())
			})
			.catch(error => Promise.reject(error))
	}

	processCampaignCheckout(form: any): Promise<any> {
		console.log("checkout form", form);
		const payload = encryptPayload(form);
		return fetch(`${this.baseUrl}/Campaign/ProcessCheckout`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include',
			body: JSON.stringify({ payload }),
		})
			.then(res => res.status)
			.catch(error => Promise.reject(error))
	}

	getLowestPriceService(name: string): Promise<any> {
		return fetch(`${this.baseUrl}/Campaign/GetMarketingServicePrice/${name}`, {
			method: 'GET',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include'
		})
			.then(res => {
				if (res.ok) return res.json()
			})
			.catch(error => Promise.reject(error))
	}

	toggleFavorite(campaignId: string): Promise<any> {
		return fetch(`${this.baseUrl}/Campaign/MarkAsFavorite/${campaignId}`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include'
		})
			.then(res => {
				if (res.status === 200) return true;

				Promise.reject(res.json());
			})
			.catch(error => Promise.reject(error))
	}

	moveToFolder(campaignId: string, folderId: string): Promise<any> {
		return fetch(`${this.baseUrl}/Campaign/MoveToFolder/${campaignId}/${folderId}`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include'
		})
			.then(res => {
				if (res.ok) return res.ok;

				Promise.reject(res.status)
			})
			.catch(error => Promise.reject(error))
	}

	getAmazonTouchpoints(campaignId: string): Promise<any> {
		return fetch(`${this.baseUrl}/Campaign/AmazonTouchpoints/${campaignId}`, {
			method: 'GET',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include'
		})
			.then(res => res.json())
			.catch(error => Promise.reject(error))
	}

	toggleAmazonShareBudget(campaignId: string): Promise<any> {
		return fetch(`${this.baseUrl}/Campaign/MarkShareAmazonBudget/${campaignId}`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			credentials: 'include'
		})
	}

	cloneCampaign(sourceCampaignId: string, targetName: string): Promise<any> {
		const minimumStart = process.env.REACT_APP_SERVICE_MIN_START ? parseInt(process.env.REACT_APP_SERVICE_MIN_START) : 2;

		return fetch(`${this.baseUrl}/Campaign/Clone`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include',
			body: JSON.stringify({ sourceCampaignId, name: targetName, minimumStart }),
		})
			.then(res => res.json())
			.then(resAsJson => {
				if (resAsJson.message) throw (resAsJson);
				return resAsJson;
			})
			.catch(error => Promise.reject(error))
	}

	duplicateMetaInOtherPlacement(metaTouchpointId: string): Promise<any> {
		return fetch(`${this.baseUrl}/Campaign/DuplicateMetaInOtherPlacement/${metaTouchpointId}`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include'
		})
			.then(res => {
				if (!res.ok) {
					return res.json()
				}

				return res;
			})
			.then(resAsJson => {
				if (resAsJson.message) throw (resAsJson);
				return resAsJson;
			})
			.catch(error => Promise.reject(error))
	}

	createPaymentIntent(paymentIntent: PaymentIntentModel): Promise<any> {
		return fetch(`${this.baseUrl}/Campaign/CreatePaymentIntent`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include',
			body: JSON.stringify(paymentIntent),
		})
			.then(res => res.json())
			.then(resAsJson => {
				if (resAsJson.message) throw (resAsJson);
				return resAsJson;
			})
			.catch(error => Promise.reject(error))
	}

	confirmPayment(campaignId: string, invoiceId: string): Promise<any> {
		return fetch(`${this.baseUrl}/Campaign/ConfirmPayment/${campaignId}/${invoiceId}`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			credentials: 'include'
		})
	}

	errorPayment(campaignId: string, invoiceId: string, code:string="",  errorMessage :string=""): Promise<any> {
		return fetch(`${this.baseUrl}/Campaign/errorPayment`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			credentials: 'include',
			body: JSON.stringify({ campaignId, invoiceId, errorCode: code, errorMessage })
		})
	}

	getExtendedCampaignSummary(request: IExtendedCampaignSummaryRequest) {
		return fetch(`${this.baseUrl}/Campaign/Extend/GetCampaignSummary`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			credentials: 'include',
			body: JSON.stringify(request)
		});
	}

	createExtendedPaymentIntent(paymentIntent: PaymentIntentModel): Promise<any> {
		return fetch(`${this.baseUrl}/Campaign/Extend/CreatePaymentIntent`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include',
			body: JSON.stringify(paymentIntent),
		})
			.then(res => res.json())
			.then(resAsJson => {
				if (resAsJson.message) throw (resAsJson);
				return resAsJson;
			})
			.catch(error => Promise.reject(error))
	}

	confirmExtendedPayment(campaignId: string, invoiceId: string): Promise<any> {
		return fetch(`${this.baseUrl}/Campaign/Extend/ConfirmPayment/${campaignId}/${invoiceId}`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			},
			credentials: 'include'
		})
	}

	processExtendedCampaignCheckout(form: any): Promise<any> {
		const payload = encryptPayload(form);
		return fetch(`${this.baseUrl}/Campaign/Extend/ProcessCheckout`, {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json; ',
				'Access-Control-Allow-Credentials': 'true',
			  },
			credentials: 'include',
			body: JSON.stringify({ payload }),
		})
			.then(res => res.status)
			.catch(error => Promise.reject(error))
	}
}
