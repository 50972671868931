import * as types from './types';

const initialState: types.FeedbackState = {
  form: {
    questionType: 0,
    message: ''
  },
  loading: false,
  sent: false,
  error: '',
}

const FeedbackReducer = (
  state : types.FeedbackState = initialState,
  action: any
) => {
  
  switch(action.type) {

    case types.SET_CONTACT_FORM:
    return {
      ...state,
      form: action.payload,
    }
    
    case types.POST_FEEDBACK_REQUEST:
    return {
      ...state,
      loading: true,
      sent: false,
      error: initialState.error,
    }

    case types.POST_FEEDBACK_SUCCESS:
    return {
      ...state,
      loading: false,
      sent: true,
    }

    case types.POST_FEEDBACK_FAILED:
    return {
      ...state,
      loading: false,
      error: action.payload
    }
    
    default:
    return state;
  }
}

export default FeedbackReducer;