import { AccountModel, SmallAccountModel } from '../models'

export function formatAccountDto(account: AccountModel): SmallAccountModel {
  return {
    id: account.id,
    firstName: account.firstName,
    lastName: account.lastName,
    businessName: account.businessName,
    email: account.email,
    jobTitle: account.jobTitle,
    avatarUrl: account.avatarUrl,
    salesforceInvitedContactId: account.salesforceInvitedContactId,
    salesforceContactId: account.salesforceContactId,
    salesforceAccountId: account.salesforceAccountId,
    stripeAccountId: account.stripeAccountId,
    lsiAccountNumber: account.lsiAccountNumber,
    facebookPages: account.facebookPages,
    suscribe: account.suscribe,
    signupCode: account.signupCode,
  }
}
