import { IGoogleTouchpoint, FinalKeywordModel, TouchpointFormError, GoogleValidationItem } from '../../../models';

export type googleTouchpointstate = {
  touchpoints: IGoogleTouchpoint[],
  products: any[],
  prices: any[],
  step: string,
  loading: boolean,
  error: string,
  keywords: {
    data: FinalKeywordModel[],
    customData: FinalKeywordModel[],
    load: boolean,
    error: string,
    customError: string,
  }
  images: {
    landscape: {
      loading: boolean,
      error: string,
    },
    square: {
      loading: boolean,
      error: string,
    },
    logo: {
      loading: boolean,
      error: string,
    },
  }
  formErrors: Array<TouchpointFormError>,
  validationItems: Array<GoogleValidationItem>,
}

export const SET_PS_FORM_ERRORS = 'SET_FB_FORM_ERRORS';

type setFormErrors = {
    type: typeof SET_PS_FORM_ERRORS,
    payload: Array<TouchpointFormError>,
}

export const RESET_PS_FORM_ERRORS = 'RESET_FB_FORM_ERRORS';

type resetFormErrors = {
    type: typeof RESET_PS_FORM_ERRORS,
}

export const DELETE_PS_FORM_ERROR = 'DELETE_FB_FORM_ERROR';

type deleteFormError = {
    type: typeof DELETE_PS_FORM_ERROR,
    payload: string, //field pointer
}



export const SET_PS_TOUCHPOINT_STEP = 'SET_PS_TOUCHPOINT_STEP';

type setTouchpointStep = {
  type: typeof SET_PS_TOUCHPOINT_STEP,
  payload: string,
}

export const SET_PS_TOUCHPOINTS = 'SET_PS_TOUCHPOINTS';

type setTouchpoints = {
  type: typeof SET_PS_TOUCHPOINTS,
  payload: IGoogleTouchpoint[]
}

export const SET_PS_TOUCHPOINT = 'SET_PS_TOUCHPOINT';

type setTouchpoint = {
  type: typeof SET_PS_TOUCHPOINT,
  payload: IGoogleTouchpoint,
}

export const SET_PS_PRICES = 'SET_PS_PRICES';

type setPrices = {
  type: typeof SET_PS_PRICES,
  payload: any[],
}

export const GET_PS_TOUCHPOINT_REQUEST = 'GET_PS_TOUCHPOINT_REQUEST';
export const GET_PS_TOUCHPOINT_SUCCESS = 'GET_PS_TOUCHPOINT_SUCCESS';
export const GET_PS_TOUCHPOINT_FAILED = 'GET_PS_TOUCHPOINT_FAILED';

type getTochpointRequest = {
  type: typeof GET_PS_TOUCHPOINT_REQUEST
}
type getTochpointSuccess = {
  type: typeof GET_PS_TOUCHPOINT_SUCCESS,
  payload: IGoogleTouchpoint,
}
type getTochpointFailed = {
  type: typeof GET_PS_TOUCHPOINT_FAILED,
  payload: string
}

export const UPDATE_PS_TOUCHPOINT_REQUEST = 'UPDATE_PS_TOUCHPOINT_REQUEST';
export const UPDATE_PS_TOUCHPOINT_SUCCESS = 'UPDATE_PS_TOUCHPOINT_SUCCESS';
export const UPDATE_PS_TOUCHPOINT_FAILED = 'UPDATE_PS_TOUCHPOINT_FAILED';

type updateTochpointRequest = {
  type: typeof UPDATE_PS_TOUCHPOINT_REQUEST,
}
type updateTochpointSuccess = {
  type: typeof UPDATE_PS_TOUCHPOINT_SUCCESS,
}
type updateTochpointFailed = {
  type: typeof UPDATE_PS_TOUCHPOINT_FAILED,
  payload: string
}

export const DELETE_PS_TOUCHPOINT_REQUEST = 'DELETE_PS_TOUCHPOINT_REQUEST';
export const DELETE_PS_TOUCHPOINT_SUCCESS = 'DELETE_PS_TOUCHPOINT_SUCCESS';
export const DELETE_PS_TOUCHPOINT_FAILED = 'DELETE_PS_TOUCHPOINT_FAILED';

type deleteTochpointRequest = {
  type: typeof DELETE_PS_TOUCHPOINT_REQUEST
}
type deleteTochpointSuccess = {
  type: typeof DELETE_PS_TOUCHPOINT_SUCCESS,
  payload: string,
}
type deleteTochpointFailed = {
  type: typeof DELETE_PS_TOUCHPOINT_FAILED,
  payload: string,
}

export const GET_PS_AUDIENCE_OPTIONS_REQUEST = 'GET_PS_AUDIENCE_OPTIONS_REQUEST';
export const GET_PS_AUDIENCE_OPTIONS_SUCCESS = 'GET_PS_AUDIENCE_OPTIONS_SUCCESS';
export const GET_PS_AUDIENCE_OPTIONS_FAILED = 'GET_PS_AUDIENCE_OPTIONS_FAILED';

type getAudienceOptionsRequest = {
  type: typeof GET_PS_AUDIENCE_OPTIONS_REQUEST,
}

type getAudienceOptionsSuccess = {
  type: typeof GET_PS_AUDIENCE_OPTIONS_SUCCESS,
  payload: any[]
} 

type getAudienceOptionsFailed = {
  type: typeof GET_PS_AUDIENCE_OPTIONS_FAILED,
  payload: string
} 

export const GET_FINAL_KEYWORDS_REQUEST = 'GET_FINAL_KEYWORDS_REQUEST';
export const GET_FINAL_KEYWORDS_SUCCESS = 'GET_FINAL_KEYWORDS_SUCCESS';
export const GET_FINAL_KEYWORDS_FAILED = 'GET_FINAL_KEYWORDS_FAILED';

type getFinalKeywordsRequest = {
    type: typeof GET_FINAL_KEYWORDS_REQUEST
}

type getFinalKeywordsSuccess = {
    type: typeof GET_FINAL_KEYWORDS_SUCCESS,
    payload: FinalKeywordModel[],
}

type getFinalKeywordsFailed = {
    type: typeof GET_FINAL_KEYWORDS_FAILED,
    payload: string,
}

export const GET_CUSTOM_KEYWORDS_REQUEST = 'GET_CUSTOM_KEYWORDS_REQUEST';
export const GET_CUSTOM_KEYWORDS_SUCCESS = 'GET_CUSTOM_KEYWORDS_SUCCESS';
export const GET_CUSTOM_KEYWORDS_FAILED = 'GET_CUSTOM_KEYWORDS_FAILED';

type getCustomKeywordsRequest = {
    type: typeof GET_CUSTOM_KEYWORDS_REQUEST
}

type getCustomKeywordsSuccess = {
    type: typeof GET_CUSTOM_KEYWORDS_SUCCESS,
    payload: FinalKeywordModel[],
}

type getCustomKeywordsFailed = {
    type: typeof GET_CUSTOM_KEYWORDS_FAILED,
    payload: string,
}

export const CLEAR_CUSTOM_KEYWORDS = "CLEAR_CUSTOM_KEYWORDS";

type clearCustomKeywords = {
  type: typeof CLEAR_CUSTOM_KEYWORDS
}

export const UPLOAD_LANDSCAPE_IMAGES_REQUEST = 'UPLOAD_LANDSCAPE_IMAGES_REQUEST';
export const UPLOAD_LANDSCAPE_IMAGES_SUCCESS = 'UPLOAD_LANDSCAPE_IMAGES_SUCCESS';
export const UPLOAD_LANDSCAPE_IMAGES_FAILED = 'UPLOAD_LANDSCAPE_IMAGES_FAILED';

type uploadLandscapeImageRequest = {
  type: typeof UPLOAD_LANDSCAPE_IMAGES_REQUEST
}

type uploadLandscapeImageSuccess = {
  type: typeof UPLOAD_LANDSCAPE_IMAGES_SUCCESS
}

type uploadLandscapeImageFailed = {
  type: typeof UPLOAD_LANDSCAPE_IMAGES_FAILED,
  payload: string
}

export const UPLOAD_SQUARE_IMAGES_REQUEST = 'UPLOAD_SQUARE_IMAGES_REQUEST';
export const UPLOAD_SQUARE_IMAGES_SUCCESS = 'UPLOAD_SQUARE_IMAGES_SUCCESS';
export const UPLOAD_SQUARE_IMAGES_FAILED = 'UPLOAD_SQUARE_IMAGES_FAILED';

type uploadSquareImagesRequest = {
  type: typeof UPLOAD_SQUARE_IMAGES_REQUEST,
}

type uploadSquareImagesSuccess = {
  type: typeof UPLOAD_SQUARE_IMAGES_SUCCESS,
}
type uploadSquareImagesFailed = {
  type: typeof UPLOAD_SQUARE_IMAGES_FAILED,
  payload: string
}

export const UPLOAD_LOGO_IMAGES_REQUEST = 'UPLOAD_LOGO_IMAGES_REQUEST';
export const UPLOAD_LOGO_IMAGES_SUCCESS = 'UPLOAD_LOGO_IMAGES_SUCCESS';
export const UPLOAD_LOGO_IMAGES_FAILED = 'UPLOAD_LOGO_IMAGES_FAILED';

type uploadLogoImagesRequest = {
  type: typeof UPLOAD_LOGO_IMAGES_REQUEST,
}

type uploadLogoImagesSuccess = {
  type: typeof UPLOAD_LOGO_IMAGES_SUCCESS,
}
type uploadLogoImagesFailed = {
  type: typeof UPLOAD_LOGO_IMAGES_FAILED,
  payload: string
}

export const SET_VALIDATION_ITEMS = "SET_GOOGLE_VALIDATION_ITEMS";
export const RESET_VALIDATION_ITEMS = "RESET_GOOGLE_VALIDATION_ITEMS";

type setValidationItems = {
  type: typeof SET_VALIDATION_ITEMS,
  payload: Array<GoogleValidationItem>
}

type resetValidationItems = {
  type: typeof RESET_VALIDATION_ITEMS,
}

export type DispatchTypes = 
  setTouchpointStep | setTouchpoints | setTouchpoint | setPrices |
  getTochpointRequest | getTochpointSuccess | getTochpointFailed |
  updateTochpointRequest | updateTochpointSuccess | updateTochpointFailed |
  deleteTochpointRequest | deleteTochpointSuccess | deleteTochpointFailed | 
  getAudienceOptionsRequest | getAudienceOptionsSuccess | getAudienceOptionsFailed |
  getFinalKeywordsRequest | getFinalKeywordsSuccess | getFinalKeywordsFailed |
  getCustomKeywordsRequest | getCustomKeywordsSuccess | getCustomKeywordsFailed |
  clearCustomKeywords |
  uploadLandscapeImageRequest | uploadLandscapeImageSuccess | uploadLandscapeImageFailed |
  uploadSquareImagesRequest | uploadSquareImagesSuccess | uploadSquareImagesFailed |
  uploadLogoImagesRequest | uploadLogoImagesSuccess | uploadLogoImagesFailed | 
  setFormErrors | resetFormErrors | deleteFormError |
  setValidationItems | resetValidationItems
; 