import * as types from './types';

const initialState: types.FaqsState = {
  data: {
    count: 0,
    next_page: null,
    page: 0,
    per_page: 0,
    previous_page: null,
    articles: [],
    sort_by: '',
    sort_order: '',
  },
  status: {
    loading: false,
    error: ''
  }
}

const FaqsReducer = (
  state: types.FaqsState = initialState,
  action: any
) => {

  switch( action.type ) {

    case types.GET_FAQS_REQUEST: 
    return {
      ...state,
      status: {
        ...state.status,
        loading: true,
      },
    }
    
    case types.GET_FAQS_SUCCESS: 
    return {
      ...state,
      data: action.payload,
      status: {
        loading: false,
        error: initialState.status.error,
      },
    }
    
    case types.GET_FAQS_FAILED: 
    return {
      ...state,
      status: {
        ...state.status,
        loading: false,
        error: action.payload,
      },
    }

    case types.GET_FAQ_BY_ID_REQUEST: 
    return {
      ...state,
      status: {
        ...state.status,
        loading: true,
      }
    }
    
    case types.GET_FAQ_BY_ID_SUCCESS: 
    return {
      ...state,
      selectedFaq: action.payload,
      status: {
        ...state.status,
        loading: false,
      },
    }

    case types.GET_FAQ_BY_ID_FAILED: 
    return {
      ...state,
      status: {
        loading: false,
        error: action.payload
      }
    }
      
    default : {
      return state;
    }
  }
}

export default FaqsReducer;