import { ReportModel } from '../../models';

const campaign = 'campaign';
const service = 'service';

export type ReportState = {
  grouping: typeof campaign | typeof service, 
  options: {
    campaigns: any[],
    titles: any[],
  },
  selectedOptions: any[],
  images: {
    global: string,
    clicks: string,
    impressions: string,
    cpc: string,
    ctr: string,
  },
  config: {
    campaignIds: string[],
    booksIds: string[],
    sinceDate: Date,
    untilDate: Date,
    range: undefined | number,
  },
  report: ReportModel,
  status: {
    loading: boolean,
    error: string,
  }
  needsAttentionCampaigns: any[]
}

export const SET_REPORT_OPTIONS = "SET_REPORT_OPTIONS"; 

type setSelectedOptions = {
  type: typeof SET_REPORT_OPTIONS,
  payload: any[],
}

export const SET_REPORT_CONFIG = 'SET_REPORT_CONFIG';

type setReportConfig = {
  type: typeof SET_REPORT_CONFIG,
  payload: any
}

export const SET_REPORT_DATE_RANGE = 'SET_REPORT_DATE_RANGE';

type setReportDaterRange = {
  type: typeof SET_REPORT_DATE_RANGE,
  payload: number
}

export const SET_REPORT_GROUPING = 'SET_REPORT_GROUPING';

type setReportGrouping = {
  type: typeof SET_REPORT_GROUPING,
  payload: string,
}

export const SET_CHART_IMAGES = 'SET_CHART_IMAGES';

type setChartImages = {
  type: typeof SET_CHART_IMAGES,
  payload: {
    clicks?: string,
    global?: string,
    cpc?: string,
    ctr?: string,
    impressions?: string
  }
}

export const GET_FILTER_OPTIONS_REQUEST = 'GET_FILTER_OPTIONS_REQUEST';
export const GET_FILTER_OPTIONS_SUCCESS = 'GET_FILTER_OPTIONS_SUCCESS';
export const GET_FILTER_OPTIONS_FAILED = 'GET_FILTER_OPTIONS_FAILED';

type getFilterOptionsRequest = {
  type: typeof GET_FILTER_OPTIONS_REQUEST,
}

type getFilterOptionsSuccess = {
  type: typeof GET_FILTER_OPTIONS_SUCCESS,
  payload: {
    campaigns: any[],
    titles: any[]
  }
}

type getFilterOptionsFailed = {
  type: typeof GET_FILTER_OPTIONS_FAILED,
  payload: string,
}

export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAILED = 'GET_REPORT_FAILED';

type getReportRequest = {
  type: typeof GET_REPORT_REQUEST,
}

type getReportSuccess = {
  type: typeof GET_REPORT_SUCCESS,
  payload: any
}

type getReportFailed = {
  type: typeof GET_REPORT_FAILED,
  payload: string
}

export const GET_NEEDS_ATTENTION_CAMPAIGNS_REQUEST = 'GET_NEEDS_ATTENTION_CAMPAIGNS_REQUEST';
export const GET_NEEDS_ATTENTION_CAMPAIGNS_SUCCESS = 'GET_NEEDS_ATTENTION_CAMPAIGNS_SUCCESS';
export const GET_NEEDS_ATTENTION_CAMPAIGNS_FAILED = 'GET_NEEDS_ATTENTION_CAMPAIGNS_FAILED';

type getNeedsAttentionCampaignsRequest = {
  type: typeof GET_NEEDS_ATTENTION_CAMPAIGNS_REQUEST
}

type getNeedsAttentionCampaignsSuccess = {
  type: typeof GET_NEEDS_ATTENTION_CAMPAIGNS_SUCCESS,
  payload: any
}

type getNeedsAttentionCampaignsFailed = {
  type: typeof GET_NEEDS_ATTENTION_CAMPAIGNS_FAILED
}

export type DispatchTypes = 
  setSelectedOptions | setReportConfig | setReportGrouping | setChartImages | setReportDaterRange |
  getFilterOptionsRequest | getFilterOptionsSuccess | getFilterOptionsFailed |
  getReportRequest | getReportSuccess | getReportFailed | 
  getNeedsAttentionCampaignsRequest | getNeedsAttentionCampaignsSuccess | getNeedsAttentionCampaignsFailed
;