import { IFacebookTouchpoint, TouchpointFormError } from "../../../models";

export type FacebookTouchpointState = {
  touchpoints: IFacebookTouchpoint[],
  products: any[],
  prices: any[],
  step: string,
  loading: boolean,
  imageLoading: boolean,
  error: string,
  touchpointError: any,
  formErrors: Array<TouchpointFormError>,
}

export const SET_FB_FORM_ERRORS = 'SET_FB_FORM_ERRORS';

type setFormErrors = {
    type: typeof SET_FB_FORM_ERRORS,
    payload: Array<TouchpointFormError>,
}

export const RESET_FB_FORM_ERRORS = 'RESET_FB_FORM_ERRORS';

type resetFormErrors = {
    type: typeof RESET_FB_FORM_ERRORS,
}

export const DELETE_FB_FORM_ERROR = 'DELETE_FB_FORM_ERROR';

type deleteFormError = {
    type: typeof DELETE_FB_FORM_ERROR,
    payload: string, //field pointer
}

//| setFormErrors | resetFormErrors | deleteFormError
export const SET_FB_TOUCHPOINT_STEP = 'SET_FB_TOUCHPOINT_STEP';

type setTouchpointStep = {
  type: typeof SET_FB_TOUCHPOINT_STEP,
  payload: string,
}

export const SET_FB_TOUCHPOINTS = 'SET_FB_TOUCHPOINTS';

type setFbTouchpoints = {
  type: typeof SET_FB_TOUCHPOINTS,
  payload: IFacebookTouchpoint[],
}

export const SET_FB_TOUCHPOINT = 'SET_FB_TOUCHPOINT';

type setFbTouchpoint = {
  type: typeof SET_FB_TOUCHPOINT,
  payload: IFacebookTouchpoint
}

export const SET_FB_TOUCHPOINT_PRICES = 'SET_FB_TOUCHPOINT_PRICES';

type setAvailablePrices = {
    type: typeof SET_FB_TOUCHPOINT_PRICES,
    payload: any[],
}

export const SELECT_FB_TOUCHPOINT = 'SELECT_TOUCHPOINT';

type selectFbTouchpoint = {
    type: typeof SELECT_FB_TOUCHPOINT,
    payload: string,
}

export const DELETE_FB_TOUCHPOINT_REQUEST = 'DELETE_FB_TOUCHPOINT_REQUEST';
export const DELETE_FB_TOUCHPOINT_SUCCESS = 'DELETE_FB_TOUCHPOINT_SUCCESS';
export const DELETE_FB_TOUCHPOINT_FAILED = 'DELETE_FB_TOUCHPOINT_FAILED';

type deleteFbTouchpointRequest = {
    type: typeof DELETE_FB_TOUCHPOINT_REQUEST,
}

type deleteFbTouchpointSuccess = {
    type: typeof DELETE_FB_TOUCHPOINT_SUCCESS,
    payload: any,
}

type deleteFbTouchpointFailed = {
    type: typeof DELETE_FB_TOUCHPOINT_FAILED,
    payload: string,
}

export const SET_FACEBOOK_TOUCHPOINT = 'SET_FACEBOOK_TOUCHPOINT';

type setFacebookTouchpoint = {
    type: typeof SET_FACEBOOK_TOUCHPOINT,
    touchpoint: any
}

export const GET_FB_TOUCHPOINT_REQUEST = 'GET_FB_TOUCHPOINT_REQUEST';
export const GET_FB_TOUCHPOINT_SUCCESS = 'GET_FB_TOUCHPOINT_SUCCESS';
export const GET_FB_TOUCHPOINT_FAILED = 'GET_FB_TOUCHPOINT_FAILED';

type getFbTouchpointRequest = {
    type: typeof GET_FB_TOUCHPOINT_REQUEST,
}

type getFbTouchpointSuccess = {
    type: typeof GET_FB_TOUCHPOINT_SUCCESS,
    payload: any,
}

type getFbTouchpointFailed = {
    type: typeof GET_FB_TOUCHPOINT_FAILED,
    payload: string,
}

export const GET_FB_TOUCHPOINT_AUDIENCES_REQUEST = 'GET_FB_TOUCHPOINT_AUDIENCES_REQUEST';
export const GET_FB_TOUCHPOINT_AUDIENCES_SUCCESS = 'GET_FB_TOUCHPOINT_AUDIENCES_SUCCESS';
export const GET_FB_TOUCHPOINT_AUDIENCES_FAILED = 'GET_FB_TOUCHPOINT_AUDIENCES_FAILED';

type getFbTouchpointAudiencesRequest = {
    type: typeof GET_FB_TOUCHPOINT_AUDIENCES_REQUEST,
}

type getFbTouchpointAudiencesSuccess = {
    type: typeof GET_FB_TOUCHPOINT_AUDIENCES_SUCCESS,
    payload: any
}

type getFbTouchpointAudiencesFailed = {
    type: typeof GET_FB_TOUCHPOINT_AUDIENCES_FAILED,
    payload: string
}

export const UPDATE_FB_TOUCHPOINT_REQUEST = 'UPDATE_FB_TOUCHPOINT_REQUEST';
export const UPDATE_FB_TOUCHPOINT_SUCCESS = 'UPDATE_FB_TOUCHPOINT_SUCCESS';
export const UPDATE_FB_TOUCHPOINT_FAILED = 'UPDATE_FB_TOUCHPOINT_FAILED';

type updateFbTouchpointRequest = {
    type: typeof UPDATE_FB_TOUCHPOINT_REQUEST,
}

type updateFbTouchpointSuccess = {
    type: typeof UPDATE_FB_TOUCHPOINT_SUCCESS,
    payload: any
}

type updateFbTouchpointFailed = {
    type: typeof UPDATE_FB_TOUCHPOINT_FAILED,
    payload: string,
}

export const UPLOAD_FB_IMAGE_REQUEST = 'UPLOAD_FB_IMAGE_REQUEST';
export const UPLOAD_FB_IMAGE_SUCCESS = 'UPLOAD_FB_IMAGE_SUCCESS';
export const UPLOAD_FB_IMAGE_FAILED = 'UPLOAD_FB_IMAGE_FAILED';

type uploadFbImageRequest = {
    type: typeof UPLOAD_FB_IMAGE_REQUEST,
}

type uploadFbImageSuccess = {
    type: typeof UPLOAD_FB_IMAGE_SUCCESS,
    payload: {
        image: string,
        id: string,
    }
}

type uploadFbImageFailed = {
    type: typeof UPLOAD_FB_IMAGE_FAILED,
    payload: string,
} 

export const REMOVE_FB_IMAGE_REQUEST = "REMOVE_FB_IMAGE_REQUEST";
export const REMOVE_FB_IMAGE_SUCCESS = "REMOVE_FB_IMAGE_SUCCESS";
export const REMOVE_FB_IMAGE_FAILED = "REMOVE_FB_IMAGE_FAILED";

type removeFbImageRequest = {
    type: typeof REMOVE_FB_IMAGE_REQUEST
}

type removeFbImageSuccess = {
    type: typeof REMOVE_FB_IMAGE_SUCCESS,
    payload: string
}

type removeFbImageFailed = {
    type: typeof REMOVE_FB_IMAGE_FAILED,
    payload: string
}


export type DispatchTypes = 
  setTouchpointStep | setFbTouchpoints | setFbTouchpoint | setAvailablePrices |
  getFbTouchpointRequest | getFbTouchpointSuccess | getFbTouchpointFailed | selectFbTouchpoint |
  getFbTouchpointAudiencesRequest | getFbTouchpointAudiencesSuccess | getFbTouchpointAudiencesFailed |    
  updateFbTouchpointRequest | updateFbTouchpointSuccess | updateFbTouchpointFailed |
  deleteFbTouchpointRequest | deleteFbTouchpointSuccess | deleteFbTouchpointFailed | 
  uploadFbImageRequest | uploadFbImageSuccess | uploadFbImageFailed | setFacebookTouchpoint |
  removeFbImageRequest | removeFbImageSuccess | removeFbImageFailed | 
  setFormErrors | resetFormErrors | deleteFormError
;