import React, { createContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { deleteCampaign } from "../../../../reducers/Campaigns";
import { RootStore } from "../../../../store";
import { Modal } from "../../../Modals";

type CampaignContextModel = {
  deleteModal: boolean;
  target: string;
  goToChangeAudiences(): void;
  openDeleteModal(id: string): void;
  setTarget(target: string): void;
  setGuided(val: boolean): void;
};

type ProviderProps = {
  children: React.ReactNode;
};

export const CampaignContext = createContext<CampaignContextModel>({
  deleteModal: false,
  target: "",
  goToChangeAudiences: () => {},
  openDeleteModal: () => {},
  setTarget: () => {},
  setGuided: () => {},
});

const CampaignContextProvider: React.FC<ProviderProps> = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [link, setLink] = useState<string>("");
  const accountId = useSelector((store: RootStore) => store.account.details.id);

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [target, setTarget] = useState<string>("");
  const [guided, setGuided] = useState<boolean>(false);

  const openDeleteModal = (id: string) => {
    setTarget(id);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setTarget("");
    setDeleteModal(false);
  };

  const handleDelete = () => {
    dispatch(deleteCampaign(accountId, target));
    history.replace("/app/campaigns");
    closeDeleteModal();
  };

  const goToChangeAudiences = () => {
    history.push(link);
  };

  useEffect(() => {
    if (target !== "") {
      setLink(`/app/create-campaign?target=${target}`);
    } else {
      setLink("/app/create-campaign");
    }
  }, [target]);

  useEffect(() => {
    if (guided) {
      dispatch({ type: "SET_GUIDED_FORM" });
    } else {
      dispatch({ type: "SET_BYO_FORM" });
    }
  }, [guided, dispatch]);

  return (
    <CampaignContext.Provider
      value={{
        deleteModal,
        target,
        goToChangeAudiences,
        openDeleteModal,
        setTarget,
        setGuided,
      }}
    >
      {children}

      <Modal
        open={deleteModal}
        handleClose={closeDeleteModal}
        children={
          <Alert severity="error">
            <AlertTitle>
              <strong>Are you sure?</strong>
            </AlertTitle>
            <p style={{ width: "40ch" }}>
              If you change delete this campaign, you will loose all configured
              services.
            </p>
          </Alert>
        }
        confirm={{
          buttonText: "Delete campaign",
          action: handleDelete,
        }}
      />
    </CampaignContext.Provider>
  );
};

export default CampaignContextProvider;
