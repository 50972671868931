import { FilterControl } from "../reducers/Campaigns/Filter/types";

export function getShowing(controls: FilterControl) {
	if (controls.all.value) return '0,1,2,3,4,5,6,7';

	let showing = [];

	if (controls.needsAttention.value) {
		showing.push(0);
	}
	if (controls.inReview.value) {
		showing.push(1);
		showing.push(2);
	}
	if (controls.scheduled.value) {
		showing.push(3)
	}
	if (controls.live.value) {
		showing.push(4)
	}
	if (controls.draft.value) {
		showing.push(5);
	}
	if (controls.completed.value) {
		showing.push(6);
	}
	if (controls.cancelled.value) {
		showing.push(7);
	}

	return showing.join(',');
}

export function getCampaignStatus(campaignStatus: number): string {
	let status: string = "";

	if (campaignStatus === 0) {
		status = "NEEDS ATTENTION";
	}

	if (campaignStatus > 0 && campaignStatus < 5) {
		status = "IN PROGRESS";
	}

	if (campaignStatus === 5) {
		status = "DRAFT";
	}

	if (campaignStatus === 6) {
		status = "FINISHED";
	}

	if (campaignStatus === 7) {
		status = "CANCELLED";
	}

	return status;
}