import * as types from './types';

const initialState: types.NotificationsState = {
  data: [],
  total: 0,
  new: [],
  campaign: [],
  status: {
    loading: false,
    secondaryLoad: false,
    error: ''
  }
}

const NotificationsReducer = (
  state: types.NotificationsState = initialState,
  action: any
): types.NotificationsState => {

  switch(action.type) {

    case types.GET_NOTIFICATIONS_REQUEST : {
      return {
        ...state,
        status: {
          ...state.status,
          error: initialState.status.error,
          secondaryLoad: true,
        }
      }
    }

    case types.GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.notifications,
        total: action.payload.total,
        status: {
          ...state.status,
          secondaryLoad: false,
        }
      }
    }

    case types.GET_NOTIFICATIONS_FAILED: {
      return {
        ...state,
        status: {
          ...state.status,
          secondaryLoad: false,
          error: action.payload
        }
      }
    }

    case types.GET_NEW_NOTIFICATIONS_REQUEST : {
      return {
        ...state,
        status: {
          ...state.status,
          error: initialState.status.error,
          loading: true,
        }
      }
    }

    case types.GET_NEW_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        new: action.payload,
        status: {
          ...state.status,
          loading: false,
        }
      }
    }

    case types.GET_NEW_NOTIFICATIONS_FAILED: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: action.payload
        }
      }
    }

    case types.SET_READ_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          error: initialState.status.error
        }
      }
    }

    case types.SET_READ_NOTIFICATIONS_SUCCESS: {
      const readNotifications = state.new.map(
        (n: any) => {
          return { ...n, read : !n.read }
        }  
      );

      return {
        ...state,
        new: readNotifications,
        status: {
          ...state.status,
          loading: false,
        }
      }
    }

    case types.SET_READ_NOTIFICATIONS_FAILED: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: action.payload,
        }
      }
    }

    case types.DISMISS_NOTIFICATION_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          error: initialState.status.error,
          secondaryLoad: true,
        }
      }
    }

    case types.DISMISS_NOTIFICATION_SUCCESS: {
      const rest = state.data.filter(
        (n: any) => n.id !== action.payload
      );
      const campaign = state.campaign.filter(
        (n: any) => n.id !== action.payload
      )

      const [targetData] = state.data.filter(
        (n: any) => n.id === action.payload
      );
      const [targetNotification] = state.campaign.filter(
        (n: any) => n.id === action.payload
      );

      const notification = targetData ?? targetNotification;

      notification.dismiss = true;

      return {
        ...state,
        data: [...rest, notification],
        campaign: [...campaign, notification],
        status: {
          ...state.status,
          secondaryLoad: false
        }
      }
    }

    case types.DISMISS_NOTIFICATION_FAILED: {
      return {
        ...state,
        status: {
          ...state.status,
          secondaryLoad: false,
          error: action.payload,
        }
      }
    }

    case types.DISMISS_BULK_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          error: initialState.status.error
        }
      }
    }

    case types.DISMISS_BULK_NOTIFICATIONS_SUCCESS: {
      const dismissed = state.campaign.map((c) => {
        return {
          ...c,
          dismiss: true
        }
      }) 

      return {
        ...state,
        campaign: dismissed,
        status: {
          ...state.status,
          loading: false,
        }
      }
    }

    case types.DISMISS_BULK_NOTIFICATIONS_FAILED: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: action.payload
        }
      }
    }

    case types.SET_CAMPAIGN_NOTIFICATIONS: {
      return {
        ...state,
        campaign: action.payload, 
      }
    }

    default:
      return state;

  }
}

export default NotificationsReducer;