import * as types from './types';

const initialState: types.StatementsState = {
  statements: [],
  range: {
    sinceDate: null,
    untilDate: null,
  },
  table: {
    page: 0,
    count: 10,
    selected: []
  },
  status: {
    loading: false,
    error: ''
  }
}

const StatementsReducer = (
  state: types.StatementsState = initialState,
  action: any
) => {

  switch(action.type) {

    case types.SET_DATE_RANGE: {
      return {
        ...state,
        range: action.payload,
      }
    }

    case types.SET_TABLE_COUNT: {
      return {
        ...state,
        table: {
          ...state.table,
          count: action.payload
        }
      }
    }

    case types.SET_TABLE_PAGE: {
      return {
        ...state,
        table: {
          ...state.table,
          page: action.payload
        }
      }
    }

    case types.SELECT_TABLE_ROWS: {
      return {
        ...state,
        table: {
          ...state.table,
          selected: action.payload
        }
      }
    }

    case types.GET_STATEMENTS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: true
        }
      }
    }

    case types.GET_STATEMENTS_SUCCESS: {
      return {
        ...state,
        statements: action.payload,
        status: {
          ...state.status,
          loading: false,
        }
      }
    }

    case types.GET_STATEMENTS_FAILED: {
      return {
        ...state,
        status: {
          loading: false,
          error: action.payload,
        }
      }
    }

    default: {
      return state;
    }
  }
}

export default StatementsReducer;