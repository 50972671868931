import { CardCampaignModel, FilterOption } from '../../../models';

export type FilterOptions = {
	authors: Array<FilterOption>,
	campaigns: Array<FilterOption>,
	titles: Array<FilterOption>
}

export type FilterData = {
	0: Array<CardCampaignModel>,
	1: Array<CardCampaignModel>,
	2: Array<CardCampaignModel>,
	3: Array<CardCampaignModel>,
	4: Array<CardCampaignModel>,
	5: Array<CardCampaignModel>,
	6: Array<CardCampaignModel>,
	7: Array<CardCampaignModel>,
}

export type FilterControl = {
	all: {
		count: number,
		value: boolean,
	},
	needsAttention: {
		count: number,
		value: boolean,
	},
	inReview: {
		count: number,
		value: boolean,
	},
	scheduled: {
		count: number,
		value: boolean,
	},
	live: {
		count: number,
		value: boolean,
	},
	draft: {
		count: number,
		value: boolean,
	},
	completed: {
		count: number,
		value: boolean,
	},
	cancelled: {
		count: number,
		value: boolean,
	},
	pagination: {
		maxPages: number,
		currentPage: number,
		totalCampaigns: number,
	}
}

type Folder = {
	id: string,
	title: string,
	parentFolder: string | null,
	childs: Array<Folder>,
	accountId: string,
	campaignCovers: Array<any>,
	favorite: boolean
}

export type FilterState = {
	controls: FilterControl,
	options: FilterOptions,
	campaigns: Array<CardCampaignModel>,
	results: Array<CardCampaignModel>,
	selected: Array<FilterOption>
	data: FilterData,
	currentFolder: string | null,
	moveTarget: string | null,
	path: Array<string>,
	folders: Array<Folder>,
	favorites: Array<Folder>,
	showing: string,
	status: {
		filterLoad: boolean,
		folderLoad: boolean,
		campaignLoad: boolean,
		error: any
	}
}

export const RESET_CAMPAIGN_FILTER_STATE = "RESET_CAMPAIGN_FILTER_STATE";

type resetCampaignFilterState = {
	type: typeof RESET_CAMPAIGN_FILTER_STATE,
}

export const GET_CAMPAIGNS_BY_ACCOUNT_REQUEST =
	'GET_CAMPAIGNS_BY_ACCOUNT_REQUEST'
export const GET_CAMPAIGNS_BY_ACCOUNT_SUCCESS =
	'GET_CAMPAIGNS_BY_ACCOUNT_SUCCESS'
export const GET_CAMPAIGNS_BY_ACCOUNT_FAILED = 'GET_CAMPAIGNS_BY_ACCOUNT_FAILED'

type getCampaignsByAccountRequest = {
	type: typeof GET_CAMPAIGNS_BY_ACCOUNT_REQUEST
}

type getCampaignsByAccountSuccess = {
	type: typeof GET_CAMPAIGNS_BY_ACCOUNT_SUCCESS
	payload: any
}

type getCampaignsByAccountFailed = {
	type: typeof GET_CAMPAIGNS_BY_ACCOUNT_FAILED
	payload: string
}

export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS';

type setFilterOptions = {
	type: typeof SET_FILTER_OPTIONS,
	payload: any
}

export const SET_FILTER_CONTROLS = 'SET_FILTER_CONTROLS';

type setFilterControls = {
	type: typeof SET_FILTER_CONTROLS,
	payload: any
}

export const SET_FILTER_DATA = 'SET_FILTER_DATA';

type setFilterData = {
	type: typeof SET_FILTER_DATA,
	payload: any
}

export const SET_FILTER_RESULTS = 'SET_FILTER_RESULTS';

type setFilterResults = {
	type: typeof SET_FILTER_RESULTS,
	payload: Array<any>
}

export const SELECT_STATUS_CONTROL = 'SELECT_STATUS_CONTROL';

type setFilterControl = {
	type: typeof SELECT_STATUS_CONTROL,
	payload: FilterControl,
}
//Folders
export const GET_ACCOUNT_FOLDERS_REQUEST = 'GET_ACCOUNT_FOLDERS_REQUEST';
export const GET_ACCOUNT_FOLDERS_SUCCESS = 'GET_ACCOUNT_FOLDERS_SUCCESS';
export const GET_ACCOUNT_FOLDERS_FAILED = 'GET_ACCOUNT_FOLDERS_FAILED';

type getAccountFoldersRequest = {
	type: typeof GET_ACCOUNT_FOLDERS_REQUEST,
}

type getAccountFoldersSuccess = {
	type: typeof GET_ACCOUNT_FOLDERS_SUCCESS,
	payload: Array<Folder>,
}

type getAccountFoldersFailed = {
	type: typeof GET_ACCOUNT_FOLDERS_FAILED,
	payload: any,
}

export const GET_FAVORITE_FOLDERS_REQUEST = "GET_FAVORITE_FOLDERS_REQUEST";
export const GET_FAVORITE_FOLDERS_SUCCESS = "GET_FAVORITE_FOLDERS_SUCCESS";
export const GET_FAVORITE_FOLDERS_FAILED = "GET_FAVORITE_FOLDERS_FAILED";

type getFavoriteFoldersRequest = {
	type: typeof GET_FAVORITE_FOLDERS_REQUEST,
}

type getFavoriteFoldersSuccess = {
	type: typeof GET_FAVORITE_FOLDERS_SUCCESS,
	payload: Array<Folder>
}

type getFavoriteFoldersFailed = {
	type: typeof GET_FAVORITE_FOLDERS_FAILED,
	payload: any
}

export const SET_CURRENT_FOLDER = 'SET_CURRENT_FOLDER';

type setCurrentFolder = {
	type: typeof SET_CURRENT_FOLDER,
	payload: string,
}

export const SET_FOLDER_PATH = "SET_FOLDER_PATH";

type setFolderPath = {
	type: typeof SET_FOLDER_PATH,
	payload: Array<string>
}

export const CREATE_FOLDER_REQUEST = 'CREATE_FOLDER_REQUEST';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_FAILED = 'CREATE_FOLDER_FAILED';

type createFolderRequest = {
	type: typeof CREATE_FOLDER_REQUEST,
}

type createFolderSuccess = {
	type: typeof CREATE_FOLDER_SUCCESS,
	payload: Folder,
}

type createFolderFailed = {
	type: typeof CREATE_FOLDER_FAILED,
	payload: any
}

export const UPDATE_FOLDER_REQUEST = 'UPDATE_FOLDER_REQUEST';
export const UPDATE_FOLDER_SUCCESS = 'UPDATE_FOLDER_SUCCESS';
export const UPDATE_FOLDER_FAILED = 'UPDATE_FOLDER_FAILED';

type updateFolderRequest = {
	type: typeof UPDATE_FOLDER_REQUEST,
}

type updateFolderSuccess = {
	type: typeof UPDATE_FOLDER_REQUEST,
	payload: any
}

type updateFolderFailed = {
	type: typeof UPDATE_FOLDER_REQUEST,
	payload: any
}

export const DELETE_FOLDER_REQUEST = 'DELETE_FOLDER_REQUEST';
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS';
export const DELETE_FOLDER_FAILED = 'DELETE_FOLDER_FAILED';

type deleteFolderRequest = {
	type: typeof DELETE_FOLDER_REQUEST,
}

type deleteFolderSuccess = {
	type: typeof DELETE_FOLDER_REQUEST,
	payload: any
}

type deleteFolderFailed = {
	type: typeof DELETE_FOLDER_REQUEST,
	payload: any
}

export const SET_MOVE_TARGET = 'SET_MOVE_TARGET';

type setMoveTarget = {
	type: typeof SET_MOVE_TARGET,
	payload: string | null
}

export const TOGGLE_FOLDER_FAVORITE = 'TOGGLE_FOLDER_FAVORITE';

type toggleFolderFavorite = {
	type: typeof TOGGLE_FOLDER_FAVORITE,
	payload: string,
}

export const TOGGLE_CAMPAIGN_FAVORITE = "TOGGLE_CAMPAIGN_FAVORITE";

type toggleCampaignFavorite = {
	type: typeof TOGGLE_CAMPAIGN_FAVORITE,
	payload: string
}


export const MOVE_TO_FOLDER_REQUEST = 'MOVE_TO_FOLDER_REQUEST';
export const MOVE_TO_FOLDER_SUCCESS = 'MOVE_TO_FOLDER_SUCCESS';
export const MOVE_TO_FOLDER_FAILED = 'MOVE_TO_FOLDER_FAILED';

type moveToFolderRequest = {
	type: typeof MOVE_TO_FOLDER_REQUEST
}

type moveToFolderSuccess = {
	type: typeof MOVE_TO_FOLDER_SUCCESS,
	payload: {
		folderId: string,
		campaignId: string
	}
}

type moveToFolderFailed = {
	type: typeof MOVE_TO_FOLDER_FAILED,
	payload: any
}

export const DELETE_CAMPAIGN_FROM_FILTER = "DELETE_CAMPAIGN_FROM_FILTER";

type deleteCampaignFromFilter = {
	type: typeof DELETE_CAMPAIGN_FROM_FILTER,
	payload: string,
}

export const SET_SELECTED_OPTIONS = "SET_SELECTED_OPTIONS";

type setSelectectedOptions = {
	type: typeof SET_SELECTED_OPTIONS,
	payload: Array<FilterOption>
}

export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

type setCurrentPage = {
	type: typeof SET_CURRENT_PAGE,
	payload: number,
}

export type DispatchTypes = resetCampaignFilterState
	| getCampaignsByAccountRequest | getCampaignsByAccountSuccess | getCampaignsByAccountFailed
	| setFilterOptions
	| setFilterData
	| setFilterControl
	| setFilterControls
	| setFilterResults
	| getAccountFoldersRequest | getAccountFoldersSuccess | getAccountFoldersFailed
	| setCurrentFolder
	| getFavoriteFoldersRequest | getFavoriteFoldersSuccess | getFavoriteFoldersFailed
	| createFolderRequest | createFolderSuccess | createFolderFailed
	| setFolderPath
	| updateFolderRequest | updateFolderSuccess | updateFolderFailed
	| setMoveTarget
	| deleteFolderRequest | deleteFolderSuccess | deleteFolderFailed
	| toggleFolderFavorite
	| toggleCampaignFavorite
	| moveToFolderRequest
	| moveToFolderSuccess
	| moveToFolderFailed
	| deleteCampaignFromFilter
	| setSelectectedOptions
	| setCurrentPage
	;