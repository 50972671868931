import * as types from './types';

const initialState: types.FacebookTouchpointState = {
  touchpoints: [],
  products: [],
  prices: [],
  step: 'panel1',
  loading: false,
  imageLoading: false,
  error: '',
  touchpointError: undefined,
  formErrors: [],
}

const facebookTouchpointReducer = (
  state: types.FacebookTouchpointState = initialState,
  action: any
) : types.FacebookTouchpointState => {

  switch (action.type) {

    case types.SET_FB_TOUCHPOINT_STEP: {
      return {
        ...state,
        step: action.payload,
      }
    }

    case types.SET_FB_TOUCHPOINTS: {
      return {
        ...state,
        touchpoints: action.payload,
      }
    }

    case types.SET_FB_TOUCHPOINT: {
      const filtered = state.touchpoints.filter(
        (t) => t.id !== action.payload.id
      )
      return {
        ...state,
        touchpoints: [ ...filtered, action.payload ],
        loading: false,
      }
    }

    case types.SET_FB_FORM_ERRORS: {
      return {
        ...state,
        formErrors: action.payload,
      }
    }

    case types.RESET_FB_FORM_ERRORS: {
      return {
        ...state,
        formErrors: [],
      }
    }

    case types.DELETE_FB_FORM_ERROR: {
      return {
        ...state,
        formErrors: state.formErrors.filter(
          (e) => e.field !== action.payload
        ),
      }
    }

    case types.SET_FB_TOUCHPOINT_PRICES: {
      return {
        ...state,
        prices: action.payload
      }
    }

    case types.GET_FB_TOUCHPOINT_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
      
    case types.GET_FB_TOUCHPOINT_SUCCESS: {
      const filtered = state.touchpoints.filter(
        (t) => t.id !== action.payload.id
      )
      return {
        ...state,
        touchpoints: [ ...filtered, action.payload ],
        loading: false,
      }
    }

    case types.GET_FB_TOUCHPOINT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }

    case types.UPDATE_FB_TOUCHPOINT_REQUEST: {
      return {
        ...state,
        touchpointError: initialState.touchpointError,
        loading: true,
      }
    }
      
    case types.UPDATE_FB_TOUCHPOINT_SUCCESS: {
      const filtered = state.touchpoints.filter(
        (t) => t.id !== action.payload.id
      )
      return {
        ...state,
        touchpoints: [ ...filtered, action.payload ],
        loading: false,
      }
    }

    case types.UPDATE_FB_TOUCHPOINT_FAILED: {
      return {
        ...state,
        loading: false,
        touchpointError: action.payload,
      }
    }

    case types.DELETE_FB_TOUCHPOINT_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
      
    case types.DELETE_FB_TOUCHPOINT_SUCCESS: {
      const filtered = state.touchpoints.filter(
        (t) => t.id !== action.payload
      )
      return {
        ...state,
        touchpoints: [ ...filtered ],
        loading: false,
      }
    }

    case types.DELETE_FB_TOUCHPOINT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }

    
    case types.GET_FB_TOUCHPOINT_AUDIENCES_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
      
    case types.GET_FB_TOUCHPOINT_AUDIENCES_SUCCESS: {

      return {
        ...state,
        products: action.payload,
        loading: false,
      }
    }

    case types.GET_FB_TOUCHPOINT_AUDIENCES_FAILED: {
      return {
        ...state,
        products: action.payload,
        loading: false,
      }
    }
    
    case types.UPLOAD_FB_IMAGE_REQUEST: {
      return {
        ...state,
        imageLoading: true,
        error: initialState.error
      }
    }

    case types.UPLOAD_FB_IMAGE_SUCCESS: {
      const rest = state.touchpoints.filter(
        touchpoint => touchpoint.id !== action.payload.id
      );

      const [ update ] = state.touchpoints.filter(
        touchpoint => touchpoint.id === action.payload.id
      );
      update.image = action.payload.image;

      return {
        ...state,
        touchpoints: [...rest, update],
        imageLoading: false,

      }
    }

    case types.UPLOAD_FB_IMAGE_FAILED: {
      return {
        ...state,
        imageLoading: false,
        error: action.payload
      }
    }

    case types.REMOVE_FB_IMAGE_REQUEST: {
      return {
        ...state,
        imageLoading: true,
        error: initialState.error,
      }
    }

    case types.REMOVE_FB_IMAGE_SUCCESS: {
      const touchpoints = state.touchpoints;
      const target = state.touchpoints.findIndex((t) => t.id === action.payload);

      touchpoints[target].image = ""

      return {
        ...state,
        touchpoints: touchpoints,
        imageLoading: false,
      }
    }

    case types.REMOVE_FB_IMAGE_FAILED: {
      return {
        ...state,
        imageLoading: false,
        error: action.payload
      }
    }
  
    default:
    return state;
  }
}

export default facebookTouchpointReducer;