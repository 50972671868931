import React, { useEffect } from "react";

const GoogleAnalytics = () => {
  useEffect(() => {
    const body = document.body;
    const script = document.createElement("script");
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-NNNBMW6W');
    `;

    const s = document.getElementsByTagName("script")[0];
    s?.parentNode?.insertBefore(script, document.head.firstChild);

    const noscript = document.createElement("noscript");
	
	const iframe = document.createElement("iframe");
	iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-NNNBMW6W";
	iframe.height = 0;
	iframe.width = 0;
	iframe.style = "display:none;visibility:hidden";

	noscript.append(iframe);


    body.insertBefore(noscript, body.firstChild);

    return () => {
		document.head.removeChild(script);
		document.body.removeChild(noscript);
	};
  }, []);

  return <React.Fragment />;
};

export default GoogleAnalytics;
