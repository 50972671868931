import { FC, useEffect } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button } from "../index";
import { useKeyPress } from "../../hooks";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			background: "rgba(255, 255, 255, .4)",
			zIndex: theme.zIndex.tooltip,
		},
		modal: {
			minWidth: 400,
			padding: 0,
			borderRadius: 8,
			overflow: "hidden",
			"& header": {
				background: "#F6F6F6",
				padding: theme.spacing(2, 4),
				width: "100%",
				maxHeight: 100,
			},
			"& section": {
				padding: theme.spacing(2, 4),
				background: "#F6F6F6",
				width: "100%",
				maxHeight: 570,
				overflowY: "auto",
			},
			"& footer": {
				alignItems: "center",
				background: "#F6F6F6",
				display: "flex",
				gap: theme.spacing(2),
				padding: theme.spacing(3),
				width: "100%",
			},

			[theme.breakpoints.down("sm")]: {
				width: "95%",

				"& header": {
					padding: theme.spacing(2)
				},

				"& section": {
					padding: theme.spacing(2)
				},

				"& footer": {
					padding: theme.spacing(2)
				}

			}
		},


	})
);

type ModalProps = {
	open: boolean;
	handleClose(): void;
	confirm: {
		buttonText: string;
		action?: () => void;
		loading?: boolean;
		disabled?: boolean;
	};
	title?: string;
	children?: any;
	closeText?: string;
	backgroundColor?: string;
};

const Modal: FC<ModalProps> = ({
	children,
	open,
	handleClose,
	title,
	confirm,
	closeText,
	backgroundColor,
}) => {
	const { root, modal } = useStyles();

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		confirm.action && confirm.action();
	};

	const escPress = useKeyPress("Escape");

	useEffect(() => {
		if (escPress === true) {
			handleClose();
		}
	}, [escPress, handleClose]);

	return (
		<Backdrop open={open} className={root}>
			<Paper elevation={3} className={modal}>
				<header>
					{title && <Typography variant="h3">{title}</Typography>}
				</header>
				{children && (
					<section style={{ backgroundColor: backgroundColor }}>
						{children}
					</section>
				)}
				<footer>
					<Button
						mode="primary"
						type="submit"
						onClick={handleClick}
						loading={confirm.loading}
						disabled={confirm.disabled}
					>
						{confirm.buttonText}
					</Button>
					<Button mode="light" type="button" onClick={handleClose}>
						{closeText ?? "Cancel"}
					</Button>
				</footer>
			</Paper>
		</Backdrop>
	);
};

export default Modal;
