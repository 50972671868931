import React from "react";
import { useSelector } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import ingramLogo from "../../assets/logoWhite.svg";
import { RootStore } from "../../store";
import { useStyles } from "./footer.styles";

const links = [
  {
    label: "About Ingram",
    href: "https://www.ingramcontent.com/about",
    separator: true,
  },
  {
    label: "Contact Us",
    href: "/app/contact-us",
    separator: true,
  },
  {
    label: "Privacy Policy",
    href: "https://www.ingramcontent.com/page/privacy-policy",
    separator: true,
  },
  {
    label: 'Cookie Preferences',
    href: "#",
    separator: true
  },
  {
    label: "Website Terms of Use",
    href: "/legal/Site_Terms.pdf",
    separator: true,
  },
  {
    label: "TERMS AND CONDITIONS FOR ONLINE SALES",
    href: "/legal/Sales_Terms.pdf",
    separator: false,
  },
  
];

const Footer: React.FC = () => {
  const classes = useStyles();
  const separator = <span className={classes.separator}> | </span>;
  const isAuth = useSelector(
    (store: RootStore) => store.account.status.loggedIn
  );

  return (
    <>
      <CssBaseline />
      <footer className={classes.root} style={{ display: isAuth ? 'inline-block' : 'none'}}>
        <Grid container>
          <Grid item md={3} className={classes.brand}>
            <img src={ingramLogo} alt="Ingram Content Group" />
          </Grid>
          <Grid item md={9} className={classes.nav}>
            {React.Children.toArray(
              links.map((link: any) => link.label === 'Cookie Preferences' 
              ? (
                <>
                  <span id="ot-sdk-btn" className="ot-sdk-show-settings">
                    Cookie preferences
                  </span>
                  {link.separator && separator}
                </>
              ) : (
                <>
                  <a href={link.href}>{link.label}</a>
                  {link.separator && separator}
                </>
                
              )))}
          </Grid>
          <Grid item md={12} className={classes.legal}>
            <p>{`© Copyright ${new Date().getFullYear() } Ingram Content Group LLC. All Rights Reserved.`}</p>
          </Grid>
        </Grid>
      </footer>
    </>
  );
};

export default Footer;
