export class RecommendedAudienceService {
	private readonly baseUrl: string
	private readonly headers: any

    constructor () {
        this.baseUrl = `${process.env.REACT_APP_API_URL}/RecommendedAudience`;
        this.headers = { 
            'Content-Type': 'application/json; ',
            // 'Access-Control-Allow-Credentials': 'true',          
        };

        
        return this;
    }

    getAllAudiences(): Promise<any> {
        return fetch(this.baseUrl, {
            method: 'GET',
            headers: this.headers,
            credentials: "include"
        })
            .then(res => res.json())
            .catch(error => Promise.reject(error))
    }

    getRecomendedAudiences(campaignId: string): Promise<any> {
        return fetch(`${this.baseUrl}/${campaignId}`, {
            method: 'GET',
            headers: this.headers,
            credentials: "include"
        })
            .then(res => res.json())
            .catch(error => Promise.reject(error))
    }

    getAllRecomendedAudiences(campaignId: string): Promise<any> {
        //if(process.env.NODE_ENV === 'development') return Promise.resolve(response);

        return fetch(`${this.baseUrl}/${campaignId}`, {
            method: 'GET',
            headers: this.headers,
            credentials: "include"
        })
            .then(res => res.json())
            .catch(error => Promise.reject(error))
    }

    getAudienceAvailableDates(audience: string): Promise<any> {

        return fetch(`${this.baseUrl}/GetEmailBlastAvailability/${audience}`, {
            method: 'GET',
            headers: this.headers,
            credentials: "include"
        })
            .then(res => res.json())
            .catch(error => Promise.reject(error));  
    }
    
}