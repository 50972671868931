import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    root: {
      alignItems: 'center',
      background: '#007BC7',
      bottom: 0,
      color: '#FFFFFF',
      display: 'flex',
      minHeight: 150,
      justifyContent: 'space-between',
      //marginTop: 150,
      padding: theme.spacing(6, 15),
      //position: 'absolute',
      width: '100%',
      zIndex: theme.zIndex.drawer,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        textAlign: 'center',
        heigth: 'auto',
        bottom: -2,
        padding: theme.spacing(5),
      }
    },
    brand: {
      '& img': {
        cursor: 'pointer',
        width: 120,
      },
      [theme.breakpoints.down('sm')]: {
        '& img': {
          margin: '5px auto'
        }
      }
    },
    nav: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& a': {
        color: '#FFFFFF',
        cursor: 'pointer',
        fontFamily: "'Open sans', sans serif",
        fontSize: 14,
        textDecoration: 'none',
        lineHeight: 1.2,
        margin: '0px 10px',   
        '&:hover': {
          opacity: .9,
        },
      },
      '& .ot-sdk-show-settings': {
        maxWidth: '30vw',
        color: "#FFFFFF !important",
        fontSize:"14px !important",
        border: 'none !important',
        cursor: 'pointer ',
        fontFamily: "'Open sans', sans serif !important",
        textDecoration: 'none !important',
        padding: theme.spacing(.2,.5) + " !important",
        lineHeight: 1.2,
        '&:hover': {
          backgroundColor: "transparent !important",
          opacity: .9,
        },
      },
      [theme.breakpoints.down('sm')]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '5px',
      }
    },
    separator: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    legal: {
      '& p': {
        fontFamily: "'Lato', sans-serif",
        fontWeight: 'bold',
        cursor: 'pointer',
        letterSpacing: 1,
        fontSize: theme.typography.pxToRem(12),
      },
    }
  })
);