import { createCipheriv } from 'crypto';


export function encryptPayload(data: any) {
  const message = typeof data === 'string' ? data : JSON.stringify(data);
  const { REACT_APP_CIPHER_KEY, REACT_APP_CIPHER_IV } = process.env;
  
  if(!REACT_APP_CIPHER_KEY || !REACT_APP_CIPHER_IV) return;

  const key = Buffer.from(REACT_APP_CIPHER_KEY);
  const iv = Buffer.from(REACT_APP_CIPHER_IV);
  const cipher = createCipheriv('aes256', key, iv);

  return cipher.update(message, 'utf8', 'base64') + cipher.final('base64');
}