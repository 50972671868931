import { Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../store";
import { RouteModel } from "./route";
import { RouteWithSubRoutes } from "./RouteWithSubRoutes";
import { signOutAccount } from "../reducers/Account/actions";
//import { history } from '.';
//import Signin from '../pages/sign-in';

export const ProtectedRoute = (route: RouteModel): JSX.Element => {
  const dispatch = useDispatch();

  const isAuth = useSelector(
    (store: RootStore) => store.account.status.loggedIn
  );

  if (!isAuth) {
    if (window.location.pathname !== "/app/contact-us" && window) {
      localStorage.setItem("targetUrl", window.location.pathname.toString() + window.location.search.toString()); //Save target url CMMP-934
      dispatch(signOutAccount());
    }
  }

  return isAuth || window.location.pathname === "/app/contact-us" ? (
    <RouteWithSubRoutes {...route} />
  ) : (
    <Redirect push to={{ pathname: "/" }} />
  );
};
