import * as types from './types';
import moment from 'moment';

const initialState: types.ReportState = {
  grouping: 'campaign',
  options: {
    campaigns: [],
    titles: [],
  },
  selectedOptions: [],
  images: {
    global: '',
    impressions: '',
    clicks: '',
    ctr: '',
    cpc: '',
  },
  config: {
    campaignIds: [],
    booksIds: [],
    sinceDate: new Date(moment().subtract(30, 'days').toDate().setHours(0,0,0,0)),
    untilDate: new Date(new Date().setHours(0,0,0,0)),
    range: undefined,
  },
  report: {
    campaigns: [],
    services: [],
    chart: [],
    impressionsWidget : {
      impressions: 0,
      cumulativeImpressions: [],
      expectedImpressions: [0,0,0,0]
    },
    clicksWidget: {
      clicks: 0,
      cumulativeClicks: [],
      expectedClicksArray: [0,0,0,0]
    },
    cpcWidget: {
      costPerClick: 0,
      cpcArray: [0,0,0,0,0],
    },
    ctrWidget: {
      clickThroughRate: 0,
    }
  },
  status: {
    loading: false,
    error: '',
  },
  needsAttentionCampaigns: []
}

const ReportReducer = (
  state: types.ReportState = initialState,
  action: any
) => {

  switch(action.type) {

    case types.SET_REPORT_CONFIG: {
      return {
        ...state,
        config: action.payload,
      }
    }

    case types.SET_REPORT_OPTIONS: {
      return {
        ...state,
        selectedOptions: action.payload,
      }
    }

    case types.SET_REPORT_DATE_RANGE: {
      return {
        ...state,
        config: {
          ...state.config,
          sinceDate: new Date(moment().subtract(action.payload, 'days').toDate().setHours(0,0,0,0)),
          untilDate: new Date(new Date().setHours(0,0,0,0)),
          range: action.payload,
        }
      }
    }

    case types.SET_REPORT_GROUPING: {
      return {
        ...state,
        grouping: action.payload,
      }
    }

    case types.SET_CHART_IMAGES: {
      return {
        ...state,
        images: {
          ...state.images,
          ...action.payload,
        }
      }
    }

    case types.GET_FILTER_OPTIONS_REQUEST: {
      return {
        ...state,
        status: {
          loading: true,
          error: initialState.status.error
        }
      }
    }

    case types.GET_FILTER_OPTIONS_SUCCESS: {
      return {
        ...state,
        options: action.payload,
        status: {
          ...state.status,
          loading: false,
        }
      }
    }

    case types.GET_FILTER_OPTIONS_FAILED: {
      return {
        ...state,
        status: {
          loading: false,
          error: action.payload
        }
      }
    }

    case types.GET_REPORT_REQUEST: {
      return {
        ...state,
        status: {
          loading: true,
          error:  initialState.status.error
        }
      }
    }

    case types.GET_REPORT_SUCCESS: {
      return {
        ...state,
        report: action.payload,
        status: {
          ...state.status,
          loading: false,
        }
      }
    }

    case types.GET_REPORT_FAILED: {
      return {
        ...state,
        status: {
          loading: false,
          error: action.payload
        }
      }
    }

    case types.GET_NEEDS_ATTENTION_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        needsAttentionCampaigns: action.payload
      }
    }

    default:
      return state;
  }
}

export default ReportReducer;