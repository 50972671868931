import ReactDom from "react-dom";
import { useDispatch } from "react-redux";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CloseRounded from "@material-ui/icons/CloseRounded";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "../../../components";
import { signOutAccount } from "src/reducers/Account";

type FetchErrorModalProps = {
	open: boolean
    onClose?: () => void
}
const FetchErrorModal: React.FC<FetchErrorModalProps> = ({
	open,
    onClose
}) => {
	const css = useStyles();
    const dispatch = useDispatch();

    const handleReload = () => {
        window.location.reload()
        
		if (onClose) {
			onClose();
		}
    }

    const handleGoToLogin = () => {
    	if (onClose) {
			onClose();
		}

		dispatch(signOutAccount())
    }

	return ReactDom.createPortal(
		<Backdrop open={open} className={css.root}>
			<Paper className={css.modal}>
                    { onClose && (
						<IconButton size="small" className={css.close} onClick={onClose}>
							<CloseRounded />
						</IconButton>
					)}
                    <WarningRoundedIcon fontSize="large" className={css.icon} />
                    <Typography variant="h3">
                        <b>Something went wrong.</b>
                    </Typography>
                    <Typography variant="body1" style={{ marginTop: 16 }}>
                        It seems we lost connection. You can either reload this page or go back to login. Sorry for the inconvenience.
                    </Typography>
                    
                
                    <Box className={css.actions}>
                        <Button onClick={handleReload} size="small">Reload</Button>
                        { window.location.pathname !== "/" && (
                            <Button mode="light" onClick={handleGoToLogin} size="small">Back to Login</Button>
                        )}
                    </Box>
			</Paper>
		</Backdrop>
		,
		document.getElementById("modal-portal") as HTMLElement
	);
};

export default FetchErrorModal;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			background: "rgba(255, 255, 255, .25)",
			zIndex: theme.zIndex.tooltip,
		},
		modal: {
            display:"flex",
            flexDirection: "column",
			padding: theme.spacing(2, 4),
			borderRadius: theme.spacing(1),
            alignItems: 'center',
			border: "3px solid " + theme.palette.warning.main,
			overflow: "hidden",
			boxShadow: "0 2px 48px 0 rgba(0,0,0,0.11)",
			background: "#FFFFFF",
            maxWidth: 400,
            position: 'relative'
		},
		close: {
			position: 'absolute',
			top: theme.spacing(1),
			right: theme.spacing(1),
		},
        icon: {
            color: theme.palette.warning.main,
            margin: theme.spacing(2,'auto')
        },
		actions: {
			display: 'flex',
			gap: theme.spacing(2),
            marginTop: theme.spacing(4),
			"& button": {
				padding: theme.spacing(1, 2),
				//fontSize: theme.typography.pxToRem(14),
			},
		},
	}));