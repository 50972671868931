import { Dispatch } from 'redux';
import { CampaignFormModel } from '../../models';
import { setTouchpoints as setFbTouchpoints } from './FacebookAd/actions';
import { setTouchpoints as setPSTouchpoints } from './GoogleSearch/actions';
import { setTouchpoints as setAZTouchpoints } from './AmazonAd/actions';
import { setTouchpoints as setEBTouchpoints } from './EmailBlast';

export const syncTouchpoints = (campaign: CampaignFormModel) => (dispatch: Dispatch<any>) => {
  dispatch(setFbTouchpoints(campaign.facebookTouchpoints));
  dispatch(setPSTouchpoints(campaign.googleTouchpoints));
  dispatch(setAZTouchpoints(campaign.amazonTouchpoints));
  dispatch(setEBTouchpoints(campaign.emailBlastTouchpoints));
}


export * as FacebookTouchpoint from './FacebookAd';
export * as GoogleTouchpoint from './GoogleSearch'; 
export * as AmazonTouchpoint from './AmazonAd';
export * as EmailBlastTouchpoint from './EmailBlast';
