import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, { ErrorInfo } from 'react';
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import ingramLogo from "../assets/logo.png";


class RootBoundary extends React.Component {
	state: {
		hasError: boolean,
		error?: Error,
		errorInfo?: ErrorInfo,
		cookieEnabled?: boolean,
	};

	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo) {
		console.log("**** name", error.name)
		return { hasError: true, error: error, errorInfo: errorInfo }
	}

	componentDidCatch(error: Error, errorInfo: any) {

		console.table(error.name, errorInfo)
		console.log("**** error", JSON.stringify(error, null, 2))

	}

	componentDidMount() {
		function areCookiesEnabled() {
			// set cookie
			document.cookie = "testCookie=test";

			// verify if cookie is saved
			const cookiesEnabled = document.cookie.indexOf("testCookie") !== -1;

			// delete cookie
			document.cookie = "testCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

			return cookiesEnabled;
		}

		this.setState({ cookieEnabled: areCookiesEnabled() });

	}

	render() {
		if (!this.state.cookieEnabled) {
			return (
				<Box style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100vh", flexDirection: "column" }}>
					<Box style={{ marginBottom: "32px" }}>
						<img src={ingramLogo} alt="Ingram Content Group" width={150} />
					</Box>
					
					<Typography variant='h2'>Oops! An error has occurred.</Typography>

					<Typography variant='body1' style={{ marginTop: '2em' }}>
						To continue, please enable cookies in your browser and refresh the page.
					</Typography>
					<Typography variant='body1' style={{ marginBottom: '2em' }}>
						You can find instructions on how to enable cookies <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en" target="_blank" rel="noopener noreferrer">here</a>.
					</Typography>

					<Button
						endIcon={<CachedRoundedIcon />}
						style={{
							padding: '.5em 2em',
							borderRadius: '8px',
							fontWeight: 'bold',
						}}
						variant='contained'
						//color='primary' 
						onClick={() => window.location.reload()}
					>
						Refresh Page
					</Button>
				</Box>
			)
		}
		
		if (this.state.hasError) {
			console.log(this.state.error, this.state.errorInfo);
			return (
				<Box style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100vh", flexDirection: "column" }}>
					<Box style={{ marginBottom: "32px" }}>
						<img src={ingramLogo} alt="Ingram Content Group" width={150} />
					</Box>
					
					<Typography variant='h2'>Oops! An error has occurred.</Typography>

					<Button
						endIcon={<CachedRoundedIcon />}
						style={{
							padding: '.5em 2em',
							borderRadius: '8px',
							fontWeight: 'bold',
						}}
						variant='contained'
						//color='primary' 
						onClick={() => window.location.reload()}
					>
						Refresh Page
					</Button>
				</Box>
			)
		}

		return this.props.children;
	}
}

export default RootBoundary;