import { IGoogleTouchpoint, GoogleValidationItem, GoogleValidationResponseItem } from "../models";

function getCodeByTrace(trace: string) {
  const obj = JSON.parse(trace);
  const keys = Object.keys(obj);
  return obj[keys[0]];
}

function getFieldByTrace(touchpoint: IGoogleTouchpoint, hint: string) {
  if(!hint) return "unknown";

  const validator = new RegExp(hint);
  const { headlines, descriptions, link, longHeadline, touchpointType } = touchpoint;

  if(validator.test(headlines.join("/n"))) return "headlines";
  if(validator.test(descriptions.join("/n"))) return "descriptions";
  if(validator.test(link)) return "link";

  if(touchpointType === 1 && validator.test(longHeadline)) return "longHeadline"; 

  return "unknown";
}


export function processValidateResponse(response: any, touchpoint: IGoogleTouchpoint) {
  if(!response.message || typeof response === "string") return;

  const { message } = response;
  
  try {
    const errors = typeof message === "string" ? JSON.parse(message) : [];
    const finalList: Array<GoogleValidationItem> = [];

    errors.forEach((error: GoogleValidationResponseItem) => {
      const field = getFieldByTrace(touchpoint, error.Trace)
      const item = {
        field,
        message: error.Message,
        trace: error.Trace,
        code: getCodeByTrace(error.Code),
      }
      finalList.push(item);
    });

    return finalList;
  } catch (error) {
    console.error(error);
    return;
  }
}
