import { Dispatch } from 'redux';
import { AmazonService } from '../../../services/AmazonService';
import { CampaignService } from '../../../services/CampaignService';
import { IAmazonTouchpoint, TouchpointFormError } from '../../../models';
import { processTouchpoint } from '../../../utils/Touchpoint';
import { getCampaign } from '../../Campaigns';
import { history } from '../../../router';
import { store } from '../../../store';
import * as types from './types';

export const setTouchpoints = (touchpoints: any) => {
  return {
    type: types.SET_AZ_TOUCHPOINTS,
    payload: touchpoints
  }
}

export const setTouchpoint = (touchpoint: any) => {
  return {
    type: types.SET_AZ_TOUCHPOINT,
    payload: touchpoint
  }
}

export const setTouchpointStep = (step: number) => {
  return {
    type: types.SET_AZ_TOUCHPOINT_STEP,
    payload: `panel${step}`
  }
}

export const getTouchpoint = (touchpointId: string) => async (dispatch: Dispatch<types.DispatchTypes>) => {
  try {
    dispatch({ type: types.GET_AZ_TOUCHPOINT_REQUEST});
        
    const service = new AmazonService(touchpointId);
    const touchpoint = await service.getTouchpoint();
    processTouchpoint(touchpoint);

    dispatch({
      type: types.GET_AZ_TOUCHPOINT_SUCCESS,
      payload: touchpoint,
    });
    
  } catch (error) {
    dispatch({
      type: types.GET_AZ_TOUCHPOINT_FAILED,
      payload: JSON.stringify(error)
    })
  }
}

export const updateTouchpoint = (touchpoint:any, accountId:string) => async (dispatch: Dispatch<types.DispatchTypes>) => {
  
  const touchpointService = new AmazonService(touchpoint.id);
  const campaignService = new CampaignService(accountId);
  console.log('fromAction :', touchpoint);
  try {
    dispatch({ type: types.UPDATE_AZ_TOUCHPOINT_REQUEST });
    //validate bookId prop 
    if (typeof touchpoint.bookId === 'object') { 

      const { id, bookId } = touchpoint;
      const bookIds = bookId;
      const targetUpdate = Object.assign(touchpoint, {bookId: bookIds[0]});
      //update touchpoint
      await touchpointService.updateTouchpoint(targetUpdate);
      //clone touchpoint
      if(bookIds.length > 1) {
        await touchpointService.cloneTouchpoint(id, bookIds);
      }

    } else {
      //update touchpoint
      await touchpointService.updateTouchpoint(touchpoint);
      
      if(touchpoint.clone) {
        const touchpoints = store.getState().amazonTouchpoints.touchpoints;
        const bookIds = touchpoints
          .filter((t: IAmazonTouchpoint) => t.clone)
          .map((t: IAmazonTouchpoint) => t.bookId);

        //clone touchpoints
        await touchpointService.cloneTouchpoint(touchpoint.id, bookIds);
      } 

    }
    //get touchpoint siblings
    const touchpoints = await campaignService.getAmazonTouchpoints(touchpoint.campaignId);

    touchpoints.forEach((t: IAmazonTouchpoint) => {
      dispatch({
        type: types.SET_AZ_TOUCHPOINT,
        payload: t
      });
    });

    dispatch({
      type: types.UPDATE_AZ_TOUCHPOINT_SUCCESS,
      //payload: updateRes
    });

  } catch (error) {
    console.log('from action', error)
    dispatch({
      type: types.UPDATE_AZ_TOUCHPOINT_FAILED,
      payload: JSON.stringify(error),
    });
  } 
}

export const deleteTouchpoint = (credentials: any) => async (dispatch: Dispatch<types.DispatchTypes & any>) =>{
  try {
    dispatch({type: types.DELETE_AZ_TOUCHPOINT_REQUEST});
    const { campaignId, accountId, touchpointId } = credentials;

    const service = new AmazonService(touchpointId);
    const res = await service.deleteTouchpoint();

    if(res) {
      dispatch({
        type: types.DELETE_AZ_TOUCHPOINT_SUCCESS,
        payload: touchpointId,
      });
      
      dispatch(getCampaign(accountId, campaignId));
    }

  } catch (error) {
    dispatch({
      type: types.DELETE_AZ_TOUCHPOINT_FAILED,
      payload: JSON.stringify(error)
    })
  }
}

export const getSuggestedKeywords = (touchpointId: string, bookIds: string) => async (dispatch: Dispatch<types.DispatchTypes>) => {
  try {
    dispatch({ type: types.GET_AZ_SUGGESTED_KEYWORDS_REQUEST });

    const response = await new AmazonService(touchpointId).getSuggestedKeywords(bookIds);

    dispatch({
      type: types.GET_AZ_SUGGESTED_KEYWORDS_SUCCESS,
      payload: response,
    });

  } catch (error) {
    console.log(error);
      dispatch({
        type: types.GET_AZ_SUGGESTED_KEYWORDS_FAILED,
        payload: error,
      });
  }
}

export const saveTouchpoint = (touchpoint: IAmazonTouchpoint, accountId: string) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: types.UPDATE_AZ_TOUCHPOINT_REQUEST });

    const { id } = touchpoint;
    //const service = new AmazonService(touchpoint.id);
    const formErrors : Array<TouchpointFormError> = [];
    
    //validate touchpoint completion
    if (!touchpoint.bookId) formErrors.push({ id, field: 'bookId', desc: "Select at least 1 book", step: 1});
    if (touchpoint.keywords.length === 0) formErrors.push({ id, field: 'keywords', desc: "Select at least 1 keyword", step: 3});
    
    //update touchpoint
    dispatch(updateTouchpoint(touchpoint, accountId));

    if(formErrors.length > 0) {
      //set errors if any and return,
      dispatch({
        type: types.SET_AMAZON_FORM_ERRORS,
        payload: formErrors,
      });

      dispatch({
        type: types.SET_AZ_TOUCHPOINT_STEP,
        payload: `panel${formErrors[0].step}`
      });

      return;
    }

    history.push("/app/shopping-cart?target="+ touchpoint.campaignId);
    //redirect if no error
  } catch (error) {
    dispatch({
      type: types.UPDATE_AZ_TOUCHPOINT_FAILED,
      payload: (error as any).message,
    }); 
  }
}

export const clearTouchpointFormErrors = () => ({ type: types.RESET_AMAZON_FORM_ERRORS });