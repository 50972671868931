import React, { useEffect } from "react";

const OneTrust = () => {

  useEffect(() => {
    //if (process.env.NODE_ENV === "development") return;

    const firstScript = document.createElement("script");
    firstScript.type = "text/javascript";
    firstScript.dataset.domainScript = "79e6c04f-1bc6-44ad-baf2-532629b047c8";
    firstScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";

    const secondScript = document.createElement("script");
    secondScript.type = "text/javascript";
    secondScript.innerText = "function OptanonWrapper() { }";

    const thirdScript = document.createElement("script");
    thirdScript.type = "text/javascript";
    thirdScript.src =
      "https://cdn.cookielaw.org/consent/79e6c04f-1bc6-44ad-baf2-532629b047c8/OtAutoBlock.js";

    document.head.appendChild(firstScript);
    document.head.appendChild(secondScript);
    document.head.appendChild(thirdScript);

    return () => {
      document.head.removeChild(firstScript);
      document.head.removeChild(secondScript);
      document.head.removeChild(thirdScript);
    };
  }, []);

  return <React.Fragment />;
};

export default OneTrust;
