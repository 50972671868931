import React from "react";
import createMuiTheme from "@material-ui/core/styles/createTheme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Open Sans", "sans-serif", "Lato"].join(","),
  },

  palette: {
    primary: {
      main: "#007BC7",
      dark: "#00539B",
      contrastText: "#FAFAFA",
    },

    success: {
      main: "#7DC048",
    },

    info: {
      main: "#169FDB",
    },

    error: {
      main: "#C00303",
    },
  },

  overrides: {
    MuiPickersModal: {
      dialogRoot: {
        zIndex: 10000000000,
      },
    },

    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(235, 235, 235, .6)",
        backdropFilter: "drop-shadow(4px 4px 10px blue)",
      },
    },

    MuiButton: {
      root: {
        fontFamily: ["Open Sans", "sans-serif", "Lato"].join(","),
        padding: 0,
        margin: 0,
      },
    },
    MuiTooltip: {
      popper: {
        zIndex: 1200,
      },
    },
    MuiTypography: {
      h1: {
        fontSize: "2.375rem",
        color: "#374655",
        fontFamily: "Open Sans, Lato, sans-serif",
        fontWeight: 700,
      },
      h2: {
        color: "#282828",
        fontSize: "1.5rem",
        fontFamily: "Open Sans, sans-serif",
        fontWeight: "bold",
      },
      h3: {
        color: "#282828",
        fontSize: "1.125rem",
        fontFamily: "Open Sans, sans-serif",
        fontWeight: "bold",
      },
      h4: {
        color: "#282828",
        fontSize: "1rem",
        fontFamily: "Open Sans, sans-serif",
        fontWeight: "bold",
      },
      h5: {
        color: "#282828",
        fontSize: "0.813rem",
        fontFamily: "Open Sans, sans-serif",
        fontWeight: "bold",
      },
      subtitle1: {
        color: "#686868",
        fontSize: "0.813rem",
        fontFamily: "Open Sans, sans-serif",
      },
    },
  },
});

export interface ThemeProps {
  children: any;
}

const Theme: React.FC<ThemeProps> = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default Theme;
