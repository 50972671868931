import { Reducer } from 'redux'
import * as types from './types';


const initialState: types.BookmarkState = {
  current: {
    id: '',
    name: '',
    accountId: '',
    path: '',
    type: 0,
  },
  items: [],
  status: {
    loading: false,
    error: ''
  }
}

const BookmarkReducer : Reducer<types.BookmarkState, types.DispatchTypes> = (
  state = initialState,
  action
) => {
  switch(action.type) {

    case types.SET_BOOKMARK: {
      return {
        ...state,
        current: {
          ...state.current,
          ...action.payload,
        }
      }
    }

    case types.GET_BOOKMARKS_BY_ACCOUNT_REQUEST: {
      return {
        ...state,
        status: {
          loading: true,
          error : ''
        }
      }
    }

    case types.GET_BOOKMARKS_BY_ACCOUNT_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        status: {
          loading: false,
          error : ''
        }
      }
    }

    case types.GET_BOOKMARKS_BY_ACCOUNT_FAILED: {
      return {
        ...state,
        status: {
          loading: false,
          error : action.payload,
        }
      }
    }

    case types.CREATE_BOOKMARK_REQUEST: {
      return {
        ...state,
        status: {
          loading: true,
          error : ''
        }
      }
    }

    case types.CREATE_BOOKMARK_SUCCESS: {
      return {
        ...state,
        items: [ ...state.items, action.payload],
        status: {
          loading: false,
          error: ''
        }
      }
    }

    case types.CREATE_BOOKMARK_FAILED: {
      return {
        ...state,
        status: {
          loading: false,
          error: action.payload
        }
      }
    }

    case types.DELETE_BOOKMARK_REQUEST: {
      return {
        ...state,
        status: {
          loading: true,
          error: '',
        }
      }
    }

    case types.DELETE_BOOKMARK_SUCCESS: {
      const items = state.items.filter(i => i.id !== action.payload);
      return {
        ...state,
        items,
        status: {
          loading: false,
          error: ''
        }
      }
    }

    case types.DELETE_BOOKMARK_FAILED: {
      return {
        ...state,
        status: {
          loading: false,
          error: action.payload
        }
      }
    }

    case types.UPDATE_BOOKMARK_REQUEST: {
      return {
        ...state,
        status: {
          loading: true,
          error: '',
        }
      }
    }

    case types.UPDATE_BOOKMARK_SUCCESS: {
      return {
        ...state,
        status: {
          loading: false,
          error: ''
        }
      }
    }

    case types.UPDATE_BOOKMARK_FAILED: {
      return {
        ...state,
        status: {
          loading: false,
          error: action.payload
        }
      }
    }

    default: {
      return state;
    }
  }
}

export default BookmarkReducer;