import { Dispatch } from 'redux';
import { isArray, compact } from 'lodash';
import { GoogleService } from '../../../services/GoogleService';
import { processTouchpoint } from '../../../utils/Touchpoint';
import { IGoogleTouchpoint, TouchpointFormError } from '../../../models';
import { processValidateResponse } from '../../../utils/GoogleTouchpoints';
import { history } from '../../../router';
import { getCampaign } from '../../Campaigns';
import * as types from './types';

export const setTouchpoints = (touchpoints: any) => {
  return {
    type: types.SET_PS_TOUCHPOINTS,
    payload: touchpoints
  }
}

export const setTouchpoint = (touchpoint: any) => {
  return {
    type: types.SET_PS_TOUCHPOINT,
    payload: touchpoint
  }
}

export const setTouchpointStep = (step: number) => {
  return {
    type: types.SET_PS_TOUCHPOINT_STEP,
    payload: `panel${step}`
  }
}

export const TIMEOUT_ERROR = "Error retrieving keyword suggestions. Try again later"

export const getTouchpoint = (touchpointId: string) => async (dispatch: Dispatch<types.DispatchTypes>) => {
  try {
    dispatch({ type: types.GET_PS_TOUCHPOINT_REQUEST});
        
    const service = new GoogleService(touchpointId);
    const touchpoint = await service.getTouchpoint();
    processTouchpoint(touchpoint);

    dispatch({
      type: types.GET_PS_TOUCHPOINT_SUCCESS,
      payload: touchpoint,
    });
    
  } catch (error) {
    dispatch({
      type: types.GET_PS_TOUCHPOINT_FAILED,
      payload: JSON.stringify(error)
    })
  }
}

export const updateTouchpoint = (touchpoint:any, accountId: string) => async (dispatch: Dispatch<types.DispatchTypes>) => {
  try {
    dispatch({ type: types.UPDATE_PS_TOUCHPOINT_REQUEST });

      
    const service = new GoogleService(touchpoint.id);
    const res = await service.updateTouchpoint(touchpoint);
    
    if (res === 200) {
      dispatch({
        type: types.UPDATE_PS_TOUCHPOINT_SUCCESS,
        payload: res
      });
      
      const newTouchpoint = await service.getTouchpoint();

      if (newTouchpoint) {
        dispatch({
          type: types.SET_PS_TOUCHPOINT,
          payload: newTouchpoint
        });
      }
    } else {
      dispatch({
        type: types.UPDATE_PS_TOUCHPOINT_FAILED,
        payload: res
      })
    }

  } catch (error) {
    dispatch({
      type: types.UPDATE_PS_TOUCHPOINT_FAILED,
      payload: JSON.stringify(error),
    });
  }
}

export const deleteTouchpoint = (credentials: any) => async (dispatch: Dispatch<types.DispatchTypes>) =>{
  try {
    dispatch({type: types.DELETE_PS_TOUCHPOINT_REQUEST});
    const { campaignId, accountId, touchpointId } = credentials;

    const service = new GoogleService(touchpointId);
    const res = await service.deleteTouchpoint();

    if(res) {
      dispatch({
        type: types.DELETE_PS_TOUCHPOINT_SUCCESS,
        payload: touchpointId,
      });
      
      getCampaign(accountId, campaignId);
    }

  } catch (error) {
    dispatch({
      type: types.DELETE_PS_TOUCHPOINT_FAILED,
      payload: JSON.stringify(error)
    })
  }
}

export const getAudienceOptions = (touchpointId: string) => async (dispatch: Dispatch<types.DispatchTypes>) => {
  try {
    dispatch({
      type: types.GET_PS_AUDIENCE_OPTIONS_REQUEST
    });

    const res = await new GoogleService(touchpointId).getAudienceOptions();

    if(res) {
      const products = res.sort((a: any, b: any) => a.cpa - b.cpa); 
      dispatch({
        type: types.GET_PS_AUDIENCE_OPTIONS_SUCCESS,
        payload: products
      })
      //TBD on Google Pricing!
      dispatch({
        type: types.SET_PS_PRICES,
        payload: products[0].productPrices
      });

    } else {
      let error = new Error(res);
      throw error;
    }
 
  } catch (error) {
    dispatch({
      type: types.GET_PS_AUDIENCE_OPTIONS_FAILED,
      payload: JSON.stringify(error)
    })
  }
}

export const uploadLandscape = (touchpoint: any, files: any[]) => async (dispatch: Dispatch<types.DispatchTypes>) => {
  try {
    dispatch({ type: types.UPLOAD_LANDSCAPE_IMAGES_REQUEST });

    const res = await new GoogleService(touchpoint.id).uploadLandscapeImages(files);

    if (res.message) {
      dispatch({
        type: types.UPLOAD_LANDSCAPE_IMAGES_FAILED,
        payload: res.message
      })
    }

    if (isArray(res)) {
      const baseUrl = process.env.REACT_APP_IMAGE_URL ?? '';
      
      const images = res.map((img) => {
        const timestamp = '?t=' + new Date().getTime(); //create timestamp
        return { 
          image: baseUrl + '/googleimages/' + img.file + timestamp, //refresh preview on any change
          imageType: 1, 
          touchpointId: touchpoint.id 
        }
      });

      const otherImages = touchpoint.images.filter( //remove all images from the same type 
        (img: any) => img.imageType !== 1
      );

      dispatch({
        type: types.UPLOAD_LANDSCAPE_IMAGES_SUCCESS,
      });
      dispatch({
        type: types.SET_PS_TOUCHPOINT,
        payload: {
          ...touchpoint,
          images: [...otherImages, ...images] //rewrite images
        }
      });
    }

  } catch (error) {
    dispatch({
      type: types.UPLOAD_LANDSCAPE_IMAGES_FAILED,
      payload: JSON.stringify(error)
    })
  }
}

export const uploadSquare = (touchpoint: any, files: any[]) => async (dispatch: Dispatch<types.DispatchTypes>) => {
  try {
    dispatch({ type: types.UPLOAD_SQUARE_IMAGES_REQUEST });

    const res = await new GoogleService(touchpoint.id).uploadSquareImages(files);
    
    if (res.message) {
      dispatch({
        type: types.UPLOAD_SQUARE_IMAGES_FAILED,
        payload: res.message
      })
    }
    
    if (isArray(res)) {
      const baseUrl = process.env.REACT_APP_IMAGE_URL ?? '';
      
      const images = res.map((img) => {
        const timestamp = '?t=' + new Date().getTime(); //create timestamp
        return { 
          image: baseUrl + '/googleimages/' + img.file + timestamp, //refresh preview on any change
          imageType: 0, 
          touchpointId: touchpoint.id 
        }
      });

      const otherImages = touchpoint.images.filter( //remove all images from the same type 
        (img: any) => img.imageType !== 0
      );

      dispatch({
        type: types.UPLOAD_LANDSCAPE_IMAGES_SUCCESS,
      });
      dispatch({
        type: types.SET_PS_TOUCHPOINT,
        payload: {
          ...touchpoint,
          images: [...otherImages, ...images]
        }
      });
    }


  } catch (error) {
    dispatch({
      type: types.UPLOAD_SQUARE_IMAGES_FAILED,
      payload: JSON.stringify(error)
    })
  }
}

export type LogoFilesType = { landscape: any[], square: any[] }

export const uploadLogo = (touchpoint: any, logos: LogoFilesType ) => async (dispatch: Dispatch<types.DispatchTypes>) => {
  try {
    dispatch({ type: types.UPLOAD_LOGO_IMAGES_REQUEST });

    const landscapeRes = logos.landscape.length > 0 && await new GoogleService(touchpoint.id).uploadLandscapeLogoImages(logos.landscape);
    const squareRes = logos.square.length > 0 && await new GoogleService(touchpoint.id).uploadSquareLogoImages(logos.square);
    
    if (landscapeRes.message || squareRes.message ) {
      dispatch({
        type: types.UPLOAD_LOGO_IMAGES_FAILED,
        payload: landscapeRes.message ?? squareRes.message
      });
    }

    
    if (isArray(landscapeRes) && isArray(squareRes)) {
      const baseUrl = process.env.REACT_APP_IMAGE_URL ?? '';

      const landscapeImages = landscapeRes.map((img: any) => {
        const timestamp = '?t=' + new Date().getTime(); //create timestamp

        return { 
          image: baseUrl + '/googleimages/' + img.file + timestamp, //refresh preview on any change
          imageType: 2, 
          touchpointId: touchpoint.id 
        }
      });

      const squareImages = squareRes.map((img: any) => {
        const timestamp = '?t=' + new Date().getTime(); //create timestamp

        return { 
          image: baseUrl + '/googleimages/' + img.file + timestamp, //refresh preview on any change
          imageType: 3, 
          touchpointId: touchpoint.id 
        }
      });

      const otherImages = touchpoint.images.filtar((img: any) => img.imageType < 2 )

      dispatch({
        type: types.UPLOAD_LANDSCAPE_IMAGES_SUCCESS,
      });
      dispatch({
        type: types.SET_PS_TOUCHPOINT,
        payload: {
          ...touchpoint,
          images: [...otherImages, ...landscapeImages, ...squareImages]
        }
      });
    }


  } catch (error) {
    dispatch({
      type: types.UPLOAD_LOGO_IMAGES_FAILED,
      payload: JSON.stringify(error)
    })
  }
}

export const getBisacKeywords = (touchpointId: string) => async (dispatch: Dispatch<types.DispatchTypes>) => {
  try {
    dispatch({ type: types.GET_FINAL_KEYWORDS_REQUEST });

    const data = await new GoogleService(touchpointId).getBisacKeywords();

    dispatch({
      type: types.GET_FINAL_KEYWORDS_SUCCESS,
      payload: data,
    });

  } catch (error) {
    const timeoutCheck = new RegExp("Execution Timeout Expired", "i");

    dispatch({
      type: types.GET_FINAL_KEYWORDS_FAILED,
      payload: timeoutCheck.test(error as string) ? TIMEOUT_ERROR: error as string
    })
  }
}


export const getCustomKeywords = (touchpointId: string, query: any) => async (dispatch: Dispatch<types.DispatchTypes>) => {
  try {
    dispatch({ type: types.GET_CUSTOM_KEYWORDS_REQUEST });

    const data = await new GoogleService(touchpointId).getCustomKeywords(query);

    dispatch({
      type: types.GET_CUSTOM_KEYWORDS_SUCCESS,
      payload: data,
    });

  } catch (error) {
    const timeoutCheck = new RegExp("Execution Timeout Expired", "i");
    
    dispatch({
      type: types.GET_CUSTOM_KEYWORDS_FAILED,
      payload: timeoutCheck.test(error as string) ? TIMEOUT_ERROR : error as string
    })
  }
}

export const clearCustomKeywords = () => {
  return {
    type: types.CLEAR_CUSTOM_KEYWORDS
  }
}

export  const resetValidationItems = () => ({ type: types.RESET_VALIDATION_ITEMS })

export const saveSearchTouchpoint = (touchpoint: IGoogleTouchpoint, accountId: string, step?: number) => async (dispatch: Dispatch<types.DispatchTypes>) => {
  let update: any = undefined;
  try {
    dispatch({ type: types.UPDATE_PS_TOUCHPOINT_REQUEST });

    const { id } = touchpoint;
    const service = new GoogleService(touchpoint.id);
    const formErrors : Array<TouchpointFormError> = [];
    //validate touchpoint completion
    const headlines = compact(touchpoint.headlines);
    const descriptions = compact(touchpoint.descriptions);

    if (touchpoint.keywords.concat(touchpoint.customKeywords).length === 0) {
      formErrors.push({ id, field: 'keywords', desc: 'Select at least one keyword.', step: 1});
    }
    if (headlines.length < 3) formErrors.push({ id, field: 'headlines', desc: `Enter at least 3 headlines`, step: 2 });
    if (descriptions.length < 2) formErrors.push({ id, field: 'descriptions', desc: `Enter at least 2 descriptions.`, step: 2 });
    if (!touchpoint.link) formErrors.push({ id, field: 'link', desc: "Field is required", step: 2 });
    
    //update touchpoint
    update = await service.updateTouchpoint(touchpoint);
    if(formErrors.length > 0) {
      //set errors if any and return,
      dispatch({
        type: types.SET_PS_FORM_ERRORS,
        payload: formErrors,
      });

      dispatch({
        type: types.SET_PS_TOUCHPOINT_STEP,
        payload: `panel${formErrors[0].step}`
      });

      return;
    }
    
    const validate = await service.validateTouchpoint();
    if(validate !== 200) {
      const validationItems = processValidateResponse(validate, touchpoint);

      if(validationItems) {
        dispatch({
          type: types.SET_VALIDATION_ITEMS,
          payload: validationItems
        });
      }

      console.log("validate response :", validationItems, typeof validationItems);
      return;
    }

    history.push("/app/shopping-cart?target="+ touchpoint.campaignId);

    //redirect if no error
  } catch (error) {
    dispatch({
      type: types.UPDATE_PS_TOUCHPOINT_FAILED,
      payload: (error as any).message,
    }); 
  } finally {
    dispatch({
      type: types.UPDATE_PS_TOUCHPOINT_SUCCESS,
      payload: update
    });
  }
}

export const saveDisplayTouchpoint = (touchpoint: IGoogleTouchpoint, accountId: string, step?: number) => async (dispatch: Dispatch<types.DispatchTypes>) => {
  let update : any = undefined;
  try {
    dispatch({ type: types.UPDATE_PS_TOUCHPOINT_REQUEST });

    const { id } = touchpoint;
    const service = new GoogleService(touchpoint.id);
    const formErrors : Array<TouchpointFormError> = [];
    
    //validate touchpoint completion
    const headlines = compact(touchpoint.headlines);
    const descriptions = compact(touchpoint.descriptions);
    const hasLandscape = touchpoint.images.findIndex(i => i.imageType === 1);
    const hasSquare = touchpoint.images.findIndex(i => i.imageType === 0);

    if (hasLandscape < 0) formErrors.push({ id, field: 'landscape-images', desc: 'Add at least 1 Landscape image', step: 2})
    if (hasSquare < 0) formErrors.push({ id, field: 'square-images', desc: "Add at least 1 Square image", step: 2 });
    if (headlines.length === 0) formErrors.push({ id, field: 'headlines', desc: "Enter at least 1 headline", step: 3 });
    if (descriptions.length === 0) formErrors.push({ id, field: 'descriptions',  desc: "Enter at least 1 description", step: 3});
    if (!touchpoint.longHeadline) formErrors.push({ id, field: 'longHeadline',  desc: "Long Headline is required", step: 3});
    if (!touchpoint.businessName) formErrors.push({ id, field: 'businessName',  desc: "Business Name is required", step: 3});
    if (!touchpoint.link) formErrors.push({ id, field: 'link', desc: "Field is required", step: 3 })
    
    //update touchpoint
    update = await service.updateTouchpoint(touchpoint);
    if(formErrors.length > 0) {
      //set errors if any and return,

      dispatch({
        type: types.SET_PS_FORM_ERRORS,
        payload: formErrors,
      });

      dispatch({
        type: types.SET_PS_TOUCHPOINT_STEP,
        payload: `panel${formErrors[0].step}`
      });

      return;
    }

    const validate = await service.validateTouchpoint();
    if(validate !== 200) {
      const validationItems = processValidateResponse(validate, touchpoint);

      if(validationItems) {
        dispatch({
          type: types.SET_VALIDATION_ITEMS,
          payload: validationItems
        });
      }

      console.log("validate response :", validationItems, typeof validationItems);
      return;
    }
    history.push("/app/shopping-cart?target="+ touchpoint.campaignId);

    //redirect if no error
  } catch (error) {
    dispatch({
      type: types.UPDATE_PS_TOUCHPOINT_FAILED,
      payload: (error as any).message,
    }); 
  } finally {
    dispatch({
      type: types.UPDATE_PS_TOUCHPOINT_SUCCESS,
      payload: update
    });
  }
}

export const clearTouchpointFormErrors = () => ({ type: types.RESET_PS_FORM_ERRORS });

