import { NotificationModel } from '../../models';

export type NotificationsState = {
  data: NotificationModel[],
  total: number,
  new: NotificationModel[],
  campaign: NotificationModel[],
  status: {
    loading: boolean,
    secondaryLoad: boolean,
    error: any,
  }
}

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILED = 'GET_NOTIFICATIONS_FAILED';

type getNotificationsRequest = {
  type: typeof GET_NOTIFICATIONS_REQUEST
}

type getNotificationsSuccess = {
  type: typeof GET_NOTIFICATIONS_SUCCESS,
  payload:{
    notifications: NotificationModel[]
    total: number
  }
}

type getNotificationsFailed = {
  type: typeof GET_NOTIFICATIONS_FAILED,
  payload: string
}

export const GET_NEW_NOTIFICATIONS_REQUEST = 'GET_NEW_NOTIFICATIONS_REQUEST';
export const GET_NEW_NOTIFICATIONS_SUCCESS = 'GET_NEW_NOTIFICATIONS_SUCCESS';
export const GET_NEW_NOTIFICATIONS_FAILED = 'GET_NEW_NOTIFICATIONS_FAILED';

type getNewNotificationsRequest = {
  type: typeof GET_NEW_NOTIFICATIONS_REQUEST
}

type getNewNotificationsSuccess = {
  type: typeof GET_NEW_NOTIFICATIONS_SUCCESS,
  payload: NotificationModel[]
}

type getNewNotificationsFailed = {
  type: typeof GET_NEW_NOTIFICATIONS_FAILED,
  payload: string
}

export const SET_READ_NOTIFICATIONS_REQUEST = 'SET_READ_NOTIFICATIONS_REQUEST';
export const SET_READ_NOTIFICATIONS_SUCCESS = 'SET_READ_NOTIFICATIONS_SUCCESS';
export const SET_READ_NOTIFICATIONS_FAILED = 'SET_READ_NOTIFICATIONS_FAILED';

type setReadNotificationsRequest = {
  type: typeof SET_READ_NOTIFICATIONS_REQUEST
}

type setReadNotificationsSuccess = {
  type: typeof SET_READ_NOTIFICATIONS_SUCCESS,
}

type setReadNotificationsFailed = {
  type: typeof SET_READ_NOTIFICATIONS_FAILED,
  payload: string
}

export const DISMISS_NOTIFICATION_REQUEST = 'DISMISS_NOTIFICATION_REQUEST';
export const DISMISS_NOTIFICATION_SUCCESS = 'DISMISS_NOTIFICATION_SUCCESS';
export const DISMISS_NOTIFICATION_FAILED= 'DISMISS_NOTIFICATION_FAILED';

type dismissNotificationRequest = {
  type: typeof DISMISS_NOTIFICATION_REQUEST
}

type dismissNotificationSuccess = {
  type: typeof DISMISS_NOTIFICATION_SUCCESS,
  payload: string,
}

type dismissNotificationFailed = {
  type: typeof DISMISS_NOTIFICATION_FAILED,
  payload: string,
}

export const DISMISS_BULK_NOTIFICATIONS_REQUEST = 'DISMISS_BULK_NOTIFICATIONS_REQUEST';
export const DISMISS_BULK_NOTIFICATIONS_SUCCESS = 'DISMISS_BULK_NOTIFICATIONS_SUCCESS';
export const DISMISS_BULK_NOTIFICATIONS_FAILED = 'DISMISS_BULK_NOTIFICATIONS_FAILED';

type dismissBulkNotificationsRequest = {
  type: typeof DISMISS_BULK_NOTIFICATIONS_REQUEST,
} 

type dismissBulkNotificationsSuccess = {
  type: typeof DISMISS_BULK_NOTIFICATIONS_SUCCESS,
  payload: string[]
} 

type dismissBulkNotificationsFailed = {
  type: typeof DISMISS_BULK_NOTIFICATIONS_FAILED,
  payload: any,
} 

export const SET_CAMPAIGN_NOTIFICATIONS = 'SET_CAMPAIGN_NOTIFICATIONS';

type setCampaignNotifications = {
  type: typeof SET_CAMPAIGN_NOTIFICATIONS,
  payload: NotificationModel[]
}

export type DispatchTypes = 
  getNotificationsRequest | getNotificationsSuccess | getNotificationsFailed |
  getNewNotificationsRequest | getNewNotificationsSuccess | getNewNotificationsFailed |
  setReadNotificationsRequest | setReadNotificationsSuccess | setReadNotificationsFailed |
  dismissNotificationRequest | dismissNotificationSuccess | dismissNotificationFailed |
  dismissBulkNotificationsRequest | dismissBulkNotificationsSuccess | dismissBulkNotificationsFailed |
  setCampaignNotifications
;