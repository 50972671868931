import { InviteModel } from '../../models'

export type UserInviteState = {
  invites: Array<InviteModel>
  status: {
    loading: boolean
    error: string
  }
}

export const RESEND_INVITE_REQUEST = 'RESEND_INVITE_REQUEST'
export const RESEND_INVITE_SUCCESS = 'RESEND_INVITE_SUCCESS'
export const RESEND_INVITE_FAILED = 'RESEND_INVITE_FAILED'

type resendInviteRequest = {
  type: typeof RESEND_INVITE_REQUEST
}

type resendInviteSuccess = {
  type: typeof RESEND_INVITE_SUCCESS
}

type resendInviteFailed = {
  type: typeof RESEND_INVITE_FAILED
  payload: string
}

export const GET_INVITATIONS_BY_USER_ID_REQUEST =
  'GET_INVITATIONS_BY_USER_ID_REQUEST'
export const GET_INVITATIONS_BY_USER_ID_SUCCESS =
  'GET_INVITATIONS_BY_USER_ID_SUCCESS'
export const GET_INVITATIONS_BY_USER_ID_FAILED =
  'GET_INVITATIONS_BY_USER_ID_FAILED'

type getInvitationsByUserIDRequest = {
  type: typeof GET_INVITATIONS_BY_USER_ID_REQUEST
}

type getInvitationsByUserIDSuccess = {
  type: typeof GET_INVITATIONS_BY_USER_ID_SUCCESS
  payload: []
}

type getInvitationsByUserIDFailed = {
  type: typeof GET_INVITATIONS_BY_USER_ID_FAILED
  payload: string
}

export const CREATE_INVITE_REQUEST = 'CREATE_INVITE_REQUEST'
export const CREATE_INVITE_SUCCESS = 'CREATE_INVITE_SUCCESS'
export const CREATE_INVITE_FAILED = 'CREATE_INVITE_FAILED'

type createInviteRequest = {
  type: typeof CREATE_INVITE_REQUEST
}

type createInviteSuccess = {
  type: typeof CREATE_INVITE_SUCCESS
}

type createInviteFailed = {
  type: typeof CREATE_INVITE_FAILED
  payload: string
}

export const REQUEST_INVITE_REQUEST = 'REQUEST_INVITE_REQUEST'
export const REQUEST_INVITE_SUCCESS = 'REQUEST_INVITE_SUCCESS'
export const REQUEST_INVITE_FAILED = 'REQUEST_INVITE_FAILED'

type requestInviteRequest = {
  type: typeof REQUEST_INVITE_REQUEST
}

type requestInviteSuccess = {
  type: typeof REQUEST_INVITE_SUCCESS
}

type requestInviteFailed = {
  type: typeof REQUEST_INVITE_FAILED
  payload: string
}

export const RESET_ERRORS = 'RESET_ERRORS'

type resetErrors = {
  type: typeof RESET_ERRORS
}

export type DispatchTypes =
  | getInvitationsByUserIDRequest
  | getInvitationsByUserIDSuccess
  | getInvitationsByUserIDFailed
  | requestInviteRequest
  | requestInviteSuccess
  | requestInviteFailed
  | createInviteRequest
  | createInviteSuccess
  | createInviteFailed
  | resendInviteRequest
  | resendInviteSuccess
  | resendInviteFailed
  | resetErrors
