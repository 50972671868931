import * as types from './types'
import { Dispatch } from 'redux'
import { CampaignService } from '../../../services/CampaignService'
import { FacebookService } from '../../../services/FacebookService'
import { RecommendedAudienceService } from '../../../services/RecommendedAudienceService'
import { formValueToBit, bitToFormValue } from '../../../utils/Enum'
import { processCampaignTouchpoints } from '../../../utils/Touchpoint'
import {
	CampaignModel,
	Goals,
	Promotions,
	RecomendedAudienceModel,
	ServiceModel,
} from '../../../models'
import { booksFormater } from '../../../utils'
import { history } from '../../../router'
import { FacebookTouchpoint, GoogleTouchpoint, AmazonTouchpoint, EmailBlastTouchpoint } from '../../Touchpoints'
import { GoogleService } from '../../../services/GoogleService'
import { AmazonService } from '../../../services/AmazonService'
import { TOGGLE_CAMPAIGN_FAVORITE, DELETE_CAMPAIGN_FROM_FILTER } from '../Filter/types';
import backupCover from '../../../assets/backupCover.png';
import { EmailBlastService } from '../../../services/EmailBlastService'
//import { FilterControl } from '../Filter/types'

//-------------START Form Actions(Campaign and Touchpoint Common)
const ANY_TITLE = {
	id: process.env.REACT_APP_ANY_TITLE_ID,
	title: 'Any Title',
	author: 'Any Book',
	bookCoverURL: backupCover,
	isbn: '00000000000',
	type: 'none',
}

export const setFormStep = (title: string, progress: number) => {
	return {
		type: types.SET_FORM_STEP,
		payload: { title, progress },
	}
}

export const clearServices = () => ({
	type: types.CLEAR_CAMPAIGN_SERVICES,
});

export const toggleFavorite = (campaign: CampaignModel) => async (dispatch: Dispatch<any>) => {
	try {
		const service = new CampaignService(campaign.accountId);
		const res = await service.toggleFavorite(campaign.id);

		if (res) {
			dispatch({
				type: types.MARK_FAVORITE_CAMPAIGN,
				payload: campaign,
			});

			dispatch({
				type: TOGGLE_CAMPAIGN_FAVORITE,
				payload: campaign.id
			})
		}

		throw new Error("Mark as favorite Failed")

	} catch (error) {
		console.log(error);
	}
}

export const setAvailablePrices =
	(prices: any[]) => (dispatch: Dispatch<types.DispatchTypes>) => {
		return dispatch({
			type: types.SET_AVAILABLE_PRICES,
			payload: prices,
		})
	}

export const resetForm = () => ({ type: types.RESET_FORM });

export const resetBooks = () => ({ type: types.RESET_BOOKS })

export const getAccountBooks =
	(accountId: string, search: string = '') =>
		async (dispatch: Dispatch<types.DispatchTypes>) => {
			try {
				//create Request
				dispatch({
					type: types.GET_USER_BOOKS_REQUEST,
				})

				const service = new CampaignService(accountId)
				const res = await service.getBooks(search);

				if (res.books.length === 0) {
					throw new Error(" We couldn't find any title matching that description");
				}

				const formatted = booksFormater(res.books)
				//save response
				dispatch({
					type: types.GET_USER_BOOKS_SUCCESS,
					payload: {
						books: formatted,
						totalBooks: res.totalBooks,
					},
				})
			} catch (error) {
				console.log(error)
				dispatch({
					type: types.GET_USER_BOOKS_FAILED,
					payload: (error as any).message,
				})
			}
		}

//---------END
//---------START Campaign Actions

export const setCampaign = (campaign: CampaignModel) =>
	(dispatch: Dispatch<types.DispatchTypes>) => {
		const marketingGoals = bitToFormValue(Goals, campaign.marketingGoals)
		const promoting = bitToFormValue(Promotions, campaign.promoting)
		const books = booksFormater(campaign.books, 648)

		return dispatch({
			type: types.GET_CAMPAIGN_SUCCESS,
			payload: {
				...campaign,
				books,
				marketingGoals,
				promoting,
			},
		})
	}



export const getActiveCampaigns = (accountId: string) =>
	async (dispatch: Dispatch<types.DispatchTypes>) => {
		try {
			dispatch({
				type: types.GET_ACTIVE_CAMPAIGNS_REQUEST,
			})

			const service = new CampaignService(accountId)
			const res = await service.getActiveCampaigns()

			dispatch({
				type: types.GET_ACTIVE_CAMPAIGNS_SUCCESS,
				payload: res,
			})
		} catch (error) {
			dispatch({
				type: types.GET_ACTIVE_CAMPAIGNS_FAILED,
				payload: JSON.stringify(error),
			})
		}
	}

export const updateCampaign = (accountId: string, campaign: any) =>
	async (dispatch: Dispatch<types.DispatchTypes>) => {
		try {
			dispatch({
				type: types.UPDATE_CAMPAIGN_REQUEST,
			})

			const service = new CampaignService(accountId)
			const res = await service.updateCampaign({
				...campaign,
				marketingGoals: formValueToBit(Goals, campaign.marketingGoals),
				promoting: formValueToBit(Promotions, campaign.promoting),
				status: 0,
				//books: campaign.books.map((book: BookModel) => book.id),
			})

			if (res) {
				dispatch({
					type: types.UPDATE_CAMPAIGN_SUCCESS,
				})

				const newCampaign = await service.getCampaignById(campaign.id)

				if (newCampaign) {
					const marketingGoals = bitToFormValue(
						Goals,
						newCampaign.marketingGoals
					)
					const promoting = bitToFormValue(Promotions, newCampaign.promoting)
					const books = booksFormater(newCampaign.books, 648)

					dispatch({
						type: types.GET_CAMPAIGN_SUCCESS,
						payload: {
							...campaign,
							books,
							marketingGoals,
							promoting,
						},
					})
				}
			}
		} catch (error) {
			dispatch({
				type: types.UPDATE_CAMPAIGN_FAILED,
				payload: JSON.stringify(error),
			})
		}
	}

export const getCampaignById = (accountId: string, campaignId: string) =>
	async (dispatch: Dispatch<types.DispatchTypes>) => {
		try {
			dispatch({ type: types.GET_CAMPAIGN_BY_ID_REQUEST })

			const service = new CampaignService(accountId)
			const res = await service.getCampaignById(campaignId)

			dispatch({
				type: types.GET_CAMPAIGN_BY_ID_SUCCESS,
				payload: res,
			})

		} catch (error) {
			dispatch({
				type: types.GET_CAMPAIGN_BY_ID_FAILED,
				payload: JSON.stringify(error),
			})
		}
	}

export const createOrUpdateCampaignBasics =
	(form: any, accountId: string, campaignId: string, campaignStatus: number) =>
		async (dispatch: Dispatch<types.DispatchTypes>) => {
			try {
				//create request
				dispatch({ type: types.CREATE_CAMPAIGN_BASICS_REQUEST });
				const { name, books, guided } = form;
				let redirect = guided ? "/app/create-campaign/goals" : '/app/create-campaign/audiences';


				if (books.length === 0) {
					books.push(ANY_TITLE);
				}

				dispatch({
					type: types.GUIDED_CAMPAIGN_STEP_ONE,
					payload: {
						name,
						books,
						accountId,
						guided,
					},
				});

				const service = new CampaignService(accountId);

				if (campaignId === '') {
					const res = await service.createCampaignBasic(name, books);
					redirect = redirect + "?target=" + res.id;
					dispatch({
						type: types.CREATE_CAMPAIGN_BASICS_SUCCESS,
						payload: res.id,
					});
				} else {
					const res = await service.updateCampaignBasic(campaignId, name, books);
					redirect = redirect + "?target=" + campaignId;

					dispatch({
						type: types.CREATE_CAMPAIGN_BASICS_SUCCESS,
						payload: res.id,
					})
				}
				console.log(campaignStatus);
				if (campaignStatus !== 5) {
					redirect = "/app/summary?campaignId=" + campaignId;
				}

				history.push(redirect);

			} catch (error) {
				console.log(error)
				dispatch({
					type: types.CREATE_CAMPAIGN_BASICS_FAILED,
					payload: JSON.stringify(error),
				})
			}
		}

export const updateCampaignGoals =
	(form: any, accountId: string) =>
		async (dispatch: Dispatch<types.DispatchTypes>) => {
			try {
				dispatch({
					type: types.UPDATE_CAMPAIGN_GOALS_REQUEST,
				})

				dispatch({
					type: types.GUIDED_CAMPAIGN_STEP_TWO,
					payload: form,
				})

				const { formGoals, formPromoting, id, startDate, budget } = form

				// serialize flag
				const marketingGoals = formValueToBit(Goals, formGoals)
				const promoting = formValueToBit(Promotions, formPromoting)

				const service = new CampaignService(accountId)
				await service.updateGoals({
					id,
					marketingGoals,
					promoting,
					startDate,
					budget,
				})

				dispatch({
					type: types.UPDATE_CAMPAIGN_GOALS_SUCCESS,
				});

				history.push("/app/create-campaign/audiences?target=" + id);

			} catch (error) {
				console.log(error)
				dispatch({
					type: types.UPDATE_CAMPAIGN_GOALS_FAILED,
					payload: JSON.stringify(error),
				})
			}
		}

export const getRecomendedAudiences =
	(accountId: string, campaignId: string, guided: boolean) =>
		async (dispatch: Dispatch<types.DispatchTypes>) => {
			try {
				dispatch({
					type: types.GET_RECOMENDED_AUDIENCES_REQUEST,
				});
				let audiences: any;
				const service = new RecommendedAudienceService();

				if (guided) {
					audiences = await service.getRecomendedAudiences(campaignId);
				} else {
					audiences = await service.getAllRecomendedAudiences(campaignId);
				}

				dispatch({
					type: types.GET_RECOMENDED_AUDIENCES_SUCCESS,
					payload: audiences,
				});

			} catch (error) {
				dispatch({
					type: types.GET_RECOMENDED_AUDIENCES_FAILED,
					payload: JSON.stringify(error),
				});
			}
		}

export const getAllRecommendedAudiences =
	(accountId: string, campaignId: string) =>
		async (dispatch: Dispatch<types.DispatchTypes>) => {
			try {
				dispatch({ type: types.GET_ALL_RECOMENDED_AUDIENCES_REQUEST });
				const service = new RecommendedAudienceService();
				const audiences = await service.getAllRecomendedAudiences(campaignId);

				dispatch({
					type: types.GET_ALL_RECOMENDED_AUDIENCES_SUCCESS,
					payload: audiences
				})

			} catch (error) {
				dispatch({
					type: types.GET_ALL_RECOMENDED_AUDIENCES_FAILED,
					payload: error as string
				})
			}
		}

export const updateRecomendedAudiences =
	(form: any) => async (dispatch: Dispatch<types.DispatchTypes>) => {
		try {
			dispatch({
				type: types.UPDATE_RECOMENDED_AUDIENCES_REQUEST,
			})

			const { audiences, accountId, campaignId } = form;
			const service = new CampaignService(accountId);

			const audiencesId: Array<string> = audiences.map(
				(audience: RecomendedAudienceModel) => audience.id
			);

			const res = await service.updateRecomendedAudiences(
				audiencesId,
				campaignId
			);

			dispatch({
				type: types.GUIDED_CAMPAIGN_STEP_THREE,
				payload: audiences,
			});

			dispatch({
				type: types.UPDATE_RECOMENDED_AUDIENCES_SUCCESS,
				payload: res,
			});

			history.push("/app/create-campaign/services?target=" + campaignId);

		} catch (error) {
			dispatch({
				type: types.UPDATE_RECOMENDED_AUDIENCES_FAILED,
				payload: JSON.stringify(error),
			})
		}
	}

export const getMarketingServices =
	(accountId: string, campaignId: string) =>
		async (dispatch: Dispatch<types.DispatchTypes>) => {
			try {
				dispatch({
					type: types.GET_MARKETING_SERVICES_REQUEST,
				})

				const service = new CampaignService(accountId)
				const services = await service.getMarketingServices(campaignId)

				dispatch({
					type: types.GET_MARKETING_SERVICES_SUCCESS,
					payload: services,
				})
			} catch (error) {
				dispatch({
					type: types.GET_RECOMENDED_AUDIENCES_FAILED,
					payload: JSON.stringify(error),
				})
			}
		}

export const addMarketingServices =
	(form: any) => async (dispatch: Dispatch<types.DispatchTypes>) => {
		try {
			dispatch({
				type: types.ADD_MARKETING_SERVICES_REQUEST,
			})

			const { services, campaignId, accountId } = form
			const service = new CampaignService(accountId)
			const servicesId = services.map((service: ServiceModel) => service.id)
			const res = await service.addMarketingServices(servicesId, campaignId)

			if (res === 200) {
				dispatch({
					type: types.ADD_MARKETING_SERVICES_SUCCESS,
					payload: services,
				})

				const campaign = await service.getCampaignById(campaignId)

				const marketingGoals = bitToFormValue(Goals, campaign.marketingGoals)
				const promoting = bitToFormValue(Promotions, campaign.promoting)
				const books = booksFormater(campaign.books, 648)

				dispatch({
					type: types.GET_CAMPAIGN_SUCCESS,
					payload: {
						...campaign,
						books,
						marketingGoals,
						promoting,
					},
				})
			}
		} catch (error) {
			dispatch({
				type: types.ADD_MARKETING_SERVICES_FAILED,
				payload: JSON.stringify(error),
			})
		}
	}

export const getCampaign =
	(accountId: string, campaignId: string, callback?: (c: CampaignModel) => void) =>
		async (dispatch: Dispatch<types.DispatchTypes | any>) => {
			if (campaignId === "new") {
				return dispatch({
					type: types.RESET_FORM
				})
			}
			try {
				dispatch({ type: types.GET_CAMPAIGN_REQUEST })

				const service = new CampaignService(accountId)
				const res = await service.getCampaignById(campaignId)

				if (res.marketingGoals === 0 && res.promoting === 0) {
					dispatch({
						type: types.SET_BYO_FORM,
					})
				} else {
					dispatch({
						type: types.SET_GUIDED_FORM,
					})
				}

				const marketingGoals = bitToFormValue(Goals, res.marketingGoals)
				const promoting = bitToFormValue(Promotions, res.promoting)
				const books = booksFormater(res.books, 720);


				processCampaignTouchpoints(res);

				dispatch(FacebookTouchpoint.setTouchpoints(res.facebookTouchpoints));
				dispatch(GoogleTouchpoint.setTouchpoints(res.googleTouchpoints));
				dispatch(AmazonTouchpoint.setTouchpoints(res.amazonTouchpoints));
				dispatch(EmailBlastTouchpoint.setTouchpoints(res.emailBlastTouchpoints));

				const campaign =  {
					...res,
					books,
					marketingGoals,
					promoting,
				}

				dispatch({
					type: types.GET_CAMPAIGN_SUCCESS,
					payload: campaign,
				});


				if (window.location.pathname.includes("shopping-cart") && ![5, 4].includes(res.status)) {
					history.push(`/app/summary?campaignId=${res.id}`);
				}

				if (callback) {
					callback(campaign)
				}

			} catch (error) {
				dispatch({
					type: types.GET_CAMPAIGN_FAILED,
					payload: JSON.stringify(error),
				})
			}
		}

export const deleteCampaign =
	(accountId: string, campaignId: string) =>
		async (dispatch: Dispatch<types.DispatchTypes | any>) => {
			try {

				dispatch({ type: types.DELETE_CAMPAIGN_REQUEST });

				const service = new CampaignService(accountId);
				await service.deleteCampaign(campaignId);

				dispatch({
					type: types.DELETE_CAMPAIGN_SUCCESS,
					payload: campaignId,
				});

				dispatch({
					type: DELETE_CAMPAIGN_FROM_FILTER,
					payload: campaignId,
				})

			} catch (error) {

				dispatch({
					type: types.DELETE_CAMPAIGN_FAILED,
					payload: JSON.stringify(error),
				});

			}
		}

//-------------END Campaign Actions

export const deleteTouchpoint =
	(credentials: any, type: string) =>
		async (
			dispatch: Dispatch<
				types.DispatchTypes | { type: 'REMOVE_SERVICE'; payload: string }
			>
		) => {
			try {
				dispatch({ type: types.DELETE_FB_TOUCHPOINT_REQUEST })
				const { campaignId, accountId, touchpointId } = credentials

				let res: number | null = null

				if (type === 'Facebook' || type === "Instagram") {
					const facebook = new FacebookService(accountId);
					res = await facebook.deleteFbTouchpoint(touchpointId);
				}

				if (type === 'Search' || type === 'Display') {
					const google = new GoogleService(touchpointId);
					res = await google.deleteTouchpoint();
				}

				if (type === 'AmazonSP') {
					const amazon = new AmazonService(touchpointId);
					res = await amazon.deleteTouchpoint();
				}

				if (type === "EBlast") {
					const eblast = new EmailBlastService(touchpointId);
					res = await eblast.deleteTouchpoint();
				}

				if (res && res === 200) {
					dispatch({
						type: types.DELETE_FB_TOUCHPOINT_SUCCESS,
						payload: res,
					})

					dispatch({
						type: 'REMOVE_SERVICE',
						payload: touchpointId,
					})

					const campaigns = new CampaignService(accountId)
					const campaign = await campaigns.getCampaignById(campaignId)

					const marketingGoals = bitToFormValue(Goals, campaign.marketingGoals)
					const promoting = bitToFormValue(Promotions, campaign.promoting)
					const books = booksFormater(campaign.books, 648)

					dispatch({
						type: types.GET_CAMPAIGN_SUCCESS,
						payload: {
							...campaign,
							books,
							marketingGoals,
							promoting,
						},
					})
				}
			} catch (error) {
				dispatch({
					type: types.DELETE_FB_TOUCHPOINT_FAILED,
					payload: JSON.stringify(error),
				})
			}
		}

export const processCampaignCheckout =
	(accountId: string, form: any) =>
		async (dispatch: Dispatch<types.DispatchTypes>) => {
			try {
				dispatch({ type: types.PROCESS_CAMPAIGN_CHECKOUT_REQUEST })

				const service = new CampaignService(accountId)
				const res = await service.processCampaignCheckout(form)

				if (res === 200) {
					dispatch({
						type: types.PROCESS_CAMPAIGN_CHECKOUT_SUCCESS,
					});
					
					dispatch({ type: types.RESET_FORM });
					history.push('/app/thanks?campaign=' + form.campaignId);
				} else {
					dispatch({
						type: types.PROCESS_CAMPAIGN_CHECKOUT_FAILED,
						payload: res,
					})
				}
			} catch (error) {
				dispatch({
					type: types.PROCESS_CAMPAIGN_CHECKOUT_FAILED,
					payload: JSON.stringify(error),
				})
			}
		}

export const getFinalKeywords =
	(accountId: string, campaignId: string) =>
		async (dispatch: Dispatch<types.DispatchTypes>) => {
			try {
				dispatch({ type: types.GET_FINAL_KEYWORDS_REQUEST })

				const service = new CampaignService(accountId)
				const res = await service.getBisacKeywords(campaignId)

				dispatch({
					type: types.GET_FINAL_KEYWORDS_SUCCESS,
					payload: res,
				})
			} catch (error) {
				dispatch({
					type: types.GET_RECOMENDED_AUDIENCES_FAILED,
					payload: JSON.stringify(error),
				})
			}
		}

export const toggleShareAmazonBudget = () => {
	return {
		type: types.TOGGLE_SHARE_AMAZON_BUDGET,
	}
}

export const cloneCampaign = (accountId: string, sourceCampaignId: string, targetName: string) => async (dispatch: Dispatch<types.DispatchTypes>) => {
	try {
		dispatch({ type: types.CLONE_CAMPAIGN_REQUEST });

		const service = new CampaignService(accountId);
		const cloneId = await service.cloneCampaign(sourceCampaignId, targetName);

		dispatch({ type: types.CLONE_CAMPAIGN_SUCCESS });
		history.push(`/app/shopping-cart?target=${cloneId}`);

	} catch (error) {
		dispatch({
			type: types.CLONE_CAMPAIGN_FAILED,
			payload: (error as any).message
		})
	}
}

