import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import CacheBuster from 'react-cache-buster';
import packageJson from '../package.json';
import { store, persistor } from './store';
import { Loader } from './components';
import App from './App';
import './index.css';
import { PersistGate } from 'redux-persist/integration/react';

const version = packageJson.version;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={process.env.NODE_ENV === 'production'}
    loadingComponent={<Loader />}
  >
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<Loader/>}>
        <App />
      </PersistGate>
    </Provider>
  </CacheBuster>
);
