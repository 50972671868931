import * as types from './types'

const initialState: types.AccountState = {
  avatar: undefined,
  details: {
    businessName: '',
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    salesforceInvitedContactId: '',
    avatarUrl: null,
    salesforceAccountId: '',
    salesforceContactId: '',
    lsiAccountNumber: '',
    stripeAccountId: '',
    suscribe: false,
    jobTitle: '',
    facebookPages: [],
    signupCode: ''
  },
  steps: [],
  status: {
    loading: false,
    error: null,
    msg: null,
    verifyUrl: null,
    verifyInvite: false,
    created: false,
    verified: false,
    loggedIn: false,
    invalidCredentials: false,
    notVerifiedAttempt: false,
    attempts: 0,
    blocked: null,
    emailChange: false,
    impersonation: false
  },
}

const AccountReducer = (
  state: types.AccountState = initialState,
  action: any
): types.AccountState => {
  switch (action.type) {

    case types.RESET_LOADING_ACCOUNT : {
      return {
        ...state,
        status: {
          ...state.status,
          loading: initialState.status.loading,
        }
      }
    }

    case types.VALIDATE_INVITE_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
        },
      }

    case types.VALIDATE_INVITE_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          id: action.payload.id,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          jobTitle: action.payload.jobTitle,
          email: action.payload.email,
          salesforceAccountId: action.payload.salesforceAccountId,
          salesforceContactId: action.payload.salesforceContactId,
        },
        status: {
          ...state.status,
          loading: false,
          verifyInvite: true,
        },
      }

    case types.VALIDATE_INVITE_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: { code: action.payload.code, message: action.payload.message },
        },
      }

    case types.SIGN_UP_ACCOUNT_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
        },
      }

    case types.SIGN_UP_ACCOUNT_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          stripeAccountId: action.payload,
        },
        status: {
          ...state.status,
          loading: false,
          verifyUrl: action.payload,
          created: true,
        },
      }

    case types.SIGN_UP_ACCOUNT_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          msg: action.payload,
        },
      }

    case types.CONFIRM_ACCOUNT_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
        },
      }

    case types.CONFIRM_ACCOUNT_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          verified: true,
        },
      }

    case types.CONFIRM_ACCOUNT_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          msg: action.payload,
        },
      }

    case types.RESEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          msg: initialState.status.msg,
        },
      }

    case types.RESEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          msg: initialState.status.msg,
          notVerifiedAttempt: false,
          loading: false,
        },
      }

    case types.RESEND_VERIFICATION_EMAIL_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          msg: action.payload,
        },
      }

    case types.SIGN_IN_ACCOUNT_REQUEST:
      return {
        ...state,
        status: {
          ...initialState.status,
          loading: true,
        },
      }

    case types.SIGN_IN_ACCOUNT_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          id: action.payload,
        },
        status: {
          ...state.status,
          loading: false,
          loggedIn: true,
          invalidCredentials: false,
          attempts: 0,
          blocked: null,
        },
      }

    case types.SIGN_IN_ACCOUNT_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
        },
      }

    case types.SIGN_IN_ACCOUNT_INVALID:
      if (state.status.attempts < 3) {
        return {
          ...state,
          status: {
            ...state.status,
            loading: false,
            invalidCredentials: true,
            attempts: state.status.attempts + 1,
          },
        }
      } else {
        return {
          ...state,
          status: {
            ...state.status,
            attempts: state.status.attempts + 1,
            blocked: action.payload,
          },
        }
      }

    case types.SIGN_IN_ACCOUNT_NOT_VERIFIED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          notVerifiedAttempt: true,
        },
      }

    case types.SIGN_IN_RESET_INVALID_ATTEMPTS:
      return {
        ...state,
        status: {
          ...state.status,
          attempts: 0,
          invalidCredentials: false,
        },
      }

    case types.SIGN_IN_IMPERSONATED_ACCOUNT: 
      return {
        ...state,
        status: {
          ...state.status,
          impersonation: true
        }
      }

    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
        },
      }

    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          attempts: 0,
          blocked: null,
        },
      }

    case types.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          msg: action.payload,
        },
      }

    case types.SIGN_OUT_ACCOUNT:
      return {
        ...state,
        details: initialState.details,
        status: {
          ...state.status,
          loggedIn: false,
          attempts: initialState.status.attempts,
          invalidCredentials: initialState.status.invalidCredentials,
          impersonation: initialState.status.impersonation
        },
      }

    case types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          msg: initialState.status.msg,
        },
      }

    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
        },
      }

    case types.RESET_PASSWORD_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          msg: action.payload,
        },
      }

    case types.UPDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          msg: '',
        },
      }

    case types.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          ...action.payload,
        },
        status: {
          ...state.status,
          loading: false,
        },
      }

    case types.EMAIL_CHANGE_TOGGLE:
      return {
        ...state,
        status: {
          ...state.status,
          emailChange: action.payload,
        },
      }

    case types.UPDATE_ACCOUNT_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          msg: action.payload,
        },
      }

    case types.UPDATE_ACCOUNT_PASSWORD_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          msg: '',
        },
      }

    case types.UPDATE_ACCOUNT_PASSWORD_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          msg: 'Password succesfully changed',
        },
      }

    case types.UPDATE_ACCOUNT_PASSWORD_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          msg: action.payload,
        },
      }

    case types.GET_ACCOUNT_BY_ID_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          msg: initialState.status.msg,
        },
      }

    case types.GET_ACCOUNT_BY_ID_SUCCESS:
      return {
        ...state,
        details: {
          ...action.payload,
        },
        status: {
          ...state.status,
          loading: false,
        },
      }

    case types.GET_ACCOUNT_BY_ID_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
        },
      }

    case types.ACCOUNT_RECOMMENDED_STEPS_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          msg: initialState.status.msg,
        },
      }

    case types.ACCOUNT_RECOMMENDED_STEPS_SUCCESS:
      return {
        ...state,
        steps: action.payload,
        status: {
          ...state.status,
          loading: false,
        },
      }

    case types.ACCOUNT_RECOMMENDED_STEPS_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          msg: action.payload,
        },
      }

    case types.SKIP_RECOMMENDED_STEP_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          //loading: true,
          msg: initialState.status.msg,
        },
      }

    case types.SKIP_RECOMMENDED_STEP_SUCCESS: {
      const selected = state.steps.filter(step => step.id === action.payload)[0]
      const rest = state.steps.filter(step => step.id !== action.payload)

      selected.skipped = !selected.skipped

      return {
        ...state,
        steps: [...rest, selected],
        status: {
          ...state.status,
          loading: false,
        },
      }
    }

    case types.SKIP_RECOMMENDED_STEP_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          msg: action.payload,
        },
      }

    case types.POST_FB_ACCESS_TOKEN_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          msg: initialState.status.msg,
        },
      }

    case types.POST_FB_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        details: action.payload,
        status: {
          ...state.status,
          loading: false,
        },
      }

    case types.POST_FB_ACCESS_TOKEN_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          msg: action.payload,
        },
      }

    case types.VALIDATE_FB_INTEGRATION_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          msg: initialState.status.msg,
        },
      }

    case types.VALIDATE_FB_INTEGRATION_SUCCESS:
      return {
        ...state,
        details: action.payload,
        status: {
          ...state.status,
          loading: false,
        },
      }

    case types.VALIDATE_FB_INTEGRATION_FAILED:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          msg: action.payload,
        },
      }

    case types.UPDATE_ACCOUNT_AVATAR_REQUEST: 
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          error: initialState.status.error
        }
      }
    
    case types.UPDATE_ACCOUNT_AVATAR_SUCCESS:  {
      return {
        ...state,
        details:  {
          ...state.details,
          avatarUrl: action.payload
        },
        status: {
          ...state.status,
          loading: false,
        }
      }
    }

    case types.UPDATE_ACCOUNT_AVATAR_FAILED: {
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: action.payload
        }
      }
    }

    case types.RESET_ACCOUNT_STATE:
      return initialState;
    
    case types.RESET_ACCOUNT_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          error: null,
          msg: null
        }
      }

    default:
      return state
  }
}

export default AccountReducer
