import { Router } from "react-router";
import { AppRoutes, history } from "./router";
import { useDispatch } from "react-redux";
import { ScrollToTop } from "./router/ScrollToTop";
import { useEffect, useState } from 'react';
//contexts
import Theme from "./theme";
import { CampaignContextProvider } from "./components/Wizards/Campaigns";
import SidebarContextProvider from "./components/Sidebar/Context";
import { NotificationsProvider } from "./components/Notifications";
import { Footer } from "./components";
import { RootBoundary } from "./errors";
import { GoogleAnalytics, OneTrust } from "./scripts";
import { signOutAccount } from "./reducers/Account";
import FetchErrorModal from "./components/Modals/FetchErrorModal";
import ScrollToAnchor from "./components/Layout/ScrollToAnchor";


function App() {
	const dispatch = useDispatch();
	const [fetchError, setFetchError] = useState<boolean>(false);

	useEffect(() => {
		const { fetch: originalFetch } = window;

		window.fetch = async (...args) => {
			
			let [resource, config] = args;
			
			const response = await originalFetch(resource, config)
				.catch((error) => {
					if (error.message.includes("NetworkError")) {
						dispatch(signOutAccount());
					}

					if (error.message === "Failed to fetch" && !resource.toString().toLocaleLowerCase().includes('/accounts')) {
						setFetchError(true);
					}

					throw error
				});
		
			if (response.redirected && !(resource as string).includes("accounts/Logout") && config?.method !== "POST") {
				dispatch(signOutAccount());
			}

			return response;
		}
	}, [dispatch]);

	return (
		<div className="App">
			<OneTrust />
			<Theme>
				<Router history={history}>
					<GoogleAnalytics />
					<CampaignContextProvider>
						<NotificationsProvider>
							<RootBoundary>
								<SidebarContextProvider>
									<ScrollToTop />
									<ScrollToAnchor />
									<AppRoutes />
								</SidebarContextProvider>
							</RootBoundary>
						</NotificationsProvider>
					</CampaignContextProvider>
				</Router>
				<Footer />
				<FetchErrorModal open={fetchError} onClose={() => setFetchError(false)}/>
			</Theme>
		</div>
	);
}

export default App;