import { IAmazonTouchpoint, TouchpointFormError } from '../../../models';

export type AmazonTouchpointState = {
  touchpoints: IAmazonTouchpoint[],
  keywords: {
    loading: boolean,
    data: any[],
    error: any
  },
  prices: any[],
  step: string,
  loading: boolean,
  error: any,
  formErrors: Array<TouchpointFormError>,
}

export const SET_AMAZON_FORM_ERRORS = 'SET_AMAZON_FORM_ERRORS';

type setFormErrors = {
    type: typeof SET_AMAZON_FORM_ERRORS,
    payload: Array<TouchpointFormError>,
}

export const RESET_AMAZON_FORM_ERRORS = 'RESET_AMAZON_FORM_ERRORS';

type resetFormErrors = {
    type: typeof RESET_AMAZON_FORM_ERRORS,
}

export const DELETE_AMAZON_FORM_ERROR = 'DELETE_AMAZON_FORM_ERROR';

type deleteFormError = {
    type: typeof DELETE_AMAZON_FORM_ERROR,
    payload: string, //field pointer
}


export const LOAD_MOCK_AMAZON_TOUCHPOINT = 'LOAD_MOCK_AMAZON_TOUCHPOINT';
export const LOAD_MOCK_SUMMARY = 'LOAD_MOCK_SUMMARY';

type loadMockSummary = {
  type: typeof LOAD_MOCK_SUMMARY,
  payload: any
}

type loadMockTouchpoint = {
  type: typeof LOAD_MOCK_AMAZON_TOUCHPOINT,
  payload: any
}

export const SET_AZ_TOUCHPOINTS = 'SET_AZ_TOUCHPOINTS';
export const SET_AZ_TOUCHPOINT = 'SET_AZ_TOUCHPOINT';
export const SET_AZ_TOUCHPOINT_STEP = 'ET_AZ_TOUCHPOINT_STEP';

type setAmazonTouchpoints = {
  type: typeof SET_AZ_TOUCHPOINTS,
  payload: IAmazonTouchpoint[]
}

type setAmazonTouchpoint = {
  type: typeof SET_AZ_TOUCHPOINT,
  payload: IAmazonTouchpoint,
}

type setAmazonStep = {
  type: typeof SET_AZ_TOUCHPOINT_STEP,
  payload: string
}

export const GET_AZ_TOUCHPOINT_REQUEST = 'GET_AZ_TOUCHPOINT_REQUEST';
export const GET_AZ_TOUCHPOINT_SUCCESS = 'GET_AZ_TOUCHPOINT_SUCCESS';
export const GET_AZ_TOUCHPOINT_FAILED = 'GET_AZ_TOUCHPOINT_FAILED';

type getTochpointRequest = {
  type: typeof GET_AZ_TOUCHPOINT_REQUEST
}
type getTochpointSuccess = {
  type: typeof GET_AZ_TOUCHPOINT_SUCCESS,
  payload: IAmazonTouchpoint,
}
type getTochpointFailed = {
  type: typeof GET_AZ_TOUCHPOINT_FAILED,
  payload: string
}

export const UPDATE_AZ_TOUCHPOINT_REQUEST = 'UPDATE_AZ_TOUCHPOINT_REQUEST';
export const UPDATE_AZ_TOUCHPOINT_SUCCESS = 'UPDATE_AZ_TOUCHPOINT_SUCCESS';
export const UPDATE_AZ_TOUCHPOINT_FAILED = 'UPDATE_AZ_TOUCHPOINT_FAILED';

type updateTochpointRequest = {
  type: typeof UPDATE_AZ_TOUCHPOINT_REQUEST,
}
type updateTochpointSuccess = {
  type: typeof UPDATE_AZ_TOUCHPOINT_SUCCESS,
}
type updateTochpointFailed = {
  type: typeof UPDATE_AZ_TOUCHPOINT_FAILED,
  payload: string
}

export const DELETE_AZ_TOUCHPOINT_REQUEST = 'DELETE_AZ_TOUCHPOINT_REQUEST';
export const DELETE_AZ_TOUCHPOINT_SUCCESS = 'DELETE_AZ_TOUCHPOINT_SUCCESS';
export const DELETE_AZ_TOUCHPOINT_FAILED = 'DELETE_AZ_TOUCHPOINT_FAILED';

type deleteTochpointRequest = {
  type: typeof DELETE_AZ_TOUCHPOINT_REQUEST
}
type deleteTochpointSuccess = {
  type: typeof DELETE_AZ_TOUCHPOINT_SUCCESS,
  payload: string,
}
type deleteTochpointFailed = {
  type: typeof DELETE_AZ_TOUCHPOINT_FAILED,
  payload: string,
}

export const GET_AZ_SUGGESTED_KEYWORDS_REQUEST = 'GET_AZ_SUGGESTED_KEYWORDS_REQUEST';
export const GET_AZ_SUGGESTED_KEYWORDS_SUCCESS = 'GET_AZ_SUGGESTED_KEYWORDS_SUCCESS';
export const GET_AZ_SUGGESTED_KEYWORDS_FAILED = 'GET_AZ_SUGGESTED_KEYWORDS_FAILED';

type getSuggestedKeywordsRequest = {
  type: typeof GET_AZ_SUGGESTED_KEYWORDS_REQUEST
}
type getSuggestedKeywordsSuccess = {
  type: typeof GET_AZ_SUGGESTED_KEYWORDS_SUCCESS,
  payload: Array<any>
}
type getSuggestedKeywordsFailed = {
  type: typeof GET_AZ_SUGGESTED_KEYWORDS_FAILED,
  payload: any,
}

export type DispatchTypes = 
  loadMockTouchpoint | loadMockSummary | setAmazonTouchpoints | setAmazonTouchpoint | setAmazonStep |
  getTochpointRequest | getTochpointSuccess | getTochpointFailed |
  updateTochpointRequest | updateTochpointSuccess | updateTochpointFailed |
  deleteTochpointRequest | deleteTochpointSuccess | deleteTochpointFailed |
  getSuggestedKeywordsRequest | getSuggestedKeywordsSuccess | getSuggestedKeywordsFailed | 
  setFormErrors | resetFormErrors | deleteFormError
;