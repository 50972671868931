const mockTouchpoint = (campaignId: string, touchpointId: string) => ({
  id: touchpointId,
  displayName: 'Amazon Sponsored Product Ad',
  keywords: [],
  bookId: null,
  book: null,
  campaignId,
  dailyBudget: null,
  startDate: null,
  endDate: null,
  products: [],
  productPrice: null,
  budget: null,
  status: 5,
  completePercentage: 0,
  reason: null,
  statsResume: null,
})

export class AmazonService {
  private readonly touchpointId: string;
  private readonly baseUrl: string;

  constructor (touchpointId: string) {
    this.touchpointId = touchpointId;
    this.baseUrl = `${process.env.REACT_APP_API_URL}/AmazonTouchpoint`;

    return this;
  }

  getMockTouchpoint(campaignId: string) {
    return mockTouchpoint(campaignId, this.touchpointId)
  }

  getTouchpoint(): Promise<any> {
    return fetch(`${this.baseUrl}/${this.touchpointId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json ;',
      },
      credentials: 'include'
    })
      .then(res => {
        if(res.ok) return res.json();
        else return Promise.reject(res);
      })
      .catch(error => Promise.reject(error))
  }

  updateTouchpoint(touchpoint: any): Promise<any> {
    return fetch(`${this.baseUrl}/${touchpoint.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json ;',
      },
      credentials: 'include',
      body: JSON.stringify(touchpoint),
    })
      .then(res => {
        if(res.ok) return res.status;
        else return res.json();
      })
      .then(json => {
        if(json === 200) return 200;
        else return Promise.reject(json);
      })
      .catch(error => Promise.reject(error))
  }

  cloneTouchpoint(touchpointId: string, bookIds: Array<string>): Promise<any> {
    return fetch(`${this.baseUrl}/Clone/${touchpointId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json ;',
      },
      credentials: 'include',
      body: JSON.stringify(bookIds),
    })
      .then(res => {
        if(res.ok) return res.status;
        else return res.json();
      })
      .then(json => {
        if(json === 200) return 200;
        else return Promise.reject(json);
      })
      .catch(error => Promise.reject(error))
  }

  deleteTouchpoint(): Promise<any> {
    return fetch(`${this.baseUrl}/${this.touchpointId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json ;',
      },
      credentials: 'include'
    })
    .then(res => {
      if(res.ok) return res.status;
      else return Promise.reject(res);
    })
    .catch(error => Promise.reject(error))
  }

  getSuggestedKeywords(bookIds: string): Promise<any> {
    return fetch(`${this.baseUrl}/GetSuggestedKeywordsByBookIds/${this.touchpointId}`, {
      method: 'POST',
      body: bookIds,
      headers: {
        'Content-Type': 'application/json ;',
      },
      credentials: 'include'
    })
    .then(res => {
      if (res.ok) return res.json();
      else return res.json();
    })
    .then((json) => {
      if(json.message) {
        throw json;
      }
      return json;
    })
    .catch(error => Promise.reject(error.message))
  }
  
}