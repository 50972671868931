type StatementModel = {
  date: Date,
  campaignId: string,
  campaignName: string,
  isbn: string[],
  status: string,
  totalAmount: string,
  transactionId: string,
}

export type StatementsState = {
  statements: StatementModel[],
  range: {
    sinceDate: null | Date,
    untilDate: null | Date,
  },
  table: {
    page: number,
    count: number,
    selected: string[],
  },
  status: {
    loading: boolean,
    error: string,
  }
}

export const SET_DATE_RANGE = 'SET_DATE_RANGE';

type setDateRange = {
  type: typeof SET_DATE_RANGE,
  payload: {
    sinceDate: null | Date,
    untilDate: null | Date,
  }
}

export const SET_TABLE_PAGE = 'SET_TABLE_PAGE';

type setTablePage = {
  type: typeof SET_TABLE_PAGE,
  payload: number,
}

export const SET_TABLE_COUNT =  'SET_TABLE_COUNT';

type setTableCount = {
  type: typeof SET_TABLE_COUNT,
  payload: number
}

export const SELECT_TABLE_ROWS = 'SELECT_TABLE_ROWS';

type selectTableRows = {
  type: typeof SELECT_TABLE_ROWS,
  payload: string[],
}

export const GET_STATEMENTS_REQUEST = 'GET_STATEMENTS_REQUEST';
export const GET_STATEMENTS_SUCCESS = 'GET_STATEMENTS_SUCCESS';
export const GET_STATEMENTS_FAILED = 'GET_STATEMENTS_FAILED';

type getStatementsRequest = {
  type: typeof GET_STATEMENTS_REQUEST,
}

type getStatementsSuccess = {
  type: typeof GET_STATEMENTS_SUCCESS,
  payload: StatementModel[],
}

type getStatementsFailed = {
  type: typeof GET_STATEMENTS_FAILED, 
}

export type DispatchTypes = 
  setTablePage | setTableCount | selectTableRows | setDateRange |
  getStatementsRequest | getStatementsSuccess | getStatementsFailed
;