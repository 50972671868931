import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Loader } from "../components";
import { RouteModel } from "./route";
import { ProtectedRoute } from "./ProtectedRoute";
import { RouteWithSubRoutes } from "./RouteWithSubRoutes";
import { routes } from "./routes";

export const history = createBrowserHistory();

export const configRoutes = (routes: RouteModel[]) =>
  routes.map((route: RouteModel) =>
    route?.isProtected ? (
      <ProtectedRoute key={route.path} {...route} />
    ) : (
      <RouteWithSubRoutes key={route.path} {...route} />
    )
  );

export const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>{configRoutes(routes)}</Switch>
    </Suspense>
  );
};
