import { createContext, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { NotificationModel } from "../../models";
import { RootStore } from "../../store";

type OptionModel = {
  id: string;
  name: string;
};

type NotificationsContextModel = {
  today: NotificationModel[];
  thisWeek: NotificationModel[];
  lastWeek: NotificationModel[];
  rest: NotificationModel[];
  options: OptionModel[];
  filter: string[];
  setFilter(filter: string[]): void;
};

type ProviderProps = {
  children: any;
};

export const NotificationsContext = createContext<NotificationsContextModel>({
  today: [],
  thisWeek: [],
  lastWeek: [],
  rest: [],
  options: [],
  filter: [],
  setFilter: () => {},
});

const NotificationsProvider: React.FC<ProviderProps> = ({ children }) => {
  const [filter, setFilter] = useState<string[]>([]);

  //get posible options from camapign info
  let rawCampaigns = useSelector((store: RootStore) =>
    store.notifications.data.map((notification: NotificationModel) => {
      return {
        name: notification.campaignName,
        id: notification.campaignId,
      };
    })
  );
  //remove duplicate options
  const options = rawCampaigns.filter(
    (campaign: any, index: number, current: any) =>
      index ===
      current.findIndex(
        (c: any) => campaign.name === c.name && campaign.id === c.id
      )
  );

  //set time filters
  const now = useMemo(() => new Date(), []);
  now.setHours(0, 0, 0, 0);

  const dayOfWeek = now.getDay();
  const dayOfMonth = now.getDate();

  //Earlier this week
  function getThisWeekStart() {
    let start = new Date(now);
    start.setDate(dayOfMonth - dayOfWeek);

    return start.getTime();
  }
  //Last week
  function getLastWeekStart() {
    let end = new Date(now);
    end.setDate(dayOfMonth - dayOfWeek);
    let start = new Date(end);
    start.setDate(dayOfMonth - dayOfWeek - 6);

    return start.getTime();
  }
  //Dates processor

  const lastWeekStart = getLastWeekStart();
  const thisWeekStart = getThisWeekStart();

  const notifications = useSelector(
    (store: RootStore) => store.notifications.data
  );
  const newNotifications = useSelector(
    (store: RootStore) => store.notifications.new
  );

  const [today, thisWeek, lastWeek, rest] = useMemo(() => {
    const today: Array<NotificationModel> = [];
    const thisWeek: Array<NotificationModel> = [];
    const lastWeek: Array<NotificationModel> = [];
    const rest: Array<NotificationModel> = [];

    const all = newNotifications.concat(notifications);
    const filtred = all.filter(
      (n: NotificationModel, index: number, array: Array<NotificationModel>) =>
        index === array.findIndex((s) => s.id === n.id)
    );

    filtred.forEach((n: NotificationModel) => {
      const created = new Date(n.created).getTime();
      if (created > now.getTime()) {
        return today.push(n);
      }

      if (created > thisWeekStart) {
        return thisWeek.push(n);
      }

      if (created > lastWeekStart) {
        return lastWeek.push(n);
      }

      return rest.push(n);
    });

    return [
      today.sort((a: NotificationModel, b: NotificationModel)=>{return b.created.getTime() - a.created.getTime();}), 
      thisWeek.sort((a: NotificationModel, b: NotificationModel)=>{return b.created.getTime() - a.created.getTime();}), 
      lastWeek.sort((a: NotificationModel, b: NotificationModel)=>{return b.created.getTime() - a.created.getTime();}), 
      rest.sort((a: NotificationModel, b: NotificationModel)=>{return b.created.getTime() - a.created.getTime();})
    ];
  }, [notifications, newNotifications, now, lastWeekStart, thisWeekStart]);

  return (
    <NotificationsContext.Provider
      value={{
        today,
        thisWeek,
        lastWeek,
        rest,
        options,
        filter,
        setFilter,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
