function enumToBitValues(enumeration: object) {
  return Object.keys(enumeration).map(Number).filter(Boolean); 
}

export function formValueToBit(enumeration: object, selection: boolean[]) {
  const bits = enumToBitValues(enumeration);
  return selection.reduce(
    (total, selected, i) => total + (selected ? bits[i] : 0),
    0,
  );
}

export function bitToFormValue(enumeration: object, bit: number) {
  const bits = enumToBitValues(enumeration)
  return bits.map((b) => (bit & b) === b)
}
