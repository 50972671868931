import * as types from './types';
import { getShowing } from '../../../utils';

const initialState: types.FilterState = {
	controls: {
		all: { count: 0, value: true },
		needsAttention: { count: 0, value: true },
		scheduled: { count: 0, value: true },
		live: { count: 0, value: true },
		draft: { count: 0, value: true },
		inReview: { count: 0, value: true },
		completed: { count: 0, value: true },
		cancelled: { count: 0, value: true },
		pagination: {
			maxPages: 0,
			currentPage: 0,
			totalCampaigns: 0,
		},
	},
	options: {
		authors: [],
		titles: [],
		campaigns: []
	},
	showing: '0,1,2,3,4,5,6,7',
	campaigns: [],
	results: [],
	selected: [],
	folders: [],
	favorites: [],
	currentFolder: null,
	moveTarget: null,
	path: ['root'],
	data: {
		0: [],
		1: [],
		2: [],
		3: [],
		4: [],
		5: [],
		6: [],
		7: [],
	},
	status: {
		filterLoad: false,
		folderLoad: false,
		campaignLoad: false,
		error: null,
	}
}

const FilterReducer = (
	state: types.FilterState = initialState,
	action: types.DispatchTypes
) => {
	switch (action.type) {

		case types.RESET_CAMPAIGN_FILTER_STATE: {
			return {
				...initialState,
				campaigns: [],
			}
		}

		case types.SET_FILTER_OPTIONS: {
			return {
				...state,
				options: action.payload,
				status: {
					...state.status,
					filterLoad: false,
				}
			}
		}

		case types.GET_CAMPAIGNS_BY_ACCOUNT_REQUEST: {
			return {
				...state,
				status: {
					...state.status,
					campaignLoad: true,
				},
			}
		}

		case types.GET_CAMPAIGNS_BY_ACCOUNT_SUCCESS: {
			return {
				...state,
				campaigns: action.payload,
				status: {
					...state.status,
					campaignLoad: false,
				},
			}
		}

		case types.GET_CAMPAIGNS_BY_ACCOUNT_FAILED: {
			return {
				...state,
				status: {
					...state.status,
					error: action.payload,
					campaignLoad: false,
				},
			}
		}

		case types.SET_FILTER_DATA: {
			return {
				...state,
				data: {
					...initialState.data,
					...action.payload
				},
			}
		}

		case types.SET_FILTER_CONTROLS: {
			return {
				...state,
				controls: action.payload
			}
		}

		case types.SET_FILTER_RESULTS: {
			return {
				...state,
				results: action.payload
			}
		}

		case types.SELECT_STATUS_CONTROL: {
			return {
				...state,
				controls: action.payload,
				showing: getShowing(action.payload),
			}
		}

		case types.TOGGLE_CAMPAIGN_FAVORITE: {
			const campaigns = state.campaigns;
			const campaignIndex = campaigns.findIndex((c) => c.id === action.payload);

			if (campaignIndex >= 0) {
				campaigns[campaignIndex].favorite = !campaigns[campaignIndex].favorite;
			}

			return {
				...state,
				campaigns,
			}
		}

		case types.MOVE_TO_FOLDER_REQUEST: {
			return {
				...state,
				status: {
					...state.status,
					secondaryLoad: true,
				}
			}
		}

		case types.MOVE_TO_FOLDER_SUCCESS: {
			const campaigns = state.campaigns;
			const index = campaigns.findIndex((c) => c.id === action.payload.campaignId);
			campaigns[index].folderId = action.payload.folderId

			return {
				...state,
				campaigns,
				status: {
					...state.status,
					secondaryLoad: false
				}
			}
		}


		case types.MOVE_TO_FOLDER_FAILED: {
			return {
				...state,
				status: {
					...state.status,
					secondaryLoad: false,
					error: action.payload
				}
			}
		}

		case types.SET_SELECTED_OPTIONS: {
			return {
				...state,
				selected: action.payload
			}
		}

		case types.SET_CURRENT_PAGE: {
			return {
				...state,
				controls: {
					...state.controls,
					pagination: {
						...state.controls.pagination,
						currentPage: action.payload
					}
				}
			}
		}

		case types.DELETE_CAMPAIGN_FROM_FILTER: {
			return {
				...state,
				campaigns: state.campaigns.filter((c) => c.id !== action.payload),
				results: state.results.filter((c) => c.id !== action.payload),
			}
		}

		case types.GET_ACCOUNT_FOLDERS_REQUEST: {
			return {
				...state,
				status: {
					...state.status,
					folderLoad: true,
				}
			}
		}

		case types.GET_ACCOUNT_FOLDERS_SUCCESS: {
			return {
				...state,
				folders: action.payload,
				status: {
					...state.status,
					folderLoad: false,
				}
			}
		}

		case types.GET_ACCOUNT_FOLDERS_FAILED: {
			return {
				...state,
				status: {
					...state.status,
					folderLoad: false,
					error: action.payload,
				}
			}
		}

		case types.GET_FAVORITE_FOLDERS_REQUEST: {
			return {
				...state,
				status: {
					...state.status,
					folderLoad: true,
					error: initialState.status.error
				}
			}
		}

		case types.GET_FAVORITE_FOLDERS_SUCCESS: {
			return {
				...state,
				favorites: action.payload,
				status: {
					...state.status,
					folderLoad: false,
				}
			}
		}

		case types.GET_FAVORITE_FOLDERS_FAILED: {
			return {
				...state,
				status: {
					...state.status,
					folderLoad: false,
					error: action.payload
				}
			}
		}

		case types.SET_CURRENT_FOLDER: {
			return {
				...state,
				currentFolder: action.payload,
			}
		}

		case types.SET_FOLDER_PATH: {
			return {
				...state,
				path: action.payload,
			}
		}

		case types.CREATE_FOLDER_REQUEST || types.UPDATE_FOLDER_REQUEST || types.DELETE_FOLDER_REQUEST: {
			return {
				...state,
				status: {
					...state.status,
					folderLoad: true,
				}
			}
		}

		case types.CREATE_FOLDER_SUCCESS || types.UPDATE_FOLDER_SUCCESS || types.DELETE_FOLDER_SUCCESS: {
			return {
				...state,
				status: {
					...state.status,
					folderLoad: false,
				}
			}
		}

		case types.CREATE_FOLDER_FAILED || types.UPDATE_FOLDER_FAILED || types.DELETE_FOLDER_SUCCESS: {
			return {
				...state,
				status: {
					...state.status,
					folderLoad: false,
				}
			}
		}

		case types.SET_MOVE_TARGET: {
			return {
				...state,
				moveTarget: action.payload
			}
		}

		case types.TOGGLE_FOLDER_FAVORITE: {
			let newFavorites = state.favorites;
			let target = newFavorites.findIndex((f) => f.id === action.payload);
			if (target >= 0) {
				newFavorites[target].favorite = !state.favorites[target].favorite;
			}

			return {
				...state,
				favorites: newFavorites,
			}
		}

		default: {
			return state;
		}
	}
}

export default FilterReducer;