import { PaymentMethodModel, SummaryItemModel } from '../../models/Cart';

export interface ITaxItem {
  id: string
  country: string
  type: string
  value: string
  verification: {
    status:string,
    verifiedAddress: string
    verifiedName: string
  },
}

export type CartState = {
  paymentMethods: PaymentMethodModel[],
  defaultMethod: string,
  paymentIntent: string,
  promocode: string,
  promotion: {
    message: string,
    valid: boolean,
  },
  summary: {
    touchpoints: SummaryItemModel[],
    subTotal: number,
    total: number, 
    taxAmount: number,
    taxServiceError: boolean,
    discount: number,
    enablePayment: boolean,
    isFree: boolean,
  },
  taxes: {
    items: ITaxItem[],
    selected: ITaxItem | undefined
    loading: boolean,
    error: any
  }
  purchaseTerms: boolean,
  status: {
    loading: boolean,
    error: string,
  }
}

export const GET_PAYMENT_METHODS_REQUEST = 'GET_PAYMENT_METHODS_REQUEST';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAILED = 'GET_PAYMENT_METHODS_FAILED';

type getPaymentMethodsRequest = {
  type: typeof GET_PAYMENT_METHODS_REQUEST,
}

type getPaymentMethodsSuccess = {
  type: typeof GET_PAYMENT_METHODS_SUCCESS,
  payload: PaymentMethodModel[],
}

type getPaymentMethodsFailed = {
  type: typeof GET_PAYMENT_METHODS_FAILED,
  payload: string,
}

export const CREATE_PAYMENT_INTENT_REQUEST = 'CREATE_PAYMENT_INTENT_REQUEST';
export const CREATE_PAYMENT_INTENT_SUCCESS = 'CREATE_PAYMENT_INTENT_SUCCESS';
export const CREATE_PAYMENT_INTENT_FAILED = 'CREATE_PAYMENT_INTENT_FAILED';

type createPaymentIntentRequest = {
  type: typeof CREATE_PAYMENT_INTENT_REQUEST,
}

type createPaymentIntentSuccess = {
  type: typeof CREATE_PAYMENT_INTENT_SUCCESS,
  payload: string,
}

type createPaymentIntentFailed = {
  type: typeof CREATE_PAYMENT_INTENT_FAILED,
  payload: string,
}

export const SAVE_PAYMENT_METHOD = 'SAVE_PAYMENT_METHOD';

type savePaymentMethod = {
  type: typeof SAVE_PAYMENT_METHOD,
  payload: PaymentMethodModel
}

export const ATACH_PAYMENT_METHOD_REQUEST = 'ATACH_PAYMENT_METHOD_REQUEST';
export const ATACH_PAYMENT_METHOD_SUCCESS = 'ATACH_PAYMENT_METHOD_SUCCESS';
export const ATACH_PAYMENT_METHOD_FAILED = 'ATACH_PAYMENT_METHOD_FAILED';

type atachPaymentMethodRequest = {
  type: typeof ATACH_PAYMENT_METHOD_REQUEST,
}

type atachPaymentMethodSuccess = {
  type: typeof ATACH_PAYMENT_METHOD_SUCCESS,
}

type atachPaymentMethodFailed = {
  type: typeof ATACH_PAYMENT_METHOD_FAILED,
  payload: string
}

export const DELETE_PAYMENT_METHOD_REQUEST = 'DELETE_PAYMENT_METHOD_REQUEST';
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';
export const DELETE_PAYMENT_METHOD_FAILED = 'DELETE_PAYMENT_METHOD_FAILED';

type deletePaymentMethodRequest = {
  type: typeof DELETE_PAYMENT_METHOD_REQUEST,
}

type deletePaymentMethodSuccess = {
  type: typeof DELETE_PAYMENT_METHOD_SUCCESS,
  payload: string,
}

type deletePaymentMethodFailed = {
  type: typeof DELETE_PAYMENT_METHOD_FAILED,
  payload: string,
}

export const UPDATE_PAYMENT_METHOD_REQUEST = 'UPDATE_PAYMENT_METHOD_REQUEST';
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS';
export const UPDATE_PAYMENT_METHOD_FAILED = 'UPDATE_PAYMENT_METHOD_FAILED';

type updatePaymentMethodRequest = {
  type: typeof UPDATE_PAYMENT_METHOD_REQUEST,
}

type updatePaymentMethodSuccess = {
  type: typeof UPDATE_PAYMENT_METHOD_SUCCESS,
  payload: PaymentMethodModel,
}

type updatePaymentMethodFailed = {
  type: typeof UPDATE_PAYMENT_METHOD_FAILED,
  payload: string,
}


export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD';

type selectPaymentMethod = {
  type: typeof SELECT_PAYMENT_METHOD,
  payload: string,
}

export const GET_CAMPAIGN_SUMMARY_REQUEST = 'GET_CAMPAIGN_SUMMARY_REQUEST';
export const GET_CAMPAIGN_SUMMARY_SUCCESS = 'GET_CAMPAIGN_SUMMARY_SUCCESS';
export const GET_CAMPAIGN_SUMMARY_FAILED = 'GET_CAMPAIGN_SUMMARY_FAILED';

type getCampaignSummaryRequest = {
  type: typeof GET_CAMPAIGN_SUMMARY_REQUEST,
}

type getCampaignSummarySuccess = {
  type: typeof GET_CAMPAIGN_SUMMARY_SUCCESS,
  payload: any
}

type getCampaignSummaryFailed = {
  type: typeof GET_CAMPAIGN_SUMMARY_FAILED,
  payload: string
}

export const SET_PROMOCODE = 'SET_PROMOCODE';

type setPromocode = {
  type: typeof SET_PROMOCODE,
  payload: string,
}

export const SET_PROMOTION = 'SET_PROMOTION';

type setPromotion = {
  type: typeof SET_PROMOTION,
  payload: any,
}

export const RESET_PROMOTION = 'RESET_PROMOTION';

type resetPromotion = {
  type: typeof RESET_PROMOTION
}

export const TOGGLE_PURCHASE_TERMS = 'TOGGLE_PURCHASE_TERMS';

type togglePurchaseTerms = {
  type: typeof TOGGLE_PURCHASE_TERMS,
}

export const GET_TAX_ITEMS_REQUEST = "GET_TAX_ITEMS_REQUEST";
export const GET_TAX_ITEMS_SUCCESS = "GET_TAX_ITEMS_SUCCESS";
export const GET_TAX_ITEMS_FAILED = "GET_TAX_ITEMS_FAILED";

type getTaxItemsRequest = {
  type: typeof GET_TAX_ITEMS_REQUEST
}

type getTaxItemsSuccess = {
  type: typeof GET_TAX_ITEMS_SUCCESS,
  payload: ITaxItem[]
}

type getTaxItemsFailed = {
  type: typeof GET_TAX_ITEMS_FAILED,
  payload: any
}

export const CREATE_TAX_ITEM_REQUEST = "CREATE_TAX_ITEM_REQUEST";
export const CREATE_TAX_ITEM_SUCCESS = "CREATE_TAX_ITEM_SUCCESS";
export const CREATE_TAX_ITEM_FAILED = "CREATE_TAX_ITEM_FAILED";

type createTaxItemRequest = {
  type: typeof CREATE_TAX_ITEM_REQUEST
}

type createTaxItemSuccess = {
  type: typeof CREATE_TAX_ITEM_SUCCESS,
  payload: ITaxItem
}

type createTaxItemFailed = {
  type: typeof CREATE_TAX_ITEM_FAILED,
  payload: any
}

export const DELETE_TAX_ITEM_REQUEST = "DELETE_TAX_ITEM_REQUEST";
export const DELETE_TAX_ITEM_SUCCESS = "DELETE_TAX_ITEM_SUCCESS";
export const DELETE_TAX_ITEM_FAILED = "DELETE_TAX_ITEM_FAILED";

type deleteTaxItemRequest = {
  type: typeof DELETE_TAX_ITEM_REQUEST
}

type deleteTaxItemSuccess = {
  type: typeof DELETE_TAX_ITEM_SUCCESS,
  payload: ITaxItem
}

type deleteTaxItemFailed = {
  type: typeof DELETE_TAX_ITEM_FAILED,
  payload: any
}

export const SET_SELECTED_TAX_ITEM = "SET_SELECTED_TAX_ITEM"

type setSelectedTaxItem = {
  type: typeof SET_SELECTED_TAX_ITEM,
  payload: ITaxItem
}

export type DispatchTypes = selectPaymentMethod | savePaymentMethod | setPromocode | setPromotion | resetPromotion |
  atachPaymentMethodRequest | atachPaymentMethodSuccess | atachPaymentMethodFailed |
  updatePaymentMethodRequest | updatePaymentMethodSuccess | updatePaymentMethodFailed |
  deletePaymentMethodRequest | deletePaymentMethodSuccess | deletePaymentMethodFailed | 
  createPaymentIntentRequest | createPaymentIntentSuccess | createPaymentIntentFailed |
  getPaymentMethodsRequest | getPaymentMethodsSuccess | getPaymentMethodsFailed | 
  getCampaignSummaryRequest | getCampaignSummarySuccess | getCampaignSummaryFailed | togglePurchaseTerms |
  getTaxItemsRequest | getTaxItemsSuccess | getTaxItemsFailed |
  createTaxItemRequest | createTaxItemSuccess | createTaxItemFailed |
  deleteTaxItemRequest | deleteTaxItemSuccess | deleteTaxItemFailed |
  setSelectedTaxItem
;