export type ZendeskArticle = {
    id: string,
    url: string,
    html_url: string,
    author_id: string,
    comments_disabled: boolean,
    draft: boolean,
    promoted: boolean,
    position: number,
    vote_sum: number,
    vote_count: number,
    created_at: string,
    updated_at: string,
    name: string,
    title: string,
    source_locale: string,
    locale: string,
    outdated: boolean,
    outdated_locales: any,
    edited_at: string,
    user_segment_id: any,
    permision_group_id: any,
    label_names: any,
    body: string,
}

export type ZendeskResponse = {
    count: number,
    next_page: null | string,
    page: number,
    per_page: number,
    previous_page: null | string,
    articles: Array<ZendeskArticle>,
    sort_by: string,
    sort_order: string,
}

export type FaqsState = {
    data: ZendeskResponse,
    status: {
        loading: boolean,
        error: any,
    }
}

export const GET_FAQS_REQUEST = 'GET_FAQS_REQUEST';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';
export const GET_FAQS_FAILED = 'GET_FAQS_FAILED';

type getFAQsRequest = {
    type: typeof GET_FAQS_REQUEST,
}

type getFAQsSuccess = {
    type: typeof GET_FAQS_SUCCESS,
    payload: ZendeskResponse,
}

type getFAQsFailed = {
    type: typeof GET_FAQS_FAILED,
    payload: any,
}

export const GET_FAQ_BY_ID_REQUEST = 'GET_FAQ_BY_ID_REQUEST';
export const GET_FAQ_BY_ID_SUCCESS = 'GET_FAQ_BY_ID_SUCCESS';
export const GET_FAQ_BY_ID_FAILED = 'GET_FAQ_BY_ID_FAILED';

type getFAQByIdRequest = {
    type: typeof GET_FAQ_BY_ID_REQUEST,
    payload: string,
}

type getFAQByIdSuccess = {
    type: typeof GET_FAQ_BY_ID_SUCCESS,
    payload: any,
}

type getFAQByIdFailed = {
    type: typeof GET_FAQ_BY_ID_FAILED,
    payload: any,
}

export type DispatchTypes =
    getFAQsRequest | getFAQsSuccess | getFAQsFailed |
    getFAQByIdRequest | getFAQByIdSuccess | getFAQByIdFailed
;