import * as types from './types';

const initialState: types.AmazonTouchpointState = {
  touchpoints: [],
  keywords: {
    loading: false,
    data: [],
    error: undefined,
  },
  prices: [],
  step: 'panel1',
  loading: false,
  error: undefined,
  formErrors: [],
}

const amazonTouchpointReducer = (
  state: types.AmazonTouchpointState = initialState,
  action: any
): types.AmazonTouchpointState => {

  switch(action.type) {

    case types.LOAD_MOCK_AMAZON_TOUCHPOINT: {
      return {
        ...state,
        touchpoints: [ ...state.touchpoints, action.payload]
      }
    }

    case types.SET_AZ_TOUCHPOINT_STEP: {
      return {
        ...state,
        step: action.payload
      }
    }

    case types.SET_AZ_TOUCHPOINTS: {
      return {
        ...state,
        touchpoints: action.payload
      }
    }

    case types.SET_AZ_TOUCHPOINT: {
      const filtered = state.touchpoints.filter(
        (t) => t.id !== action.payload.id
      );

      return {
        ...state,
        touchpoints: [...filtered, action.payload]
      }
    }
    
    case types.SET_AMAZON_FORM_ERRORS: {
      return {
        ...state,
        formErrors: action.payload,
      }
    }

    case types.RESET_AMAZON_FORM_ERRORS: {
      return {
        ...state,
        formErrors: [],
      }
    }

    case types.DELETE_AMAZON_FORM_ERROR: {
      return {
        ...state,
        formErrors: state.formErrors.filter(
          (e) => e.field !== action.payload
        ),
      }
    }


    case types.GET_AZ_TOUCHPOINT_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
      
    case types.GET_AZ_TOUCHPOINT_SUCCESS: {
      const filtered = state.touchpoints.filter(
        (t) => t.id !== action.payload.id
      )
      return {
        ...state,
        touchpoints: [ ...filtered, action.payload ],
        loading: false,
      }
    }

    case types.GET_AZ_TOUCHPOINT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }

    case types.UPDATE_AZ_TOUCHPOINT_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
      
    case types.UPDATE_AZ_TOUCHPOINT_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    case types.UPDATE_AZ_TOUCHPOINT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }

    case types.DELETE_AZ_TOUCHPOINT_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
      
    case types.DELETE_AZ_TOUCHPOINT_SUCCESS: {
      const filtered = state.touchpoints.filter(
        (t) => t.id !== action.payload
      )
      return {
        ...state,
        touchpoints: [ ...filtered ],
        loading: false,
      }
    }

    case types.DELETE_AZ_TOUCHPOINT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }

    case types.GET_AZ_SUGGESTED_KEYWORDS_REQUEST: {
      return {
        ...state,
        keywords: {
          loading: true,
          data: initialState.keywords.data,
          error: initialState.keywords.error,
        }
      }
    }

    case types.GET_AZ_SUGGESTED_KEYWORDS_SUCCESS: {
      return {
        ...state,
        keywords: {
          ...state.keywords,
          loading: false,
          data: action.payload,
        }
      }
    }

    case types.GET_AZ_SUGGESTED_KEYWORDS_FAILED: {
      return {
        ...state,
        keywords: {
          ...state.keywords,
          loading: false,
          error: action.payload
        }
      }
    }

    default: {
      return state;
    }
  }
}

export default amazonTouchpointReducer;