import * as types from './types';

const initialState: types.googleTouchpointstate = {
  touchpoints: [],
  products: [],
  prices: [],
  step: 'panel1',
  loading: false,
  error: '',
  keywords: {
    data: [],
    customData: [],
    customError: '',
    load: false,
    error: '',
  },
  images: {
    landscape: {
      loading: false,
      error: '',
    },
    square: {
      loading: false,
      error: '',
    },
    logo: {
      loading: false,
      error: '',
    }
  },
  formErrors: [],
  validationItems: [],
}

const GoogleTouchpointReducer = (
  state: types.googleTouchpointstate = initialState,
  action: any
) : types.googleTouchpointstate => {

  switch (action.type) {
    
    case types.SET_PS_TOUCHPOINT_STEP: {
      return {
        ...state,
        step: action.payload
      }
    }

    case types.SET_PS_TOUCHPOINTS: {
      return {
        ...state,
        touchpoints: action.payload
      }
    }

    case types.SET_PS_TOUCHPOINT: {
      const filtered = state.touchpoints.filter(
        (t) => t.id !== action.payload.id
      );

      return {
        ...state,
        touchpoints: [...filtered, action.payload]
      }
    }
    
    case types.SET_PS_FORM_ERRORS: {
      return {
        ...state,
        formErrors: action.payload,
      }
    }

    case types.RESET_PS_FORM_ERRORS: {
      return {
        ...state,
        formErrors: [],
      }
    }

    case types.DELETE_PS_FORM_ERROR: {
      return {
        ...state,
        formErrors: state.formErrors.filter(
          (e) => e.field !== action.payload
        ),
      }
    }

    case types.SET_PS_PRICES: {
      return {
        ...state,
        prices: action.payload
      }
    }

    case types.GET_PS_TOUCHPOINT_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
      
    case types.GET_PS_TOUCHPOINT_SUCCESS: {
      const filtered = state.touchpoints.filter(
        (t) => t.id !== action.payload.id
      )
      return {
        ...state,
        touchpoints: [ ...filtered, action.payload ],
        loading: false,
      }
    }

    case types.GET_PS_TOUCHPOINT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }

    case types.UPDATE_PS_TOUCHPOINT_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
      
    case types.UPDATE_PS_TOUCHPOINT_SUCCESS: {
      const filtered = state.touchpoints.filter(
        (t) => t.id !== action.payload.id
      )
      return {
        ...state,
        touchpoints: [ ...filtered, action.payload ],
        loading: false,
      }
    }

    case types.UPDATE_PS_TOUCHPOINT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }

    case types.DELETE_PS_TOUCHPOINT_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
      
    case types.DELETE_PS_TOUCHPOINT_SUCCESS: {
      const filtered = state.touchpoints.filter(
        (t) => t.id !== action.payload
      )
      return {
        ...state,
        touchpoints: [ ...filtered ],
        loading: false,
      }
    }

    case types.DELETE_PS_TOUCHPOINT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }

    case types.GET_PS_AUDIENCE_OPTIONS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: initialState.error
      }
    }

    case types.GET_PS_AUDIENCE_OPTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        products: action.payload
      }
    }

    case types.GET_PS_AUDIENCE_OPTIONS_FAILED: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    }

    case types.GET_FINAL_KEYWORDS_REQUEST: {
      return {
        ...state,
        keywords: {
          ...state.keywords,
          error: initialState.keywords.error,
          data: initialState.keywords.data,
          load: true
        }
      }
    }

    case types.GET_FINAL_KEYWORDS_SUCCESS: {
      return {
        ...state,
        keywords: {
          ...state.keywords,
          data: action.payload,
          load: false
        }
      }
    }

    case types.GET_FINAL_KEYWORDS_FAILED: {
      return {
        ...state,
        keywords: {
          ...state.keywords,
          error: action.payload,
          load: false
        },
      }
    }

    case types.GET_CUSTOM_KEYWORDS_REQUEST: {
      return {
        ...state,
        keywords: {
          ...state.keywords,
          customError: initialState.keywords.error,
          load: true
        }
      }
    }

    case types.GET_CUSTOM_KEYWORDS_SUCCESS: {
      return {
        ...state,
        keywords: {
          ...state.keywords,
          customData: action.payload,
          load: false
        }
      }
    }

    case types.GET_CUSTOM_KEYWORDS_FAILED: {
      return {
        ...state,
        keywords: {
          ...state.keywords,
          customError: action.payload,
          load: false
        },
      }
    }

    case types.CLEAR_CUSTOM_KEYWORDS: {
      return {
        ...state,
        keywords: {
          ...state.keywords,
          data: [],
          customData: [],
        }
      }
    }

    case types.UPLOAD_LANDSCAPE_IMAGES_REQUEST: {
      return {
        ...state,
        //loading: true,
        images: {
          ...state.images,
          landscape: {
            loading: true,
            error: ''
          }
        }
      }
    }

    case types.UPLOAD_LANDSCAPE_IMAGES_SUCCESS: {
      return {
        ...state,
        //loading: false,
        images: {
          ...state.images,
          landscape: {
            loading: false,
            error: ''
          }
        }
      }
    }

    case types.UPLOAD_LANDSCAPE_IMAGES_FAILED: {
      return {
        ...state,
        loading: false,
        images: {
          ...state.images,
          landscape: {
            loading: false,
            error: action.payload
          }
        }
      }
    }

    case types.UPLOAD_SQUARE_IMAGES_REQUEST: {
      return {
        ...state,
        images: {
          ...state.images,
          square: {
            loading: true,
            error: ''
          }
        }
      }
    }

    case types.UPLOAD_SQUARE_IMAGES_SUCCESS: {
      return {
        ...state,
        images: {
          ...state.images,
          square: {
            loading: false,
            error: ''
          }
        }
      }
    }

    case types.UPLOAD_SQUARE_IMAGES_FAILED: {
      return {
        ...state,
        images: {
          ...state.images,
          landscape: {
            loading: false,
            error: ''
          }
        }
      }
    }

    case types.UPLOAD_LOGO_IMAGES_REQUEST: {
      return {
        ...state,
        images: {
          ...state.images,
          logo: {
            loading: true,
            error: ''
          }
        }
      }
    }

    case types.UPLOAD_LOGO_IMAGES_SUCCESS: {

      return {
        ...state,
        images: {
          ...state.images,
          logo: {
            loading: false,
            error: ''
          }
        }
      }
    }

    case types.UPLOAD_LOGO_IMAGES_FAILED: {
      return {
        ...state,
        images: {
          ...state.images,
          logo: {
            loading: false,
            error: action.payload
          }
        }
      }
    }

    case types.SET_VALIDATION_ITEMS: {
      return {
        ...state,
        validationItems: action.payload
      }
    }

    case types.RESET_VALIDATION_ITEMS: {
      return {
        ...state,
        validationItems: initialState.validationItems
      }
    }

    default:
      return state;
  }
}

export default GoogleTouchpointReducer;